export interface PricingFilterValue {
  id: number;
  pricingFilterID: number;
  entityID: number;
  customerAttributeID: number;
  customerAttribute: {
    name: string;
    id: number;
    attributeDataTypeID: number;
  };
  documentAttributeID: number;
  documentAttribute: {
    name: string;
    id: number;
    attributeDataTypeID: number;
  };
  itemAttributeID: number;
  itemAttribute: {
    name: string;
    id: number;
    attributeDataTypeID: number;
  };
  numberSourceID: number;
  numberSource: {
    name: string;
    id: number;
  };
  anotherPricingFilterID: number;
  anotherPricingFilter: {
    name: string;
    id: number;
  };
  operandID: number;
  operand: {
    name: string;
    id: number;
    optionSet: string;
  };
  value: string;
  displayValue: string;
  useCurrentDate: boolean;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyPricingFilterValue: PricingFilterValue = {
  id: null,
  pricingFilterID: null,
  entityID: null,
  customerAttributeID: null,
  customerAttribute: {
    name: '',
    id: null,
    attributeDataTypeID: null
  },
  documentAttributeID: null,
  documentAttribute: {
    name: '',
    id: null,
    attributeDataTypeID: null
  },
  itemAttributeID: null,
  itemAttribute: {
    name: '',
    id: null,
    attributeDataTypeID: null
  },
  numberSourceID: null,
  numberSource: {
    name: '',
    id: null
  },
  anotherPricingFilterID: null,
  anotherPricingFilter: {
    name: '',
    id: null
  },
  operandID: null,
  operand: {
    name: '',
    id: null,
    optionSet: ''
  },
  value: '',
  displayValue: '',
  useCurrentDate: false,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
