import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { MySnackBarService } from '../../shared/snackbar/my-snackbar.service';
import { timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ImportsService {
  model = 'RPMImports';
  constructor(private httpClient: HttpClient,
    private snack: MySnackBarService,
  ) { }
  public lastUpdateTime = 0;
  public updateInterval = 500;

  async performImport(data, entityType): Promise<HttpResponse<string>> {
    const headerValues = new HttpHeaders({ 'Content-Type': 'application/*+json' });
    headerValues.append('Content-Type', 'application/*+json');

    return this.httpClient
      .post(`${this.model}?EntityType=${entityType}`, data, { headers: headerValues, responseType: 'text', observe: 'response' })
      .pipe(timeout(environment.requestTimeout))
      .toPromise() as Promise<HttpResponse<string>>;
  }

  async onFileChange(evt: any, entityType): Promise<void> {
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = async (e: any) => {
        try {
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          var headers = {};
          var headersOrder = [];
          var data = [];

          // Extracting headers and their order per ChatGPT
          for (const z in ws) {
            if (z[0] === '!') continue;
            const match = z.match(/([A-Z]+)1$/);
            if (match) {
              const col = match[1];
              headers[col] = ws[z].v;
              headersOrder.push(ws[z].v);
            }
          }

          //for (const z in ws) {
          //  if (z[0] === '!') continue;

          //  const match = z.match(/([A-Z]+)(\d+)/);
          //  if (!match) continue;
          //  const [, col, rowString] = match;
          //  const row = parseInt(rowString);
          //  //const value = ws[z].v;
          //  const value = ws[z]?.v ?? "";  //per ChatGPT

          //  //Store header names
          //  if (row == 1) {
          //    headers[col] = String(value);
          //    continue;
          //  }

          //  if (!data[row]) data[row] = {};
          //  data[row][headers[col]] = String(value).trim();
          //}

          for (const z in ws) {  //new approach per ChatGPT; want to include blank values in JSON
            if (z[0] === '!') continue;
            const match = z.match(/([A-Z]+)(\d+)/);
            if (match) {
              const [, col, rowString] = match;
              const row = parseInt(rowString);

              if (row > 1) {
                if (!data[row - 2]) { // Adjust for array index and skip header
                  //data[row - 2] = headersOrder.reduce((obj, header) => ({ ...obj, [header]: "" }), {});
                  data[row - 2] = headersOrder.reduce((obj, header) => ({ ...obj, [header]: {} }), {});
                }
                if (headers[col] !== undefined && ws[z].v !== undefined) {
                  data[row - 2][headers[col]] = String(ws[z].v).trim();
                }
              }
            }
          }

          //drop those first two rows which are empty no longer required due to above new code
          //data.shift();
          //data.shift();

          const chunkSize = 250;
          const chunks = [];
          for (let i = 0; i < data.length; i += chunkSize) {
            const chunk = data.slice(i, i + chunkSize);
            chunks.push(chunk);
          }
          const totalChunks = chunks.length;

          for (let index = 0; index < chunks.length; index++) {
            const chunk = chunks[index];
            var myJSONString = JSON.stringify(chunk);
            var myEscapedJSONString = myJSONString
              .replace(/[\\]/g, '\\\\')
              .replace(/[\']/g, "\\'")
              .replace(/[\"]/g, '\\"')
              .replace(/[\/]/g, '\\/')
              .replace(/[\b]/g, '\\b')
              .replace(/[\f]/g, '\\f')
              .replace(/[\n]/g, '\\n')
              .replace(/[\r]/g, '\\r')
              .replace(/[\t]/g, '\\t');

            const inputXML = "'" + myEscapedJSONString + "'";
            const percentComplete = Math.round(((index + 1) / totalChunks) * 100);
            await this.performImportWithProgress(inputXML, entityType, percentComplete);
          }

          resolve();
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsBinaryString(target.files[0]);
    });
  }

  async performImportWithProgress(inputXML, entityType, percentComplete) {
    try {
      const response = await this.performImport(
        inputXML,
        entityType,
      );
      const status = response.status;
      if (status === 200) {
        const actionType = entityType.toLowerCase().includes("validate") ? "Validation" : "Import";
        const currentTime = new Date().getTime();
        if (currentTime - this.lastUpdateTime > this.updateInterval) {
          this.snack.openSnackBar(
            `${actionType} Progress: ${percentComplete}%`,
            '',
            false,
            'Success',
            'alert-success',
          );
          this.lastUpdateTime = currentTime;
        }
        if (percentComplete === 100) {
          this.snack.openSnackBar(
            `${actionType} Complete ${response.body}`,
            '',
            false,
            'Success',
            'alert-success',
          );
        }
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

}
