import { NumberSource } from '../number-sources/number-sources.model';

export interface NumberSourceChild {
  id: number;
  numberSourceID: number;
  numberSource: NumberSource;
  rank: number;
  childNumberSourceID: number;
  childNumberSource: NumberSource;
  isMin?: boolean;
  isMax?: boolean;
  createdOn?: any;
  createdBy?: any;
  modifiedOn?: any;
  modifiedBy?: any;
}

export const emptyNumberSourceChild: NumberSourceChild = {
  id: null,
  numberSourceID: null,
  numberSource: null,
  rank: null,
  childNumberSourceID: null,
  childNumberSource: null,
  isMin: false,
  isMax: false,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: '',
 };
