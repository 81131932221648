import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-import-confirmation',
  templateUrl: './import-confirmation.component.html',
  styleUrls: ['./import-confirmation.component.scss'],
})
export class ImportConfirmationComponent implements OnInit {
  @Output() confirmValidate: EventEmitter<any> = new EventEmitter(false);
  @Output() confirmImport: EventEmitter<any> = new EventEmitter(false);
  @Output() closeModal: EventEmitter<any> = new EventEmitter(false);
  constructor() { }

  ngOnInit(): void { }

  close(status: string): void {
    if (status == "validate") {
      this.confirmValidate.emit();
    }
    if (status == "import") {
      this.confirmImport.emit();
    }
    if (status == "cancel") {
      this.closeModal.emit();
    }
  }
}
