export interface DataMapValue {
  id: number;
  dataMapID: number;
  dataMap: {
    id: number;
    name: string;
  };
  customerID: number | null;
  customer: {
    id: number;
    name: string;
  };
  itemID: number | null;
  item: {
    id: number;
    name: string;
  };
  uofMID: number | null;
  uofM: {
    id: number;
    name: string;
  };
  locationID: number | null;
  location: {
    id: number;
    name: string;
  };
  currencyID: number | null;
  currency: {
    id: number;
    name: string;
  };
  dateFrom: Date | null;
  dateTo: Date | null;
  attribute1Value: string;
  attribute2Value: string;
  attribute3Value: string;
  amount: number;
  numberSourceID: number;
  numberSource: {
    id: number;
    name: string;
  };
  priceCalculationID: number;
  priceCalculation: {
    id: number;
    name: string;
  }
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyDataMapValue: DataMapValue = {
  id: null,
  dataMapID: null,
  dataMap: {
    name: '',
    id: null,
  },
  customerID: null,
  customer: {
    name: '',
    id: null,
  },
  itemID: null,
  item: {
    name: '',
    id: null,
  },
  uofMID: null,
  uofM: {
    name: '',
    id:null,
  },
  locationID: null,
  location: {
    name: '',
    id: null,
  },
  currencyID: null,
  currency: {
    name: '',
    id: null,
  },
  dateFrom: null,
  dateTo: null,
  attribute1Value: '',
  attribute2Value: '',
  attribute3Value: '',
  amount: null,
  numberSourceID: null,
  numberSource: {
    name: '',
    id: null,
  },
  priceCalculationID: null,
  priceCalculation: {
    name: '',
    id: null,
  },
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};

