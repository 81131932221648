<!--gridViewSchedChild, deprecated-->
<kendo-grid *ngIf="gridViewSchedChild && gridViewSchedChild.length > 0"
            [kendoGridBinding]="gridViewSchedChild"
            idField="id"
            [resizable]="true">
  <!--<kendo-grid *ngIf="gridViewSchedChild && gridViewSchedChild.length > 0 && showDeprecated"
              [kendoGridBinding]="gridViewSchedChild"
              idField="id"
              [resizable]="true">-->

  <kendo-grid-column field="childPriceSchedule.name" title="Child Price Schedules" width="300" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a [routerLink]="['/price/price-schedule/', dataItem.childPriceSchedule.id]">
        {{ dataItem.childPriceSchedule.name }}
      </a>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="rank" title="Rank" width="70" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>

  <kendo-grid-column field="childPriceSchedule.priceSourceOption.name" title="Source" width="120" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>

  <kendo-grid-column field="childPriceSchedule.pricingFilter.name" title="Filter" width="60" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.childPriceSchedule.pricingFilter?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="childPriceSchedule.priceBook.name" title="Price Book" width="70" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.childPriceSchedule.priceBook?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="childPriceSchedule.roundingRule.name" title="Round" width="60" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.childPriceSchedule.roundingRule?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <!--<div *kendoGridDetailTemplate="let dataItem">
    <app-pricing-tree-branch [numberSourceID]="dataItem.numberSourceID"></app-pricing-tree-branch>
  </div>-->

  <div *kendoGridDetailTemplate="let dataItem">
    <app-pricing-tree-branch [priceScheduleID]="dataItem.childPriceScheduleID"></app-pricing-tree-branch>
  </div>

</kendo-grid>

<br *ngIf="gridViewSchedChild && gridViewSchedChild.length > 0">
<!--<br *ngIf="gridViewSchedChild && gridViewSchedChild.length > 0 && showDeprecated">-->

<!--gridViewSchedCalc-->
<kendo-grid *ngIf="gridViewSchedCalc && gridViewSchedCalc.length > 0"
            [kendoGridBinding]="gridViewSchedCalc"
            idField="id"
            [resizable]="true">

  <kendo-grid-column field="priceCalculation.name" title="Calculations" width="250" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a [routerLink]="['/price/price-calculation/', dataItem.priceCalculation.id]">
        {{ dataItem.priceCalculation.name }}
      </a>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="rank" title="Rank" width="70" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"> </kendo-grid-column>
  <kendo-grid-column field="priceCalculation.priceModeOption.name" title="Price Mode" width="120" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>
  <kendo-grid-column field="priceCalculation.priceAmount" title="Amount" width="65" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>
  <kendo-grid-column field="priceCalculation.pricePercent" title="Percent" width="65" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>

  <kendo-grid-column field="priceCalculation.pricingFilter.name" title="Filter" width="60" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.priceCalculation.pricingFilter?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="priceCalculation.pricingCondition.name" title="Condition" width="75" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.priceCalculation.pricingCondition?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="priceCalculation.roundingRule.name" title="Round" width="60" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.priceCalculation.roundingRule?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <!--<div *kendoGridDetailTemplate="let dataItem">
    <app-pricing-tree-branch [dataMapID]="dataItem.dataMapID" [numberSourceID]="dataItem.numberSourceID"></app-pricing-tree-branch>
  </div>-->
</kendo-grid>

<br *ngIf="gridViewSchedCalc && gridViewSchedCalc.length > 0">

<!--gridViewNumSrcChild-->
<!--<kendo-grid *ngIf="gridViewNumSrcChild && gridViewNumSrcChild.length > 0"
            [kendoGridBinding]="gridViewNumSrcChild"
            idField="id"
            [resizable]="true">

  <kendo-grid-column field="childNumberSource.name" title="Dynamic Search Number Sources" width="250" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a [routerLink]="['/price/number-source/', dataItem.childNumberSource.id]">
        {{ dataItem.childNumberSource.name }}
      </a>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="rank" title="Rank" width="70" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"> </kendo-grid-column>

  <kendo-grid-column field="isMin" title="Minimum" width="75" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.isMin ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="isMax" title="Maximum" width="75" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.isMax ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <div *kendoGridDetailTemplate="let dataItem">
    <app-pricing-tree-branch [dataMapID]="dataItem.dataMapID" [numberSourceID]="dataItem.childNumberSource.id"></app-pricing-tree-branch>
  </div>
</kendo-grid>

<br *ngIf="gridViewNumSrcChild && gridViewNumSrcChild.length > 0">-->
<!--griveViewDMV-->
<!--<kendo-grid *ngIf="griveViewDMV && griveViewDMV.length > 0"
            [kendoGridBinding]="griveViewDMV"
            idField="id"
            [resizable]="true">

  <kendo-grid-column field="dataMapValue.name" title="Calculations" width="250" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a [routerLink]="['/price/price-calculation/', dataItem.dataMapValue.id]">
        {{ dataItem.dataMapValue.name }}
      </a>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="rank" title="Rank" width="70" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"> </kendo-grid-column>
  <kendo-grid-column field="dataMapValue.priceModeOption.name" title="Price Mode" width="120" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>
  <kendo-grid-column field="dataMapValue.priceAmount" title="Amount" width="65" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>
  <kendo-grid-column field="dataMapValue.pricePercent" title="Percent" width="65" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>

  <kendo-grid-column field="dataMapValue.pricingFilter.name" title="Filter" width="60" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.dataMapValue.pricingFilter?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="dataMapValue.pricingCondition.name" title="Condition" width="75" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.dataMapValue.pricingCondition?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="dataMapValue.roundingRule.name" title="Round" width="60" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.dataMapValue.roundingRule?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <div *kendoGridDetailTemplate="let dataItem">
    <app-pricing-tree-branch [dataMapID]="dataItem.dataMapID" [numberSourceID]="dataItem.numberSourceID"></app-pricing-tree-branch>
  </div>
</kendo-grid>-->
