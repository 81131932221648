import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StorageService } from '../../utils/StorageHelper';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { SideNavService } from '../../services/side-nav/sidenav.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { SettingsService } from '../../services/settings/settings.service';
import { Setting } from '../../services/settings/settings.model';
import { LookupsService } from '../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';

@Component({
  selector: 'rockton-entry',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {

  //NEW 
  public items: Array<any> = [];
  public isAdmin: boolean;
  public activeERP: string;

  public selected = 'ERP Data';
  public expanded = true;
  @Output() authenticationChange = new EventEmitter();
  @Output() sideNavChange = new EventEmitter();
  settings: Setting[] = [];
  public erpNames: Lookup[] = [];
  public showDeprecated: boolean = (StorageService.ShowDeprecated() == 'true');
  constructor(
    private authenticationService: AuthenticationService,
    private sideNavService: SideNavService,
    public router: Router,
    private settingsService: SettingsService,
    private lookupService: LookupsService
  ) {
    const routes: any[] = router.config;

    routes.forEach(route => {
      this.items.push({
        text: route.text,
        path: route.path ? route.path : ''
      });
    });

    this.items[0].selected = true;
  }

  toggleMenu(event: Event) {
    event.preventDefault();
    $('#sidebar-wrapper').toggleClass('collapse');
    this.expanded = !this.expanded;
    this.sideNavService.SideNavChanged(this.expanded);
  }

  async getERPNames() {
    const Filter_OptionSet = 'ERPName';
    const params = { Filter_OptionSet };
    this.erpNames = await this.lookupService.getList(params);
    return this.erpNames;
  }

  async getSettings(params) {
    console.log('getSettings is called');  //mhr this never fires when loading the app
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const params = { PageSize };
      const resp = await this.settingsService.getList(params);
      this.settings = resp.body;
    } catch (e) {
      this.settings = [];
    }
    return this.settings;
  }

  async updateERPName() {
    this.erpNames = await Promise.resolve(this.getERPNames());
    await this.getSettings(null);
    let erpNameSetting: Setting = this.settings.find(erpSetting => erpSetting.name == 'ERPName')
    let erpNameSelected: string = this.erpNames.find(erp => erp.id == +erpNameSetting.value).name;
    this.activeERP = erpNameSelected;
    StorageService.setERPName(erpNameSelected);
  }

  ngOnInit(): void {
    const userID = StorageService.UserID();

    if (userID) {
      this.isAdmin = StorageService.IsAdmin();
      this.activeERP = StorageService.ERPName();
    } else {
      this.activeERP = "None";
    }

    this.authenticationService.authenticationChangedEvent.subscribe(
      (event) => {
        const userID = StorageService.UserID();
        if (userID) {
          this.isAdmin = StorageService.IsAdmin();
          this.updateERPName();
        }
        else {
          this.activeERP = "None";
          this.isAdmin = false;
        }
      },
    );

    if (userID) {
      this.isAdmin = StorageService.IsAdmin();
      this.activeERP = StorageService.ERPName();
    } else {
      this.activeERP = "None";
    }
  }

}
