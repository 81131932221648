import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataMapsService } from '../../../services/data-maps/data-maps.service';
import { DataMap, emptyDataMap } from '../../../services/data-maps/data-maps.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { ItemAttribute } from '../../../services/item-attributes/item-attributes.model';
import { ItemAttributesService } from '../../../services/item-attributes/item-attributes.service';
import { ItemAttributeLookupComponent } from '../../item-attributes/item-attribute-lookup/item-attribute-lookup.component';
import { CustomerAttributesService } from '../../../services/customer-attributes/customer-attributes.service';
import { CustomerAttribute } from 'src/app/services/customer-attributes/customer-attributes.model';
import { CustomerAttributeLookupComponent } from '../../customer-attributes/customer-attribute-lookup/customer-attribute-lookup.component';
import { DocumentAttribute } from '../../../services/document-attributes/document-attributes.model';
import { DocumentAttributesService } from '../../../services/document-attributes/document-attributes.service';
import { DocumentAttributeLookupComponent } from '../../document-attributes/document-attribute-lookup/document-attribute-lookup.component';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { DataMapValueListComponent } from '../../data-map-values/data-map-value-list/data-map-value-list.component';
import { DataMapValue } from 'src/app/services/data-map-values/data-map-values.model';
import { DataMapValuesService } from 'src/app/services/data-map-values/data-map-values.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingsService } from '../../../services/settings/settings.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { Location } from '@angular/common';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { CopyService } from '../../../services/copy/copy.service';
import { NumberSource } from '../../../services/number-sources/number-sources.model';
import { NumberSourcesService } from '../../../services/number-sources/number-sources.service';
import { NumberSourceLookupComponent } from '../../number-sources/number-source-lookup/number-source-lookup.component';
import { PriceCalculation } from '../../../services/price-calculations/price-calculations.model';
import { PriceCalculationsService } from '../../../services/price-calculations/price-calculations.service';
import { PriceCalculationLookupComponent } from '../../price-calculations/price-calculation-lookup/price-calculation-lookup.component';

enum Filters {
  Name,
  DataMapType,
  EntityType1,
  CustomerAttribute1,
  ItemAttribute1,
  DocumentAttribute1,
  EntityType2,
  CustomerAttribute2,
  ItemAttribute2,
  DocumentAttribute2,
  EntityType3,
  CustomerAttribute3,
  ItemAttribute3,
  DocumentAttribute3,
  DefaultNumberSource,
  DefaultPriceCalculation
}
@Component({
  selector: 'app-data-map-single',
  templateUrl: './data-map-single.component.html',
  styleUrls: ['./data-map-single.component.scss'],
})
export class DataMapSingleComponent implements OnInit {
  @ViewChild('subgrid') DataMapValuesSubGrid: DataMapValueListComponent;

  filters = Filters;
  idOfDataMap: number | null = null;
  dataMap: DataMap = emptyDataMap;
  loading: boolean = true;
  dataMapForm: FormGroup;
  paginationObject: any = { DataMapValues: mockedData };
  subgridmode = 'subgrid';
  dataMapValues: DataMapValue[] = [];
  paginationObject_DataMapValues: any = { dataMapValues: mockedData };
  dataMapTypes: Lookup[] = []; ''

  numberSources: NumberSource[] = [];
  priceCalculations: PriceCalculation[] = [];

  entityTypes1: Lookup[] = [];
  customerAttributes1: CustomerAttribute[] = [];
  itemAttributes1: ItemAttribute[] = [];
  documentAttributes1: DocumentAttribute[] = [];
  paginationObject_EntityTypes1: any = { entityTypes1: mockedData };
  paginationObject_CustomerAttributes1: any = { customerAttributes1: mockedData };
  paginationObject_ItemAttributes1: any = { itemAttributes1: mockedData };
  paginationObject_DocumentAttributes1: any = { documentAttributes1: mockedData };
  attribute1ColumnName: string = 'Attribute 1 Value'

  entityTypes2: Lookup[] = [];
  customerAttributes2: CustomerAttribute[] = [];
  itemAttributes2: ItemAttribute[] = [];
  documentAttributes2: DocumentAttribute[] = [];
  paginationObject_EntityTypes2: any = { entityTypes2: mockedData };
  paginationObject_CustomerAttributes2: any = { customerAttributes2: mockedData };
  paginationObject_ItemAttributes2: any = { itemAttributes2: mockedData };
  paginationObject_DocumentAttributes2: any = { documentAttributes2: mockedData };
  attribute2ColumnName: string = 'Attribute 2 Value'

  entityTypes3: Lookup[] = [];
  customerAttributes3: CustomerAttribute[] = [];
  itemAttributes3: ItemAttribute[] = [];
  documentAttributes3: DocumentAttribute[] = [];
  paginationObject_EntityTypes3: any = { entityTypes3: mockedData };
  paginationObject_CustomerAttributes3: any = { customerAttributes3: mockedData };
  paginationObject_ItemAttributes3: any = { itemAttributes3: mockedData };
  paginationObject_DocumentAttributes3: any = { documentAttributes3: mockedData };
  attribute3ColumnName: string = 'Attribute 3 Value'

  submitted = false;
  @Output() onFormChanged = new EventEmitter<Boolean>();
  @Output() onFormSaved = new EventEmitter<Boolean>();
  @Output() onFormCancelled = new EventEmitter<Boolean>();

  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';

  constructor(
    private route: ActivatedRoute,
    private dataMapsService: DataMapsService,
    private dataMapValuesService: DataMapValuesService,
    private customerAttributesService: CustomerAttributesService,
    private itemAttributesService: ItemAttributesService,
    private documentAttributesService: DocumentAttributesService,
    private numberSourcesService: NumberSourcesService,
    private priceCalculationsService: PriceCalculationsService,
    private settingsService: SettingsService,
    private modalService: NgbModal,
    public fb: FormBuilder,
    private snack: MySnackBarService,
    public activeModal: NgbActiveModal,
    private sideNavService: SideNavService,
    private location: Location,
    private router: Router,
    private notificationHelper: NotificationHelper,
    private copyService: CopyService,
    private lookupsService: LookupsService,

  ) {
    this.route.params.subscribe((params) => {
      if (params.id && params.id === 'new') {
        this.dataMap = emptyDataMap;
        this.idOfDataMap = null;
        this.submitted = false;
        this.dataMap.entityType1ID = 75;
        this.f.customerAttribute1ID.setValidators(Validators.required);
        return;
      }
      else {
        this.idOfDataMap = params.id;
        this.loadData();
      }
    });

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.DataMapValuesSubGrid.gridClassName = "DataMapValueSubgridClass"
        }
        else {
          this.DataMapValuesSubGrid.gridClassName = "DataMapValueFullSubgridClass"
        }
      },
    );
  }

  ngOnInit(): void {
    this.buildForm();
    this.loadData();
  }

  ngAfterViewChecked() {
    this.updateColumnNames();
  }

  buildForm() {
    this.dataMapForm = new FormGroup({
      name: new FormControl('', Validators.required),
      dataMapTypeID: new FormControl(189, Validators.required),
      defaultMapAmount: new FormControl(),
      defaultNumberSourceID: new FormControl(),
      defaultPriceCalculationID: new FormControl(),
      description: new FormControl(''),
      useCustomer: new FormControl(),
      useItem: new FormControl(),
      useUofM: new FormControl(),
      useLocation: new FormControl(),
      useCurrency: new FormControl(),
      useDates: new FormControl(),
      useAttribute1: new FormControl(),
      useAttribute2: new FormControl(),
      useAttribute3: new FormControl(),
      entityType1ID: new FormControl(75), //default to Cust Attrib
      customerAttribute1ID: new FormControl(),
      itemAttribute1ID: new FormControl(),
      documentAttribute1ID: new FormControl(),
      entityType2ID: new FormControl(75),
      customerAttribute2ID: new FormControl(),
      itemAttribute2ID: new FormControl(),
      documentAttribute2ID: new FormControl(),
      entityType3ID: new FormControl(75),
      customerAttribute3ID: new FormControl(),
      itemAttribute3ID: new FormControl(),
      documentAttribute3ID: new FormControl(),
    });
    this.getDataMapTypes();
    this.getEntityTypes();
    this.dataMapForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.dataMapForm.controls;
  }

  onClickClose(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMDataMap", this.dataMap.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  updateForm() {
    this.dataMapForm.patchValue({ ...this.dataMap });
    this.isFormChanged = false;
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.dataMap.id);
    await this.dataMapsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/data-maps']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.dataMap && this.dataMap.id) {
      return false;
    }
    else {
      return true;
    }
  }

  async onClickAddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/data-map/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/data-map/new']);
      });
    }
  }

  onClickBack(): void {
    this.location.back();
  }

  onClickSave() {
    this.submit(null);
  }

  onClickSaveClose() {
    this.submit("Close");
  }

  onClickSaveNew() {
    this.submit("New");
  }

  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.dataMapForm.controls['name'].value;
    const Operand_Name = 'eq';

    const params = { Filter_Name, Operand_Name, PageSize };
    const existingDataMaps: DataMap[] = await (
      await this.dataMapsService.getList(params)
    ).body;
    if (existingDataMaps.length > 0) {
      if (this.dataMap.id) {
        for (const key in existingDataMaps) {
          const currentDataMap: DataMap = existingDataMaps[key];
          if (currentDataMap.id !== this.dataMap.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async submit(action) {
    this.dataMapForm.markAllAsTouched();
    this.submitted = true;
    if (this.dataMapForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.dataMap,
      ...this.dataMapForm.value,
    };
    // Check to see if Data Map Name already exists.
    if ((await this.checkForExistingName()) === true) {
      this.snack.openSnackBar(
        'Data Map Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.dataMap.id) {
        try {
          const response: any = await this.dataMapsService.update(
            this.dataMap.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/data-maps']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/data-map/new'],);
              });
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      } else {
        try {
          const response: any = await this.dataMapsService.create(
            this.dataMapForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.dataMap = response.body;
            this.idOfDataMap = this.dataMap.id;
            this.snack.openSnackBar(
              'Record saved successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/data-maps']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/data-map/new'],);
              });
            }
            else {
              this.dataMap = response.body;
              this.router.navigate(['/price/data-map/', this.dataMap.id]);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
      this.onFormSaved.emit(false);
      this.onFormChanged.emit(false);
      console.log('Form Changed', this.isFormChanged)
    }
  }

  async loadData() {
    this.loading = true;
    try {
      //
      if (this.idOfDataMap) {
        // If this is an existing record we populate the values.
        this.dataMap = await Promise.resolve(
          await this.dataMapsService.getById(this.idOfDataMap));

        if (this.dataMap.customerAttribute1ID) {
          const currentCustomerAttribute: CustomerAttribute = await Promise.resolve(await this.customerAttributesService.getById(this.dataMap.customerAttribute1ID));
          this.customerAttributes1.push(currentCustomerAttribute);
          this.dataMap.customerAttribute1 = currentCustomerAttribute;
        }
        if (this.dataMap.itemAttribute1ID) {
          const currentItemAttribute: ItemAttribute = await Promise.resolve(this.itemAttributesService.getById(this.dataMap.itemAttribute1ID));
          this.itemAttributes1.push(currentItemAttribute);
          this.dataMap.itemAttribute1 = currentItemAttribute;
        }
        if (this.dataMap.documentAttribute1ID) {
          const currentDocumentAttribute: DocumentAttribute = await Promise.resolve(this.documentAttributesService.getById(this.dataMap.documentAttribute1ID));
          this.documentAttributes1.push(currentDocumentAttribute);
          this.dataMap.documentAttribute1 = currentDocumentAttribute;
        }

        if (this.dataMap.customerAttribute2ID) {
          const currentCustomerAttribute: CustomerAttribute = await Promise.resolve(await this.customerAttributesService.getById(this.dataMap.customerAttribute2ID));
          this.customerAttributes2.push(currentCustomerAttribute);
          this.dataMap.customerAttribute2 = currentCustomerAttribute;
        }
        if (this.dataMap.itemAttribute2ID) {
          const currentItemAttribute: ItemAttribute = await Promise.resolve(this.itemAttributesService.getById(this.dataMap.itemAttribute2ID));
          this.itemAttributes2.push(currentItemAttribute);
          this.dataMap.itemAttribute2 = currentItemAttribute;
        }
        if (this.dataMap.documentAttribute2ID) {
          const currentDocumentAttribute: DocumentAttribute = await Promise.resolve(this.documentAttributesService.getById(this.dataMap.documentAttribute2ID));
          this.documentAttributes2.push(currentDocumentAttribute);
          this.dataMap.documentAttribute2 = currentDocumentAttribute;
        }

        if (this.dataMap.customerAttribute3ID) {
          const currentCustomerAttribute: CustomerAttribute = await Promise.resolve(await this.customerAttributesService.getById(this.dataMap.customerAttribute3ID));
          this.customerAttributes3.push(currentCustomerAttribute);
          this.dataMap.customerAttribute3 = currentCustomerAttribute;
        }
        if (this.dataMap.itemAttribute3ID) {
          const currentItemAttribute: ItemAttribute = await Promise.resolve(this.itemAttributesService.getById(this.dataMap.itemAttribute3ID));
          this.itemAttributes3.push(currentItemAttribute);
          this.dataMap.itemAttribute3 = currentItemAttribute;
        }
        if (this.dataMap.documentAttribute3ID) {
          const currentDocumentAttribute: DocumentAttribute = await Promise.resolve(this.documentAttributesService.getById(this.dataMap.documentAttribute3ID));
          this.documentAttributes3.push(currentDocumentAttribute);
          this.dataMap.documentAttribute3 = currentDocumentAttribute;
        }

        if (this.dataMap.defaultNumberSourceID) {
          const currentNumberSource: NumberSource = await Promise.resolve(await this.numberSourcesService.getById(this.dataMap.defaultNumberSourceID));
          this.numberSources.push(currentNumberSource);
          this.dataMap.defaultNumberSource = currentNumberSource;
        }

        if (this.dataMap.defaultNumberSourceID) {
          const currentRecord: PriceCalculation = await Promise.resolve(await this.priceCalculationsService.getById(this.dataMap.defaultPriceCalculationID));
          this.priceCalculations.push(currentRecord);
          this.dataMap.defaultPriceCalculation = currentRecord;
        }

        this.updateForm();
      }
      this.isFormChanged = false;
    }
    catch (e) {
      console.log(e);
    }
    finally {
      this.loading = false;
    }
  }

  onSelectBoxChanged(id: number, key: keyof DataMap, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.dataMap[key] = item;
    if (key == "dataMapTypeID") {
      //this.dataMapTypeChange(id);
    }
    if (key == "entityType1ID") {
      this.entityType1Change(id);
    }
    if (key == "entityType2ID") {
      this.entityType2Change(id);
    }
    if (key == "entityType3ID") {
      this.entityType3Change(id);
    }
    if (key == "customerAttribute1ID") {
      this.customerAttribute1Change(id);
    }
    this.updateColumnNames();
  }

  async updateColumnNames() {
    let temp1Name = 'Attribute 1 Value';
    let temp2Name = 'Attribute 2 Value';
    let temp3Name = 'Attribute 3 Value';

    let entityID = this.dataMapForm.get('entityType1ID').value;
    if (entityID) {
      const enType = this.entityTypes1.find(et => et.id === entityID);
      temp1Name = enType ? enType.name : 'Unknown';
      if (entityID == 75) { temp1Name += ' ' + (this.customerAttributes1.find(at => at.id === this.dataMapForm.get('customerAttribute1ID').value) || {}).name || 'Unknown'; }
      if (entityID == 76) { temp1Name += ' ' + (this.itemAttributes1.find(at => at.id === this.dataMapForm.get('itemAttribute1ID').value) || {}).name || 'Unknown'; }
      if (entityID == 70) { temp1Name += ' ' + (this.documentAttributes1.find(at => at.id === this.dataMapForm.get('documentAttribute1ID').value) || {}).name || 'Unknown'; }
      temp1Name += ' Value';
    }
    entityID = this.dataMapForm.get('entityType2ID').value;
    if (entityID) {
      const enType = this.entityTypes2.find(et => et.id === entityID);
      temp2Name = enType ? enType.name : 'Unknown';
      if (entityID == 75) { temp2Name += ' ' + (this.customerAttributes2.find(at => at.id === this.dataMapForm.get('customerAttribute2ID').value) || {}).name || 'Unknown'; }
      if (entityID == 76) { temp2Name += ' ' + (this.itemAttributes2.find(at => at.id === this.dataMapForm.get('itemAttribute2ID').value) || {}).name || 'Unknown'; }
      if (entityID == 70) { temp2Name += ' ' + (this.documentAttributes2.find(at => at.id === this.dataMapForm.get('documentAttribute2ID').value) || {}).name || 'Unknown'; }
      temp2Name += ' Value';
    }
    entityID = this.dataMapForm.get('entityType3ID').value;
    if (entityID) {
      const enType = this.entityTypes3.find(et => et.id === entityID);
      temp3Name = enType ? enType.name : 'Unknown';
      if (entityID == 75) { temp3Name += ' ' + (this.customerAttributes3.find(at => at.id === this.dataMapForm.get('customerAttribute3ID').value) || {}).name || 'Unknown'; }
      if (entityID == 76) { temp3Name += ' ' + (this.itemAttributes3.find(at => at.id === this.dataMapForm.get('itemAttribute3ID').value) || {}).name || 'Unknown'; }
      if (entityID == 70) { temp3Name += ' ' + (this.documentAttributes3.find(at => at.id === this.dataMapForm.get('documentAttribute3ID').value) || {}).name || 'Unknown'; }
      temp3Name += ' Value';
    }

    this.attribute1ColumnName = temp1Name;
    this.attribute2ColumnName = temp2Name;
    this.attribute3ColumnName = temp3Name;
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.CustomerAttribute1:
        this.customerAttributes1 = await (await this.customerAttributesService.getList(params)).body;
        break;
      case Filters.ItemAttribute1:
        this.itemAttributes1 = await (await this.itemAttributesService.getList(params)).body;
        break;
      case Filters.DocumentAttribute1:
        this.documentAttributes1 = await (await this.documentAttributesService.getList(params)).body;
        break;
      case Filters.CustomerAttribute2:
        this.customerAttributes2 = await (await this.customerAttributesService.getList(params)).body;
        break;
      case Filters.ItemAttribute2:
        this.itemAttributes2 = await (await this.itemAttributesService.getList(params)).body;
        break;
      case Filters.DocumentAttribute2:
        this.documentAttributes2 = await (await this.documentAttributesService.getList(params)).body;
        break;
      case Filters.CustomerAttribute3:
        this.customerAttributes3 = await (await this.customerAttributesService.getList(params)).body;
        break;
      case Filters.ItemAttribute3:
        this.itemAttributes3 = await (await this.itemAttributesService.getList(params)).body;
        break;
      case Filters.DocumentAttribute3:
        this.documentAttributes3 = await (await this.documentAttributesService.getList(params)).body;
        break;
      case Filters.DefaultNumberSource:
        this.numberSources = await (await this.numberSourcesService.getList(params)).body;
        break;
      case Filters.DefaultPriceCalculation:
        this.priceCalculations = await (await this.priceCalculationsService.getList(params)).body;
        break;
      default:
        break;
    }
  }

  async onLookup_customerAttribute1() {
    const modalRef = this.modalService.open(CustomerAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: CustomerAttribute = receivedEntry;
      this.customerAttributes1 = [];
      this.customerAttributes1.push(recordSelected);
      this.dataMapForm.patchValue({ ['customerAttribute1ID']: receivedEntry.id });
      this.dataMap.customerAttribute1 = receivedEntry;
      modalRef.close('test');
    });
  }
  async onLookup_itemAttribute1() {
    const modalRef = this.modalService.open(ItemAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: ItemAttribute = receivedEntry;
      this.itemAttributes1 = [];
      this.itemAttributes1.push(recordSelected);
      this.dataMapForm.patchValue({ ['itemAttribute1ID']: receivedEntry.id });
      this.dataMap.itemAttribute1 = receivedEntry;
      modalRef.close('test');
    });
  }
  async onLookup_documentAttribute1() {
    const modalRef = this.modalService.open(DocumentAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: DocumentAttribute = receivedEntry;
      this.documentAttributes1 = [];
      this.documentAttributes1.push(recordSelected);
      this.dataMapForm.patchValue({ ['documentAttribute1ID']: receivedEntry.id });
      this.dataMap.documentAttribute1 = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_customerAttribute2() {
    const modalRef = this.modalService.open(CustomerAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: CustomerAttribute = receivedEntry;
      this.customerAttributes2 = [];
      this.customerAttributes2.push(recordSelected);
      this.dataMapForm.patchValue({ ['customerAttribute2ID']: receivedEntry.id });
      this.dataMap.customerAttribute2 = receivedEntry;
      modalRef.close('test');
    });
  }
  async onLookup_itemAttribute2() {
    const modalRef = this.modalService.open(ItemAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: ItemAttribute = receivedEntry;
      this.itemAttributes2 = [];
      this.itemAttributes2.push(recordSelected);
      this.dataMapForm.patchValue({ ['itemAttribute2ID']: receivedEntry.id });
      this.dataMap.itemAttribute2 = receivedEntry;
      modalRef.close('test');
    });
  }
  async onLookup_documentAttribute2() {
    const modalRef = this.modalService.open(DocumentAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: DocumentAttribute = receivedEntry;
      this.documentAttributes2 = [];
      this.documentAttributes2.push(recordSelected);
      this.dataMapForm.patchValue({ ['documentAttribute2ID']: receivedEntry.id });
      this.dataMap.documentAttribute2 = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_customerAttribute3() {
    const modalRef = this.modalService.open(CustomerAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: CustomerAttribute = receivedEntry;
      this.customerAttributes3 = [];
      this.customerAttributes3.push(recordSelected);
      this.dataMapForm.patchValue({ ['customerAttribute3ID']: receivedEntry.id });
      this.dataMap.customerAttribute3 = receivedEntry;
      modalRef.close('test');
    });
  }
  async onLookup_itemAttribute3() {
    const modalRef = this.modalService.open(ItemAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: ItemAttribute = receivedEntry;
      this.itemAttributes3 = [];
      this.itemAttributes3.push(recordSelected);
      this.dataMapForm.patchValue({ ['itemAttribute3ID']: receivedEntry.id });
      this.dataMap.itemAttribute3 = receivedEntry;
      modalRef.close('test');
    });
  }
  async onLookup_documentAttribute3() {
    const modalRef = this.modalService.open(DocumentAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: DocumentAttribute = receivedEntry;
      this.documentAttributes3 = [];
      this.documentAttributes3.push(recordSelected);
      this.dataMapForm.patchValue({ ['documentAttribute3ID']: receivedEntry.id });
      this.dataMap.documentAttribute3 = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_defaultNumberSource() {
    const modalRef = this.modalService.open(NumberSourceLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: NumberSource = receivedEntry;
      this.numberSources = [];
      this.numberSources.push(recordSelected);
      this.dataMapForm.patchValue({ ['defaultNumberSourceID']: receivedEntry.id });
      this.dataMap.defaultNumberSource = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_defaultPriceCalculation() {
    const modalRef = this.modalService.open(PriceCalculationLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PriceCalculation = receivedEntry;
      this.priceCalculations = [];
      this.priceCalculations.push(recordSelected);
      this.dataMapForm.patchValue({ ['defaultPriceCalculationID']: receivedEntry.id });
      this.dataMap.defaultPriceCalculation = receivedEntry;
      modalRef.close('test');
    });
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

  async getDataMapTypes() {
    const Filter_OptionSet = 'DataMapType';
    const params = { Filter_OptionSet };
    const records: Lookup[] = await this.lookupsService.getList(params);
    this.dataMapTypes = records;
  }

  async getEntityTypes() {
    const Filter_OptionSet = 'EntityType';
    const params = { Filter_OptionSet };
    const allEntityTypes: Lookup[] = await this.lookupsService.getList(params);

    this.entityTypes1 = [];
    this.entityTypes2 = [];
    this.entityTypes3 = [];

    if (allEntityTypes.length > 0) {
      for (const key in allEntityTypes) {
        const currentLookup: Lookup = allEntityTypes[key];
        if (currentLookup.name == "Customer Attribute" || currentLookup.name == "Document Attribute" || currentLookup.name == "Item Attribute") {
          this.entityTypes1.push(currentLookup);
          this.entityTypes2.push(currentLookup);
          this.entityTypes3.push(currentLookup);
        }
      }
    }
  }

  entityType1Change(entityType1ID) {
    if (entityType1ID == 75) {
      // Require the Customer Attribute
      this.f.customerAttribute1ID.setValidators(Validators.required);
      this.f.customerAttribute1ID.updateValueAndValidity();
      // Clear the Document Attribute
      this.dataMapForm.patchValue({ ['documentAttribute1ID']: null });
      this.f.documentAttribute1ID.clearValidators();
      this.f.documentAttribute1ID.updateValueAndValidity();
      // Clear the Item Attribute
      this.dataMapForm.patchValue({ ['itemAttribute1ID']: null });
      this.f.itemAttribute1ID.clearValidators();
      this.f.itemAttribute1ID.updateValueAndValidity();
    }
    else if (entityType1ID == 76) {
      // Require the Item Attribute
      this.f.itemAttribute1ID.setValidators(Validators.required);
      this.f.itemAttribute1ID.updateValueAndValidity();
      // Clear the Customer Attribute
      this.dataMapForm.patchValue({ ['customerAttribute1ID']: null });
      this.f.customerAttribute1ID.clearValidators();
      this.f.customerAttribute1ID.updateValueAndValidity();
      // Clear the Document Attribute
      this.dataMapForm.patchValue({ ['documentAttribute1ID']: null });
      this.f.documentAttribute1ID.clearValidators();
      this.f.documentAttribute1ID.updateValueAndValidity();
    }
    else if (entityType1ID == 70) {
      // Require the Document Attribute
      this.f.documentAttribute1ID.setValidators(Validators.required);
      this.f.documentAttribute1ID.updateValueAndValidity();
      // Clear the Customer Attribute
      this.dataMapForm.patchValue({ ['customerAttribute1ID']: null });
      this.f.customerAttribute1ID.clearValidators();
      this.f.customerAttribute1ID.updateValueAndValidity();
      // Clear the Item Attribute
      this.dataMapForm.patchValue({ ['itemAttribute1ID']: null });
      this.f.itemAttribute1ID.clearValidators();
      this.f.itemAttribute1ID.updateValueAndValidity();
    }
    this.updateColumnNames();
  }

  entityType2Change(entityType2ID) {
    if (entityType2ID == 75) {
      // Require the Customer Attribute
      this.f.customerAttribute2ID.setValidators(Validators.required);
      this.f.customerAttribute2ID.updateValueAndValidity();
      // Clear the Document Attribute
      this.dataMapForm.patchValue({ ['documentAttribute2ID']: null });
      this.f.documentAttribute2ID.clearValidators();
      this.f.documentAttribute2ID.updateValueAndValidity();
      // Clear the Item Attribute
      this.dataMapForm.patchValue({ ['itemAttribute2ID']: null });
      this.f.itemAttribute2ID.clearValidators();
      this.f.itemAttribute2ID.updateValueAndValidity();
    }
    else if (entityType2ID == 76) {
      // Require the Item Attribute
      this.f.itemAttribute2ID.setValidators(Validators.required);
      this.f.itemAttribute2ID.updateValueAndValidity();
      // Clear the Customer Attribute
      this.dataMapForm.patchValue({ ['customerAttribute2ID']: null });
      this.f.customerAttribute2ID.clearValidators();
      this.f.customerAttribute2ID.updateValueAndValidity();
      // Clear the Document Attribute
      this.dataMapForm.patchValue({ ['documentAttribute2ID']: null });
      this.f.documentAttribute2ID.clearValidators();
      this.f.documentAttribute2ID.updateValueAndValidity();
    }
    else if (entityType2ID == 70) {
      // Require the Document Attribute
      this.f.documentAttribute2ID.setValidators(Validators.required);
      this.f.documentAttribute2ID.updateValueAndValidity();
      // Clear the Customer Attribute
      this.dataMapForm.patchValue({ ['customerAttribute2ID']: null });
      this.f.customerAttribute2ID.clearValidators();
      this.f.customerAttribute2ID.updateValueAndValidity();
      // Clear the Item Attribute
      this.dataMapForm.patchValue({ ['itemAttribute2ID']: null });
      this.f.itemAttribute2ID.clearValidators();
      this.f.itemAttribute2ID.updateValueAndValidity();
    }
    this.updateColumnNames();
  }

  entityType3Change(entityType3ID) {
    if (entityType3ID == 75) {
      // Require the Customer Attribute
      this.f.customerAttribute3ID.setValidators(Validators.required);
      this.f.customerAttribute3ID.updateValueAndValidity();
      // Clear the Document Attribute
      this.dataMapForm.patchValue({ ['documentAttribute3ID']: null });
      this.f.documentAttribute3ID.clearValidators();
      this.f.documentAttribute3ID.updateValueAndValidity();
      // Clear the Item Attribute
      this.dataMapForm.patchValue({ ['itemAttribute3ID']: null });
      this.f.itemAttribute3ID.clearValidators();
      this.f.itemAttribute3ID.updateValueAndValidity();
    }
    else if (entityType3ID == 76) {
      // Require the Item Attribute
      this.f.itemAttribute3ID.setValidators(Validators.required);
      this.f.itemAttribute3ID.updateValueAndValidity();
      // Clear the Customer Attribute
      this.dataMapForm.patchValue({ ['customerAttribute3ID']: null });
      this.f.customerAttribute3ID.clearValidators();
      this.f.customerAttribute3ID.updateValueAndValidity();
      // Clear the Document Attribute
      this.dataMapForm.patchValue({ ['documentAttribute3ID']: null });
      this.f.documentAttribute3ID.clearValidators();
      this.f.documentAttribute3ID.updateValueAndValidity();
    }
    else if (entityType3ID == 70) {
      // Require the Document Attribute
      this.f.documentAttribute3ID.setValidators(Validators.required);
      this.f.documentAttribute3ID.updateValueAndValidity();
      // Clear the Customer Attribute
      this.dataMapForm.patchValue({ ['customerAttribute3ID']: null });
      this.f.customerAttribute3ID.clearValidators();
      this.f.customerAttribute3ID.updateValueAndValidity();
      // Clear the Item Attribute
      this.dataMapForm.patchValue({ ['itemAttribute3ID']: null });
      this.f.itemAttribute3ID.clearValidators();
      this.f.itemAttribute3ID.updateValueAndValidity();
    }
    this.updateColumnNames();
  }

  customerAttribute1Change(entityTypeID) {
  }

  onConfigurationExpand() {
  }

  onConfigurationCollapse() {
  }

}
