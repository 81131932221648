<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/System_Jobs.png" alt="System Job" style="padding-right: 5px;">
  Create / Update System Job
</div>
<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Back.png" [routerLink]="['/price/system-jobs']"
          routerLinkActive="router-link-active" title="Go Back to List">
    BACK
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save.png" (click)="onClickSave()" title="Save">
    SAVE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save_Close.png" (click)="onClickSaveClose()" title="Save & Close">
    SAVE &
    CLOSE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save_New.png" (click)="onClickSaveNew()" title="Save & New">
    SAVE & NEW
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Add.png" (click)="onClickAddNew()" title="Add New">
    ADD NEW
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">
    COPY
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="showRemovePopUp(areYouSure)"
          title="Delete Record">
    DELETE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Action.png" (click)="onClickRunNow()"
          title="Run Now">
    RUN NOW
  </button>
</kendo-buttongroup>

<form [formGroup]="systemJobForm">
  <div class="row">
    <div class="col form-group col-6">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="name" placeholder="Enter System Job Name">
        </kendo-textbox>
        <kendo-formerror>Name is required.</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col form-group col-4">
      <kendo-formfield>
        <kendo-label for="jobTypeID" class="font-weight-bold" text="* Job Type"></kendo-label>
        <ng-select id="jobTypeID" [multiple]="false" [selectOnTab]="true" formControlName="jobTypeID" [readonly]="idOfSystemJob"
                   [notFoundText]="'Please start typing to search'"
                   [ngClass]="{'is-invalid': submitted && f.jobTypeID.errors,'w-100': true}" placeholder="Select a Job Type"
                   [searchable]="true" (change)="jobTypeChanged($event)">
          <ng-option *ngFor="let item of jobTypes" [value]="item.id"> {{ item.name }} </ng-option>
        </ng-select>
        <kendo-formerror>Job Type is required</kendo-formerror>
      </kendo-formfield>

    </div>

    <div class="col-2 form-group d-flex align-items-center">
      <div class="wrap" style="padding-right: 10px;">
        <input type="checkbox" id="isRunning" formControlName="isRunning" isRunning kendoCheckBox (click)="$event.preventDefault()" />
        <kendo-label class="k-checkbox-label" [for]="isRunning" text="Currently Running"></kendo-label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-10 form-group">
      <kendo-formfield>
        <kendo-label for="description" text="Description"></kendo-label>
        <kendo-textbox class="form-control" formControlName="description" placeholder="Enter Description">
        </kendo-textbox>
      </kendo-formfield>
    </div>

    <div class="col-2 form-group d-flex align-items-center">
      <div class="wrap" style="padding-right: 10px;">
        <input type="checkbox" id="isActive" formControlName="isActive" isActive kendoCheckBox />
        <kendo-label class="k-checkbox-label" [for]="isActive" text="Is Active"></kendo-label>
      </div>
    </div>
  </div>

  <div class="row">

    <div class="col form-group col-2">
      <kendo-formfield>
        <kendo-label for="timeUnitID" class="font-weight-bold" text="* Frequency"></kendo-label>
        <div class="d-flex input-group-prepend">
          <ng-select id="timeUnitID" [multiple]="false" [selectOnTab]="true" formControlName="timeUnitID"
                     [notFoundText]="'Please start typing to search'"
                     [ngClass]="{'is-invalid': submitted && f.timeUnitID.errors,'w-100': true}" placeholder="Select a Frequency"
                     [searchable]="true" (change)="timeUnitChanged($event)">
            <ng-option *ngFor="let item of timeUnits" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
        </div>
        <kendo-formerror>Frequency is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col form-group col-3" *ngIf="timeUnitID != 125">
      <kendo-formfield>
        <kendo-label for="startOn" class="font-weight-bold" text="* Start On"></kendo-label>
        <kendo-datetimepicker id="startOn" class="form-control" formControlName="startOn" (change)="startOnChanged()"
                              [(value)]="startOn" [ngClass]="{ 'is-invalid': submitted && f.startOn.errors }">
        </kendo-datetimepicker>
      </kendo-formfield>
    </div>

    <div class="col form-group col-2" *ngIf="timeUnitID == 111">
      <kendo-formfield>
        <kendo-label for="minutelyID" class="font-weight-bold" text="* Minutes"></kendo-label>
        <div class="d-flex input-group-prepend">
          <ng-select id="minutelyID" [multiple]="false" [selectOnTab]="true" formControlName="minutelyID"
                     [notFoundText]="'Please start typing to search'"
                     [ngClass]="{'is-invalid': submitted && f.minutelyID.errors,'w-100': true}" placeholder="Select a Frequency"
                     [searchable]="true" (change)="minutelyIDChanged($event)">
            <ng-option *ngFor="let item of minutelyOptions" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
        </div>
        <kendo-formerror>Minutes is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col form-group col-2" *ngIf="timeUnitID == 112">
      <kendo-formfield>
        <kendo-label for="hourlyID" class="font-weight-bold" text="* Hours"></kendo-label>
        <div class="d-flex input-group-prepend">
          <ng-select id="hourlyID" [multiple]="false" [selectOnTab]="true" formControlName="hourlyID"
                     [notFoundText]="'Please start typing to search'"
                     [ngClass]="{'is-invalid': submitted && f.hourlyID.errors,'w-100': true}" placeholder="Select a Frequency"
                     [searchable]="true" (change)="hourlyIDChanged($event)">
            <ng-option *ngFor="let item of hourlyOptions" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
        </div>
        <kendo-formerror>Hours is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="nextRun" text="Next Run (in UTC)"></kendo-label>
        <kendo-textbox formControlName="nextRun" class="form-control" id="nextRun">
        </kendo-textbox>
      </kendo-formfield>
    </div>

  </div>

  <kendo-tabstrip [tabPosition]="'top'">
    <kendo-tabstrip-tab [title]="'Job Logs'" [selected]="idOfSystemJob">
      <ng-template kendoTabContent>
        <div class="card p-3" style="background-color: rgb(234, 234, 234); min-height: 500px;">
          <app-system-job-log-list #subgrid [systemJobID]='idOfSystemJob' mode="view"
                                   [pagination]="paginationObject_SystemJobLogs" [loading]="loading"
                                   class="SystemJobLogSubgridClass">
          </app-system-job-log-list>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab [title]="'Parameters'" [selected]="!idOfSystemJob">
      <ng-template kendoTabContent>
        <div class="card p-3" style="background-color: rgb(234, 234, 234); min-height: 500px;">
          <app-system-job-parameter-list #subgrid [systemJobID]='idOfSystemJob' mode="view"
                                         [pagination]="paginationObject_SystemJobParameters" [loading]="loading"
                                         class="SystemJobParameterSubgridClass">
          </app-system-job-parameter-list>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

</form>

