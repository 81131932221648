import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceSchedulesService } from '../../../services/price-schedules/price-schedules.service';
import { PriceSchedule } from '../../../services/price-schedules/price-schedules.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { GridDataResult, PageChangeEvent, GridComponent, } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, FilterDescriptor, } from '@progress/kendo-data-query';
import { ColumnSettings } from "../../../../app/utils/column-settings.interface";
import { StorageService } from '../../../../app/utils/StorageHelper';
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { RoundingRuleSingleComponent } from '../../rounding-rules/rounding-rule-single/rounding-rule-single.component';
import { PriceScheduleChildrenService } from '../../../services/price-schedule-children/price-schedule-children.service';

@Component({
  selector: 'app-price-schedule-lookup',
  templateUrl: './price-schedule-lookup.component.html',
  styleUrls: ['./price-schedule-lookup.component.scss'],
})
export class PriceScheduleLookupComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;

  constructor(
    private priceScheduleChildService: PriceScheduleChildrenService,
    private priceScheduleService: PriceSchedulesService,
    private modalService: NgbModal,
    private lookupService: LookupsService,
    public activeModal: NgbActiveModal
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
  }

  @Input() loading: boolean = true;
  @Input() priceSchedules: PriceSchedule[] = [];
  @Input() query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input() pagination: Pagination = mockedData;
  @Input() parentPriceScheduleID: number | null = null; //For filtering when choosing children

  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  public isAdmin: boolean;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: PriceSchedule[];
  public exportAll: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public filterable: boolean;
  public priceSchedulePriceSourceOptions: Lookup[];
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Name',
      field: 'name',
      width: 150,
      orderIndex: 0,
      hidden: false
    },
    {
      title: 'Use In Dynamic Pricing',
      field: 'useInDynamicPricing',
      width: 175,
      orderIndex: 1,
      hidden: false
    },
    {
      title: 'Dynamic Pricing Rank',
      field: 'dynamicPricingRank',
      width: 175,
      orderIndex: 2,
      hidden: false
    },
    {
      title: 'Maximum Price',
      field: 'isHighestPrice',
      width: 150,
      orderIndex: 3,
      hidden: false
    },
    {
      title: 'Minimum Price',
      field: 'isLowestPrice',
      width: 150,
      orderIndex: 4,
      hidden: false
    },
    {
      title: 'Price Source Option',
      field: 'priceSourceOption.name',
      width: 250,
      orderIndex: 5,
      hidden: false
    },
    {
      title: 'Pricing Filter',
      field: 'pricingFilter.name',
      width: 150,
      orderIndex: 6,
      hidden: false
    },
    {
      title: 'Price Book',
      field: 'priceBook.name',
      width: 150,
      orderIndex: 7,
      hidden: false
    },
    {
      title: 'Is Active',
      field: 'isActive',
      width: 100,
      orderIndex: 8,
      hidden: false
    },
    {
      title: 'Rounding Rule',
      field: 'roundingRule.name',
      width: 150,
      orderIndex: 9,
      hidden: false
    },
    {
      title: 'Calculate After Price Schedule',
      field: 'calculateAfterPriceSchedule.name',
      width: 225,
      orderIndex: 10,
      hidden: false
    },
    {
      title: 'ERP ID',
      field: 'erpid',
      width: 100,
      orderIndex: 11,
      hidden: true
    }
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  async getPriceSchedulePriceSourceOptions() {
    const Filter_OptionSet = 'PriceSource';
    const params = { Filter_OptionSet };
    this.priceSchedulePriceSourceOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "PriceSource";
    defaultLookup.name = "(All)";
    this.priceSchedulePriceSourceOptions.reverse();
    this.priceSchedulePriceSourceOptions.push(defaultLookup);
    this.priceSchedulePriceSourceOptions.reverse();
    return this.priceSchedulePriceSourceOptions;
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onClick_Close(message) {
    this.activeModal.close(message);
  }

  onClick_Save() {
    this.onDblClick();
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('PriceScheduleLookup_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('PriceScheduleLookup_Config');

    if (!gridConfig) {
      StorageService.setColumnSettings('PriceScheduleLookup_Config', this.defaultColumnsConfig);
    } else {
      this.columnsConfig = gridConfig;
    }

    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('PriceScheduleLookup_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.isAdmin = StorageService.IsAdmin();
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;
    this.loadListData();
    this.getPriceSchedulePriceSourceOptions();
  }

  show_RoundingRule(roundingRule) {
    const modalRef = this.modalService.open(RoundingRuleSingleComponent, {});
    modalRef.componentInstance.roundingRule = roundingRule;
    modalRef.componentInstance.idOfRoundingRule = roundingRule.id;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      this.onSelectSingle.emit(this.clickedRowItem);
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = { PageNumber: 1, PageSize: this.query.PageSize, SortTerm: 'name', SearchTerm: '' };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
        if (myFilter.field == 'useInDynamicPricing') {
          const Filter_UseInDynamicPricing = myFilter.value;
          const Operand_UseInDynamicPricing = myFilter.operator;
          const params = { ...this.query, Filter_UseInDynamicPricing, Operand_UseInDynamicPricing };
          this.query = params;
        }
        if (myFilter.field == 'dynamicPricingRank') {
          const Filter_DynamicPricingRank = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_DynamicPricingRank = myFilter.operator;
          const params = { ...this.query, Filter_DynamicPricingRank, Operand_DynamicPricingRank };
          this.query = params;
        }
        if (myFilter.field == 'isHighestPrice') {
          const Filter_MaximumPrice = myFilter.value;
          const Operand_MaximumPrice = myFilter.operator;
          const params = { ...this.query, Filter_MaximumPrice, Operand_MaximumPrice };
          this.query = params;
        }
        if (myFilter.field == 'isLowestPrice') {
          const Filter_MinimumPrice = myFilter.value;
          const Operand_MinimumPrice = myFilter.operator;
          const params = { ...this.query, Filter_MinimumPrice, Operand_MinimumPrice };
          this.query = params;
        }
        if (myFilter.field == 'priceSourceOption.name') {
          if (myFilter.value != '(All)') {
            const Filter_PriceSource = myFilter.value;
            const Operand_PriceSource = myFilter.operator;
            const params = { ...this.query, Filter_PriceSource, Operand_PriceSource };
            this.query = params;
          }
        }
        if (myFilter.field == 'pricingFilter.name') {
          const Filter_PricingFilter = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_PricingFilter = myFilter.operator;
          const params = { ...this.query, Filter_PricingFilter, Operand_PricingFilter };
          this.query = params;
        }
        if (myFilter.field == 'priceBook.name') {
          const Filter_PriceBook = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_PriceBook = myFilter.operator;
          const params = { ...this.query, Filter_PriceBook, Operand_PriceBook };
          this.query = params;
        }
        if (myFilter.field == 'isActive') {
          const Filter_IsActive = myFilter.value;
          const Operand_IsActive = myFilter.operator;
          const params = { ...this.query, Filter_IsActive, Operand_IsActive };
          this.query = params;
        }
        if (myFilter.field == 'roundingRule.name') {
          const Filter_RoundingRule = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_RoundingRule = myFilter.operator;
          const params = { ...this.query, Filter_RoundingRule, Operand_RoundingRule };
          this.query = params;
        }
        if (myFilter.field == 'erpid') {
          const Filter_ERP = myFilter.value;
          const Operand_ERP = myFilter.operator;
          const params = { ...this.query, Filter_ERP, Operand_ERP };
          this.query = params;
        }
        if (myFilter.field == 'calculateAfterPriceSchedule.name') {
          const Filter_CalculateAfterPriceSchedule = (myFilter.value == null) ? "" : myFilter.value;
          const Operand_CalculateAfterPriceSchedule = myFilter.operator;
          const params = { ...this.query, Filter_CalculateAfterPriceSchedule, Operand_CalculateAfterPriceSchedule };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.priceScheduleService.getList(this.query);
      this.priceSchedules = response.body;

      this.parentPriceScheduleID = +this.parentPriceScheduleID; //convert to number if string
      if (this.parentPriceScheduleID !== 0) {
        this.priceSchedules = this.priceSchedules.filter(ps => ps.id !== this.parentPriceScheduleID);

        const response: any = await this.priceScheduleChildService.getList({
          Filter_PriceScheduleID: this.parentPriceScheduleID
        });
        const priceScheduleChildren = response.body;
        const childPriceScheduleIDs = priceScheduleChildren.map(child => child.childPriceScheduleID);
        this.priceSchedules = this.priceSchedules.filter(ps => !childPriceScheduleIDs.includes(ps.id));
      }

      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.priceSchedules,
        total: this.priceSchedules.length,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }
}
