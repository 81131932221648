<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Catalog.png" alt="Catalog" style="padding-right: 5px;">
  Create / Update Catalog
</div>

<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Back.png" [routerLink]="['/price/catalogs']" routerLinkActive="router-link-active" title="Go Back to List">BACK</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Save.png" (click)="onClickSave()" title="Save">SAVE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Save_Close.png" (click)="onClickSaveClose()" title="Save & Close">SAVE & CLOSE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Save_New.png" (click)="onClickSaveNew()" title="Save & New">SAVE & NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Add.png" (click)="onClickAddNew()" title="Add New">ADD NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableButtons" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">COPY</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableButtons" imageUrl="../../../../assets/images/Delete.png" (click)="onClick_Delete()" title="Delete Record">DELETE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableButtons" imageUrl="../../../../assets/images/Price_Calculation.png" (click)="onClickCalculate()" title="Calculate Catalog">CALCULATE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableButtons" imageUrl="../../../../assets/images/Actions.png" (click)="onClickBenchmark()" title="Benchmark Catalog">BENCHMARK</button>
</kendo-buttongroup>

<form [formGroup]="catalogForm">
  <div class="row">
    <div class="col-10 form-group">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Catalog Name">
        </kendo-textbox>
        <kendo-formerror>Name is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col-2 form-group d-flex align-items-center">
      <div class="wrap" style="padding-right: 10px;">
        <input type="checkbox" id="deleteLock" formControlName="deleteLock" deleteLock kendoCheckBox />
        <kendo-label class="k-checkbox-label" [for]="deleteLock" text="Delete Lock"
                     title="Prevents this Catalog and its Catalog Sheets from being deleted.">
        </kendo-label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="description" text="Description"></kendo-label>
        <kendo-textbox class="form-control" formControlName="description" placeholder="Enter Catalog Description">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>
</form>
<div class="card p-3" style="background-color:rgb(234, 234, 234)">
  <app-catalog-sheet-list #subgrid [catalogID]='idOfCatalog'
                          [pagination]="paginationObject" [loading]="loading"
                          [mode]="subgridmode">
  </app-catalog-sheet-list>
</div>
