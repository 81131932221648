export interface PricingDiagnostic {
  id: number;
  runDateTime: Date;
  resultData: string;
  resultSummary: string;
  statusCode: number;
  inputStringDoc: string;
  outputStringDoc: string;
  inputStringLine: string;
  outputStringLine: string;
  outputPrice: number;
  calculationTime: number;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyPricingDiagnostic: PricingDiagnostic = {
  id: null,
  runDateTime: null,
  resultData: '',
  resultSummary: '',
  statusCode: null,
  inputStringDoc: '',
  outputStringDoc: '',
  inputStringLine: '',
  outputStringLine: '',
  outputPrice: null,
  calculationTime: null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
