export interface PricingCondition {
  id: number;
  name: string;
  description: string;
  conditionTypeID: number;
  conditionType: {
    name: string,
    id: number
  };
  conditionalPricingFilterID: number;
  conditionalPricingFilter: {
    name: string;
    id: number;
  };
  conditionOperandID: number;
  conditionOperand: {
    name: string,
    id: number
  };
  conditionQuantity: number;
  conditionAmount: number;
  maximumAffectedQuantity: number;
  historyDateOptionID: number;
  historyDateOption: {
    name: string,
    id: number
  };
  historyDateValue: number;
  historyDate: Date;
  convertToUofM: boolean;
  convertToUofMName: string;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export function emptyPricingCondition(): PricingCondition  {
  return {
    id: null,
    name: '',
    description: null,
    conditionTypeID: null,
    conditionType: {
      name: null,
      id: null
    },
    conditionalPricingFilterID: null,
    conditionalPricingFilter: {
      name: '',
      id: null
    },
    conditionOperandID: null,
    conditionOperand: {
      name: '',
      id: null
    },
    conditionQuantity: null,
    conditionAmount: null,
    maximumAffectedQuantity: null,
    historyDateOptionID: null,
    historyDateOption: {
      name: '',
      id: null
    },
    historyDateValue: null,
    historyDate: null,
    convertToUofM: false,
    convertToUofMName: null,
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
  }
};

//export const emptyPricingCondition: PricingCondition = {
//  id: null,
//  name: '',
//  conditionTypeID: null,
//  conditionType: {
//    name: null,
//    id: null
//  },
//  conditionalPricingFilterID: null,
//  conditionalPricingFilter: {
//    name: '',
//    id: null
//  },
//  conditionOperandID: null,
//  conditionOperand: {
//    name: '',
//    id: null
//  },
//  conditionQuantity: null,
//  conditionAmount: null,
//  maximumAffectedQuantity: null,
//  historyDateOptionID: null,
//  historyDateOption: {
//    name: '',
//    id: null
//  },
//  historyDateValue: null,
//  historyDate: null,
//  convertToUofM: false,
//  convertToUofMName: null,
//  createdOn: null,
//  createdBy: '',
//  modifiedOn: null,
//  modifiedBy: ''
//};

