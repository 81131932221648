export interface NumberSource {
  id: number;
  name: string;
  description: string;
  rank: number;
  numberSourceTypeID: number;
  numberSourceType: {
    name: string,
    id: number
  };
  amount: number;
  priceBookID: number;
  priceBook: {
    name: string,
    id: number
  };
  useDefaultCurrency: boolean;
  priceScheduleID: number;
  priceSchedule: {
    name: string,
    id: number
  };
  customerAttributeID: number;
  customerAttribute: {
    name: string,
    id: number
  };
  itemAttributeID: number;
  itemAttribute: {
    name: string,
    id: number
  };
  documentAttributeID: number;
  documentAttribute: {
    name: string,
    id: number
  };
  dataMapID: number;
  dataMap: {
    name: string,
    id: number
  };
  sumColumnID: number;
  sumColumn: {
    name: string,
    id: number
  };
  pricingFilterID: number;
  pricingFilter: {
    name: string,
    id: number
  };
  historyDateOptionID: number;
  historyDateOption: {
    name: string,
    id: number
  };
  historyDateValue: number;
  historyDate: Date;
  convertToUofM: boolean;
  convertToUofMName: string;
  useExtendedValue: boolean;
  useHighest: boolean;
  numberSourceChildID: number;
  numberSourceChildName: string;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyNumberSource: NumberSource = {
  id: 0,
  name: '',
  description: '',
  rank: 0,
  numberSourceTypeID: 0,
  numberSourceType: { id: 0, name: '' },
  amount: null,
  priceBookID: null,
  priceBook: { id: 0, name: '' },
  useDefaultCurrency: false,
  priceScheduleID: null,
  priceSchedule: { id: 0, name: '' },
  customerAttributeID: null,
  customerAttribute: { id: 0, name: '' },
  itemAttributeID: null,
  itemAttribute: { id: 0, name: '' },
  documentAttributeID: null,
  documentAttribute: { id: 0, name: '' },
  dataMapID: null,
  dataMap: { id: 0, name: '' },
  sumColumnID: null,
  sumColumn: {
    name: '',
    id: null
  },
  pricingFilterID: null,
  pricingFilter: {
    name: '',
    id: null
  },
  historyDateOptionID: null,
  historyDateOption: {
    name: '',
    id: null
  },
  historyDateValue: null,
  historyDate: null,
  convertToUofM: false,
  convertToUofMName: '',
  useExtendedValue: false,
  useHighest: false,
  numberSourceChildID: null,
  numberSourceChildName: '',
  createdOn: null,
  createdBy: null,
  modifiedOn: null,
  modifiedBy: null
};
