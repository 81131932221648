export interface PriceBook {
  id: number;
  name: string;
  description: string;
  entityTypeID: number;
  entityType: {
    name: string;
    id: number;
  };
  customerAttributeID: number;
  customerAttribute: {
    name: string;
    id: number;
  };
  itemAttributeID: number;
  itemAttribute: {
    name: string;
    id: number;
  };
  documentAttributeID: number;
  documentAttribute: {
    name: string;
    id: number;
  };
  deleteLock: boolean;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyPriceBook: PriceBook = {
  id: null,
  name: '',
  description: null,
  entityTypeID: null,
  entityType: {
    name: '',
    id: null
  },
  customerAttributeID: null,
  customerAttribute: {
    name: '',
    id: null
  },
  itemAttributeID: null,
  itemAttribute: {
    name: '',
    id: null
  },
  documentAttributeID: null,
  documentAttribute: {
    name: '',
    id: null
  },
  deleteLock: null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
