<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="600" [height]="450">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Attribute_Map.png" alt="Data Maps" style="padding-right: 5px;">
      Create / Update Data Map Value
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="dataMapValueForm">

    <div class="row">
      <div class="col form-group" *ngIf="parentDataMap?.useCustomer">
        <kendo-formfield>
          <kendo-label for="customerID" text="Customer"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="customerID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="customerID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.customerID.errors,'w-100': true}"
                       placeholder="Select a Customer" [searchable]="true" (search)="search($event, filters.Customer)">
              <ng-option *ngFor="let item of customers" [value]="item.id">{{ item?.number }} - {{ item?.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Customer()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group" *ngIf="parentDataMap?.useItem">
        <kendo-formfield>
          <kendo-label for="itemID" text="Item"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="itemID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="itemID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.itemID.errors,'w-100': true}"
                       placeholder="Select an Item" [searchable]="true" (search)="search($event, filters.Item)"
                       (change)="onSelectBoxChanged($event, 'item', items)">
              <ng-option *ngFor="let item of items" [value]="item.id">{{ item?.number }} - {{ item?.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Item()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group" *ngIf="parentDataMap?.useUofM">
        <kendo-formfield>
          <kendo-label for="uofMID" text="U of M"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="uofMID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="uofMID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.uofMID.errors,'w-100': true}"
                       placeholder="Select a U of M" [searchable]="true" (search)="search($event, filters.UofM)"
                       (change)="onSelectBoxChanged($event, 'item', items)">
              <ng-option *ngFor="let item of uofMs" [value]="item.id">{{ item?.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_UofM()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group" *ngIf="parentDataMap?.useLocation">
        <kendo-formfield>
          <kendo-label for="locationID" text="Location"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="locationID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="locationID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.locationID.errors,'w-100': true}"
                       placeholder="Select a Location" [searchable]="true" (search)="search($event, filters.Location)">
              <ng-option *ngFor="let item of locations" [value]="item.id">{{ item.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Location()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group" *ngIf="parentDataMap?.useCurrency">
        <kendo-formfield>
          <kendo-label for="currencyID" text="Currency"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="currencyID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="currencyID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.currencyID.errors,'w-100': true}"
                       placeholder="Select a Currency" [searchable]="true" (search)="search($event, filters.Currency)">
              <ng-option *ngFor="let item of currencies" [value]="item.id">{{ item.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Currency()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group" *ngIf="parentDataMap?.useDates">
        <kendo-formfield>
          <kendo-label for="dateFrom" text="Date From"></kendo-label>
          <kendo-datepicker id="dateFrom" class="form-control" formControlName="dateFrom"
                            [(value)]="dateFrom" [ngClass]="{ 'is-invalid': submitted && f.dateFrom.errors }">
          </kendo-datepicker>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group" *ngIf="parentDataMap?.useDates">
        <kendo-formfield>
          <kendo-label for="dateTo" text="Date To"></kendo-label>
          <kendo-datepicker id="dateTo" class="form-control" formControlName="dateTo"
                            [(value)]="dateTo" [ngClass]="{ 'is-invalid': submitted && f.dateTo.errors }">
          </kendo-datepicker>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group" *ngIf="parentDataMap?.useAttribute1">
        <kendo-formfield>
          <kendo-label for="attribute1Value" text="Attribute 1 Value"></kendo-label>
          <kendo-textbox class="form-control" formControlName="attribute1Value" placeholder="Enter Attribute 1 Value">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group" *ngIf="parentDataMap?.useAttribute2">
        <kendo-formfield>
          <kendo-label for="attribute2Value" text="Attribute 2 Value"></kendo-label>
          <kendo-textbox class="form-control" formControlName="attribute2Value" placeholder="Enter Attribute 2 Value">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group" *ngIf="parentDataMap?.useAttribute3">
        <kendo-formfield>
          <kendo-label for="attribute3Value" text="Attribute 3 Value"></kendo-label>
          <kendo-textbox class="form-control" formControlName="attribute3Value" placeholder="Enter Attribute 3 Value">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>

    <div class="row" *ngIf="parentDataMap?.dataMapTypeID === 189">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="amount" text="Amount"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="amount" [autoCorrect]="true" [min]="-99999999999999" [max]="99999999999999"
                                [decimals]="5" [format]="'n5'" [ngClass]="{ 'is-invalid': submitted && f.amount.errors }"
                                placeholder="Enter an Amount">
          </kendo-numerictextbox>
        </kendo-formfield>
      </div>
    </div>
  </form>

  <div class="row" *ngIf="parentDataMap?.dataMapTypeID === 190">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="numberSourceID" text="Number Source"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="numberSourceID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="numberSourceID" [notFoundText]="'Please start typing to search'"
                     [ngClass]="{'is-invalid': submitted && f.numberSourceID.errors,'w-100': true}"
                     placeholder="Select a NumberSource" [searchable]="true" (search)="search($event, filters.NumberSource)">
            <ng-option *ngFor="let item of numberSources" [value]="item.id">{{ item.name }}</ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton look="clear" (click)="onLookup_NumberSource()" icon="search"></button>
        </span>
      </kendo-formfield>
    </div>
  </div>

  <div class="row" *ngIf="parentDataMap?.dataMapTypeID === 191">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="priceCalculationID" text="Price Calculation"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="priceCalculationID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="priceCalculationID" [notFoundText]="'Please start typing to search'"
                     [ngClass]="{'is-invalid': submitted && f.priceCalculationID.errors,'w-100': true}"
                     placeholder="Select a PriceCalculation" [searchable]="true" (search)="search($event, filters.PriceCalculation)">
            <ng-option *ngFor="let item of priceCalculations" [value]="item.id">{{ item.name }}</ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton look="clear" (click)="onLookup_PriceCalculation()" icon="search"></button>
        </span>
      </kendo-formfield>
    </div>
  </div>

  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
