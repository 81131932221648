import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, ElementRef, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataMapValuesService } from '../../../services/data-map-values/data-map-values.service';
import { DataMapValue } from '../../../services/data-map-values/data-map-values.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { DataMapValueSingleComponent } from '../data-map-value-single/data-map-value-single.component';
import { GridDataResult, PageChangeEvent, GridComponent, ExcelComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ColumnSettings } from "../../../../app/utils/column-settings.interface";
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service'
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { DataMap } from '../../../services/data-maps/data-maps.model';
import { DataMapsService } from '../../../services/data-maps/data-maps.service';

@Component({
  selector: 'app-data-map-value-list',
  templateUrl: './data-map-value-list.component.html',
  styleUrls: ['./data-map-value-list.component.scss'],
})
export class DataMapValueListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private dataMapValuesService: DataMapValuesService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private lookupService: LookupsService,
    private dataMapsService: DataMapsService,
    private utilitiesService: UtilitiesService,
    private notificationHelper: NotificationHelper
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }
  @Input() loading: boolean = true;
  @Input() dataMapID: number;
  @Input() dataMapValues: DataMapValue[] = [];
  @Input() query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '', Filters: [] };
  @Input() pagination: Pagination = mockedData;
  @Input() attribute1ColumnName: string = 'Attribute 1 Value';
  @Input() attribute2ColumnName: string = 'Attribute 2 Value';
  @Input() attribute3ColumnName: string = 'Attribute 3 Value';
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: DataMapValue[];
  parentDataMap: DataMap | null = null;
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public globalRecordCount: number = 1;
  public clickedRowItem;
  public gridClassName: string = "DataMapValueSubgridClass";
  public filterable: boolean;
  public dataMapValueQuantityRuleOptions: Lookup[];
  public sort: SortDescriptor[] = [
    {
      field: 'attributeValue1',
      dir: 'asc',
    },
    {
      field: 'attributeValue2',
      dir: 'asc',
    },
    {
      field: 'attributeValue3',
      dir: 'asc',
    },
    {
      field: 'amount',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Customer Number',
      field: 'customer.number',
      width: 150,
      orderIndex: 0,
      hidden: false
    },
    {
      title: 'Customer Name',
      field: 'customer.name',
      width: 200,
      orderIndex: 1,
      hidden: false
    },
    {
      title: 'Item Number',
      field: 'item.number',
      width: 150,
      orderIndex: 2,
      hidden: false
    },
    {
      title: 'Item Name',
      field: 'item.name',
      width: 200,
      orderIndex: 3,
      hidden: false
    },
    {
      title: 'Location',
      field: 'location.name',
      width: 200,
      orderIndex: 4,
      hidden: false
    },
    {
      title: 'Currency Name',
      field: 'currency.name',
      width: 150,
      orderIndex: 5,
      hidden: false
    },
    {
      title: 'Currency Code',
      field: 'currency.alphacode',
      width: 100,
      orderIndex: 6,
      hidden: false
    },
    {
      title: 'Date From',
      field: 'dateFrom',
      width: 100,
      orderIndex: 7,
      hidden: false
    },
    {
      title: 'Date To',
      field: 'dateTo',
      width: 100,
      orderIndex: 8,
      hidden: false
    },
    {
      title: 'Attribute 1 Value',
      field: 'attribute1Value',
      width: 150,
      orderIndex: 9,
      hidden: false
    },
    {
      title: 'Attribute 2 Value',
      field: 'attribute2Value',
      width: 150,
      orderIndex: 10,
      hidden: false
    },
    {
      title: 'Attribute 3 Value',
      field: 'attribute3Value',
      width: 150,
      orderIndex: 11,
      hidden: false
    },
    {
      title: 'Number Source',
      field: 'numberSource.name',
      width: 150,
      orderIndex: 12,
      hidden: false
    },
    {
      title: 'Amount',
      field: 'amount',
      width: 100,
      orderIndex: 13,
      hidden: false
    },
  ];
  columnsConfig: ColumnSettings[];
  public actionMenuItems: any[] = [];

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.getParentDataMap();
    //console.log('data map ID is ' + this.dataMapID);
    //console.log('parent data map is ' + this.parentDataMap); //returns null
    //console.log('use cust flag is ' + this.parentDataMap?.useCustomer);
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataMapID && !changes.dataMapID.firstChange) {
      // We now have an Parent ID so we can change the tooltip.
      document.getElementById('btnAdd').setAttribute('title', 'Add New');
    }
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.dataMapValuesService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = this.globalRecordCount;
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) {
        if (options.optSiblingRecords) {
          excelComponent.fetchData = this.allGlobalSiblingData;
        }
        else {
          excelComponent.fetchData = this.allData;
        }
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }

  async getParentDataMap(): Promise<void> {
    if (this.dataMapID) {
      try {
        console.log('getting parent data map');
        this.parentDataMap = await this.dataMapsService.getById(this.dataMapID);
      } catch (error) {
        console.log('error getting data map');
        this.parentDataMap = null;
      }
    } else {
      this.parentDataMap = null;
    }
  }

  public allGlobalSiblingData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.dataMapValuesService.getExportList({
      ...this.query
    });
  }

  async getGlobalSiblingDataCount() {
    this.loading = true;
    try {
      const response: any = await this.dataMapValuesService.getList({ PageSize: 1, PageNumber: 1 });
      this.loading = false;
      const pagination = getPaginationHeader(response.headers);
      this.globalRecordCount = pagination.TotalCount;
    } catch (error) {
      this.loading = false;
      console.error("Error fetching total count: ", error);
    }
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('DataMapValueList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('DataMapValueList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('DataMapValueList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.hidden = columnConfig.hidden;
        column.orderIndex = columnConfig.orderIndex;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('DataMapValueList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;
    this.loadListData();
    this.buildActionsMenu();
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onRefresh() {
    this.loadListData();
  }

  async onFileChange(evt: any) {
    await this.importsService.onFileChange(evt, 'RPMDataMapValue');
    this.fileUploader.nativeElement.value = null;
    this.loadListData();
  }

  onFileChangeOLD(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      //ws.forEach(function(y) {
      //var worksheet = wb.Sheets[y];
      var headers = {};
      var data = [];
      for (const z in ws) {
        if (z[0] === '!') continue;
        //parse out the column, row, and value
        var col = z.substring(0, 1);
        var row = parseInt(z.substring(1));
        var value = ws[z].v;

        //store header names
        if (row == 1) {
          headers[col] = String(value);
          continue;
        }

        if (!data[row]) data[row] = {};
        data[row][headers[col]] = String(value).trim();
      }
      //drop those first two rows which are empty
      data.shift();
      data.shift();
      console.log(data);
      //});
      var myJSONString = JSON.stringify(data);
      var myEscapedJSONString = myJSONString
        .replace(/[\\]/g, '\\\\')
        .replace(/[\']/g, "\\'")
        .replace(/[\"]/g, '\\"')
        .replace(/[\/]/g, '\\/')
        .replace(/[\b]/g, '\\b')
        .replace(/[\f]/g, '\\f')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t');

      const inputXML: string = "'" + myEscapedJSONString + "'";
      this.performImport(inputXML);
    };

    reader.readAsBinaryString(target.files[0]);

    this.fileUploader.nativeElement.value = null;
  }

  async performImport(inputXML) {
    try {
      const response: any = await this.importsService.performImport(inputXML, 'RPMDataMapValue');
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Import successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
        this.loadListData();
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      this.onEdit(this.clickedRowItem);
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = { PageNumber: 1, PageSize: this.query.PageSize, SortTerm: 'attribute1Value', SearchTerm: '', Filters: [] };  //mhr
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;

        //mhr this code ChatGPT made more universal
        const filterValue = myFilter.value || "";
        const filterField = `Filter_${myFilter.field}`;
        const operandField = `Operand_${myFilter.field}`;

        const params = { ...this.query, [filterField]: filterValue, [operandField]: myFilter.operator };
        this.query = params;

        //  if (myFilter.field == 'attribute1Value') {
        //    const Filter_Attribute1Value = (myFilter.value == null) ? "" : myFilter.value;
        //    const Operand_Attribute1Value = myFilter.operator;
        //    const params = { ...this.query, Filter_Attribute1Value, Operand_Attribute1Value };
        //    this.query = params;
        //  }
        //  if (myFilter.field == 'attribute2Value') {
        //    const Filter_Attribute2Value = (myFilter.value == null) ? "" : myFilter.value;
        //    const Operand_Attribute2Value = myFilter.operator;
        //    const params = { ...this.query, Filter_Attribute2Value, Operand_Attribute2Value };
        //    this.query = params;
        //  }
        //  if (myFilter.field == 'attribute3Value') {
        //    const Filter_Attribute3Value = (myFilter.value == null) ? "" : myFilter.value;
        //    const Operand_Attribute3Value = myFilter.operator;
        //    const params = { ...this.query, Filter_Attribute3Value, Operand_Attribute3Value };
        //    this.query = params;
        //  }
        //  if (myFilter.field == 'amount') {
        //    const Filter_Amount = myFilter.value;
        //    const Operand_Amount = myFilter.operator;
        //    const params = { ...this.query, Filter_Amount, Operand_Amount };
        //    this.query = params;
        //  }
      });
    }
    this.onFilterChange();
  }

  public gridFilterChangeNEW(filter: CompositeFilterDescriptor): void { //mhr
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'attribute1Value',
      SearchTerm: '',
      Filters: []  // Initializing an empty Filters array
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;

        const filterValue = myFilter.value || "";
        const filterObj = {
          Field: myFilter.field,
          Value: filterValue,
          Operator: myFilter.operator
        };

        this.query.Filters.push(filterObj);
      });
    }
    this.onFilterChange();
  }


  edit(id: number) {
    this.router.navigate([`/price/data-map-value/${id}`]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.dataMapValuesService.getExportList({
      Filter_DataMapID: this.dataMapID, ...this.query
    });
  }
  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.dataMapValuesService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(
          err.error,
          '',
          true,
          'Error',
          'alert-danger',
        );
      });

    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }
  get isMenuDisabled(): boolean {
    return this.dataMapID === null;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      if (this.dataMapID) {
        document.getElementById('btnAdd').setAttribute('title', 'Add New');
        const response: any = await this.dataMapValuesService.getList({
          Filter_DataMapID: this.dataMapID, ...this.query
        });
        this.dataMapValues = response.body;
        this.pagination = getPaginationHeader(response.headers);
        this.gridView = {
          data: this.dataMapValues,
          total: this.pagination.TotalCount,
        };
      }
    } catch (e) {
    } finally {
      this.loading = false;
    }
    this.getGlobalSiblingDataCount();
  }

  onCreate() {
    const modalRef = this.modalService.open(
      DataMapValueSingleComponent,
      {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: "DataMapValueModalClass",
        centered: true,
        backdrop: 'static',
      },
    );
    modalRef.componentInstance.idOfDataMap = this.dataMapID;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      //modalRef.close();
    });
  }

  onEdit(dataMapValue) {
    const modalRef = this.modalService.open(
      DataMapValueSingleComponent,
      {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: "DataMapValueModalClass",
        centered: true,
        backdrop: 'static',
      },
    );
    modalRef.componentInstance.idOfDataMap = this.dataMapID;
    modalRef.componentInstance.idOfDataMapValue = dataMapValue.id;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      //modalRef.close();
    });
  }

  async buildActionsMenu(): Promise<void> {
    this.actionMenuItems = [
      {
        text: "Actions",
        //disabled: this.isMenuDisabled, //needs work to be dynamic, doesn't update when dataMapID populates.
        items: [
          {
            text: "Add Values for Attribute",
            column: 0,
          },
        ],
      },
    ];
  }

  async actionsMenuItemSelect(event: any): Promise<void> {
    if (!this.dataMapID) {
      return;
    }
    const selectedItemText = event.item.text;
    if (selectedItemText === "Actions") {
      const attributeMenuItems = [];
      if (this.attribute1ColumnName.startsWith('Doc') || this.attribute1ColumnName.startsWith('Item') || (this.attribute1ColumnName.startsWith('Cust'))) {
        attributeMenuItems.push({ text: this.attribute1ColumnName, column: 1 });
      }
      if (this.attribute2ColumnName.startsWith('Doc') || this.attribute2ColumnName.startsWith('Item') || (this.attribute2ColumnName.startsWith('Cust'))) {
        attributeMenuItems.push({ text: this.attribute2ColumnName, column: 2 });
      }
      if (this.attribute3ColumnName.startsWith('Doc') || this.attribute3ColumnName.startsWith('Item') || (this.attribute3ColumnName.startsWith('Cust'))) {
        attributeMenuItems.push({ text: this.attribute3ColumnName, column: 3 });
      }
      if (attributeMenuItems.length == 0) {
        attributeMenuItems.push({ text: "(No Attributes Qualify)", column: 0 });
      }
      this.actionMenuItems[0].items[0].items = [];
      this.actionMenuItems[0].items[0].items.push(...attributeMenuItems);
    }
    if (selectedItemText.startsWith('Doc') || selectedItemText.startsWith('Item') || (selectedItemText.startsWith('Cust'))) {
      const attributeIndex = event.item.column;
      try {
        const params = [
          {
            Name: "@DataMapID",
            Value: this.dataMapID
          },
          {
            Name: "@MenuText",
            Value: selectedItemText
          },
          {
            Name: "@AttributeNumber",
            Value: attributeIndex
          }
        ];
        const paramList = JSON.stringify(params);
        const response: any = await this.utilitiesService.callStoredProc('usp_RPMUtilityCreateDataMapValues', paramList);
        const status: any = response.status;
        if (status === 200) {
          this.snack.openSnackBar(
            'Records created successfully from available data.' + response.body,
            '',
            false,
            'Success',
            'alert-success',
          );
          this.loadListData();
        }
      } catch (e) {
        this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
      }
      this.onRefresh();
    }
  }

}
