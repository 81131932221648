import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { debounce } from 'lodash';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { Currency, emptyCurrency } from '../../../services/currencies/currencies.model';
import { CurrenciesService } from '../../../services/currencies/currencies.service';
import { CurrencyLookupComponent } from '../../currencies/currency-lookup/currency-lookup.component';
import { PriceSchedule, emptyPriceSchedule } from '../../../services/price-schedules/price-schedules.model';
import { Location, emptyLocation } from '../../../services/locations/locations.model';
import { Customer, emptyCustomer } from '../../../services/customers/customers.model';
import { Item, emptyItem } from '../../../services/items/items.model';
import { UofM, emptyUofM } from '../../../services/uofms/uofms.model';
import { PriceSchedulesService } from '../../../services/price-schedules/price-schedules.service';
import { LocationsService } from '../../../services/locations/locations.service';
import { CustomersService } from '../../../services/customers/customers.service';
import { ItemsService } from '../../../services/items/items.service';
import { UofMsService } from '../../../services/uofms/uofms.service';
import { LocationLookupComponent } from '../../locations/location-lookup/location-lookup.component';
import { CustomerLookupComponent } from '../../customers/customer-lookup/customer-lookup.component';
import { PriceScheduleLookupComponent } from '../../price-schedules/price-schedule-lookup/price-schedule-lookup.component';
import { ItemLookupComponent } from '../../items/item-lookup/item-lookup.component';
import { UofMLookupComponent } from '../../uofms/uofm-lookup/uofm-lookup.component';
import { ToolsService } from '../../../services/tools/tools.service';
import { GLAccountsService } from '../../../services/glaccounts/glaccounts.service';
import { GLAccount } from '../../../services/glaccounts/glaccounts.model';
import { SettingsService } from '../../../services/settings/settings.service';
import { PricingDiagnosticsService } from 'src/app/services/pricing-diagnostics/pricing-diagnostics.service';
import { PricingDiagnostic } from 'src/app/services/pricing-diagnostics/pricing-diagnostics.model';
import { DatePipe } from '@angular/common';
import { PricingDiagnosticSingleComponent } from '../../pricing-diagnostics/pricing-diagnostic-single/pricing-diagnostic-single.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { PriceAdjustment } from 'src/app/services/price-adjustments/price-adjustments.model';
import { PricingScenario, emptyPricingScenario } from 'src/app/services/pricing-scenarios/pricing-scenarios.model';
import { PricingScenarioDocumentValue } from 'src/app/services/pricing-scenario-document-values/pricing-scenario-document-values.model';
import { PricingScenariosService } from 'src/app/services/pricing-scenarios/pricing-scenarios.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Setting } from 'src/app/services/settings/settings.model';
import { parseDate } from '@progress/kendo-angular-intl';
import { PricingScenarioDocumentValuesService } from 'src/app/services/pricing-scenario-document-values/pricing-scenario-document-values.service';
import { ViewChild } from '@angular/core';
import { PricingScenarioDocumentValueListComponent } from '../../pricing-scenario-document-values/pricing-scenario-document-value-list/pricing-scenario-document-value-list.component';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { CopyService } from '../../../services/copy/copy.service';
import { PricingScenarioXMLSingleComponent } from '../pricing-scenario-xml-single/pricing-scenario-xml-single.component';
import { PricePreviewComponent } from '../../price-preview/price-preview.component';


enum Filters {
  PriceSchedule = 1,
  Item = 2,
  UnitOfMeasure = 3,
  Currency = 4,
  Customer = 5,
  Location = 6,
  ExpectedPriceSchedule = 7
}
@Component({
  selector: 'app-pricing-scenario-single',
  templateUrl: './pricing-scenario-single.component.html',
  styleUrls: ['./pricing-scenario-single.component.scss'],
})
export class PricingScenarioSingleComponent implements OnInit {
  @ViewChild('subgrid') pricingScenarioDocValuesSubGrid: PricingScenarioDocumentValueListComponent;
  filters = Filters;
  idOfPricingScenario: number | null = null;
  pricingScenario: PricingScenario = emptyPricingScenario;
  loading = true;
  pricingScenarioForm: FormGroup;
  priceSchedules: PriceSchedule[] = [];
  expectedPriceSchedules: PriceSchedule[] = [];
  currencies: Currency[] = [];
  locations: Location[] = [];
  customers: Customer[] = [];
  items: Item[] = [];
  uofMs: UofM[] = [];
  pricingScenarioDocumentValues: PricingScenarioDocumentValue[] = [];
  selectedPriceSchedule: PriceSchedule = emptyPriceSchedule;
  selectedExpectedPriceSchedule: PriceSchedule = emptyPriceSchedule;
  selectedCurrency: Currency = emptyCurrency;
  selectedLocation: Location = emptyLocation;
  selectedCustomer: Customer = emptyCustomer;
  selectedItem: Item = emptyItem;
  selectedUofM: UofM = emptyUofM;
  defaultCurrencyID: number;
  singleCurrency: boolean;
  submitted = false;
  paginationObject: any = {
    pricingScenarioDocumentValues: mockedData,
    currencies: mockedData,
    priceSchedules: mockedData,
    locations: mockedData,
    customers: mockedData,
    items: mockedData,
    uofMs: mockedData,
  };
  currencyID: number = null;
  @Output() onFormChanged = new EventEmitter<Boolean>();
  effectiveDate: Date = new Date();
  pricingDiagnosticRunID: number;
  processStatus: number = null;
  public gridView: GridDataResult;
  priceAdjustments: PriceAdjustment[] = [];
  isFormChanged = false;
  goBackForce = false;
  nextURL = '';
  builtInputXML = '';

  public min: Date = new Date(2000, 0);
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pricingScenariosService: PricingScenariosService,
    private priceSchedulesService: PriceSchedulesService,
    private currenciesService: CurrenciesService,
    private locationsService: LocationsService,
    private customersService: CustomersService,
    private itemsService: ItemsService,
    private uofMsService: UofMsService,
    private settingsService: SettingsService,
    private toolsService: ToolsService,
    private glAccountsService: GLAccountsService,
    private pricingDiagnosticsService: PricingDiagnosticsService,
    private pricingScenarioDocumentValuesService: PricingScenarioDocumentValuesService,
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private datePipe: DatePipe,
    private copyService: CopyService
  ) {
    this.route.params.subscribe(params => {
      if (params.id && params.id === 'new') {
        this.selectedPriceSchedule = emptyPriceSchedule;
        this.selectedCurrency = emptyCurrency;
        this.selectedLocation = emptyLocation;
        this.selectedCustomer = emptyCustomer;
        this.selectedItem = emptyItem;
        this.selectedUofM = emptyUofM;

        this.pricingScenario = emptyPricingScenario;
        this.idOfPricingScenario = null;
        this.submitted = false;
        this.pricingScenario.effectiveDate = new Date();
        this.pricingDiagnosticRunID = null;
        this.pricingScenario.quantity = 1;
        this.pricingScenario.valueFromERP = 0;
        this.processStatus = null;
        this.updateForm();
        if (this.pricingScenarioDocValuesSubGrid) {
          this.pricingScenarioDocValuesSubGrid.pricingScenarioID = null;
          this.pricingScenarioDocValuesSubGrid.loadSubGridData();
        }
        this.gridView = {
          data: [],
          total: this.priceAdjustments.length,
        };
        return;
      }
      this.idOfPricingScenario = params.id;
      this.loadData();
      // this.updateForm();
    });
    this.priceScheduleSearch = debounce(this.priceScheduleSearch, 400, {});
    this.currencySearch = debounce(this.currencySearch, 400, {});
    this.locationSearch = debounce(this.locationSearch, 400, {});
    this.customerSearch = debounce(this.customerSearch, 400, {});
    this.itemSearch = debounce(this.itemSearch, 400, {});
    this.uofMSearch = debounce(this.uofMSearch, 400, {});
  }

  ngOnInit(): void {
    this.buildForm();
    this.pricingScenarioForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
    //this.loadData();
    //this.updateForm();
  }

  buildForm() {
    this.pricingScenarioForm = this.fb.group({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      expectedUnitPriceAmount: new FormControl(),
      expectedPriceScheduleID: new FormControl(''),
      priceScheduleID: new FormControl(),
      effectiveDate: new FormControl(new Date()),
      useToday: new FormControl(false),
      quantity: new FormControl(1),
      valueFromERP: new FormControl(0),
      itemID: new FormControl(),
      uofMID: new FormControl(),
      currencyID: new FormControl(),
      currencyLabelName: new FormControl(),
      customerID: new FormControl(),
      locationID: new FormControl(),
      lastReturnedUnitPrice: new FormControl(''),
      pricingRunID: new FormControl(''),
      lastReturnedPriceScheduleName: new FormControl(''),
      lastExecutionDateTime: new FormControl(new Date()),
      multiLineTag: new FormControl(''),
      scenarioGroup: new FormControl(''),
      processStatus: new FormControl()
    });
    if (!this.idOfPricingScenario) {
      this.getDefaultCurrencySettings();
    }
    this.getUseSingleCurrencySettings();
  }
  get f() {
    return this.pricingScenarioForm.controls;
  };

  updateForm() {
    if (this.pricingScenarioForm) {
      this.pricingScenarioForm.patchValue({ ...this.pricingScenario });
      this.isFormChanged = false;
    }
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMPricingScenario", this.pricingScenario.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  onUseTodayChanged() {
    if (this.pricingScenarioForm.get('useToday').value == true) {
      this.pricingScenarioForm.get('effectiveDate').disable()
      this.pricingScenario.effectiveDate = null;
      this.pricingScenarioForm.controls['effectiveDate'].patchValue(null);
    } else {
      this.pricingScenarioForm.get('effectiveDate').enable()
    }
  }

  onClickSave() {
    this.submit(null);
  }

  onClickSaveClose() {
    this.submit("Close");
  }

  onClickSaveNew() {
    this.submit("New");
  }

  onClickAddNew() {
    this.router.navigate(['/price/pricing-scenario/new'], { replaceUrl: true });
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.pricingScenario.id);
    await this.pricingScenariosService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/pricing-scenarios']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.pricingScenario?.id) {
      return false;
    }
    else {
      return true;
    }
  }

  closeModal = () => {
    this.modalService.dismissAll();
  };

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  };

  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

  async submit(action, showNotification: boolean = true) {
    this.pricingScenarioForm.markAllAsTouched();
    this.submitted = true;
    if (this.pricingScenarioForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.pricingScenario,
      ...this.pricingScenarioForm.value
    };

    // Check to see if Pricing Scenario Name already exists.
    if ((await this.checkForExistingName()) === true) {
      this.snack.openSnackBar(
        'Pricing Scenario with the same Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.pricingScenario.id) {
        try {
          const response: any = await this.pricingScenariosService.update(
            this.pricingScenario.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            if (showNotification) {
              this.snack.openSnackBar(
                'Record updated successfully!',
                '',
                false,
                'Success',
                'alert-success',
              );
            }
            if (action == "Close") {
              this.router.navigate(['/price/pricing-scenarios']);
            }
            else if (action == "New") {
              this.pricingScenario = emptyPricingScenario;
              this.idOfPricingScenario = null;
              this.submitted = false;
              this.updateForm();
              this.buildForm();
            }
          }
        } catch (e) {
          if (showNotification) {
            this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
          }
        }
      } else {
        try {
          const response: any = await this.pricingScenariosService.create(
            this.pricingScenarioForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.pricingScenario = response.body;
            if (showNotification) {
              this.snack.openSnackBar(
                'Record saved successfully!',
                '',
                false,
                'Success',
                'alert-success',
              );
            }
            if (action == "Close") {
              this.router.navigate(['/price/pricing-scenarios']);
            }
            else if (action == "New") {
              this.pricingScenario = emptyPricingScenario;
              this.idOfPricingScenario = null;
              this.submitted = false;
              this.updateForm();
              this.buildForm();
            }
            else {
              this.pricingScenario = response.body;
              this.idOfPricingScenario = this.pricingScenario.id;
              this.router.navigate(['/price/pricing-scenario/', this.pricingScenario.id]);
            }
          }
        } catch (e) {
          if (showNotification) {
            this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
          }
        }
      }
    }
    this.isFormChanged = false;
  }

  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.pricingScenarioForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingPricingScenarios: PricingScenario[] = await (
      await this.pricingScenariosService.getList(params)
    ).body;
    if (existingPricingScenarios.length > 0) {
      if (this.pricingScenario.id) {
        for (const key in existingPricingScenarios) {
          const currentPricingScenario: PricingScenario = existingPricingScenarios[key];
          if (currentPricingScenario.id !== this.pricingScenario.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async buildInputXML() {
    var inputDoc: XMLDocument = new Document();
    var documentNode = inputDoc.createElement('Document'); //TODO this is where we add stuff for multi doc lines
    inputDoc.appendChild(documentNode);
    var rootNode = inputDoc.createElement('DocumentLine');
    documentNode.appendChild(rootNode);
    var docLineIDNode = inputDoc.createElement('DocumentLineID');
    docLineIDNode.innerHTML = this?.idOfPricingScenario?.toString() ?? 'Test';
    rootNode.appendChild(docLineIDNode);

    var priceScheduleIDNode = inputDoc.createElement('PriceScheduleID');
    var encodedPriceScheduleID = this.selectedPriceSchedule?.erpid?.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });
    priceScheduleIDNode.innerHTML = encodedPriceScheduleID ?? '';
    //priceScheduleIDNode.innerHTML = this.selectedPriceSchedule?.erpid ?? '';
    rootNode.appendChild(priceScheduleIDNode);

    var customerIDNode = inputDoc.createElement('CustomerID');
    var encodedCustomerID = this.selectedCustomer?.erpid?.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });
    customerIDNode.innerHTML = encodedCustomerID ?? '';
    //customerIDNode.innerHTML = encodeURIComponent(this.selectedCustomer?.erpid) ?? '';
    rootNode.appendChild(customerIDNode);

    var itemIDNode = inputDoc.createElement('ItemID');
    var encodedItemID = this.selectedItem?.erpid?.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });
    itemIDNode.innerHTML = encodedItemID ?? '';
    //itemIDNode.innerHTML = this.selectedItem?.erpid ?? '';
    rootNode.appendChild(itemIDNode);

    var locationIDNode = inputDoc.createElement('LocationID');
    var encodedLocationID = this.selectedLocation?.erpid?.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });
    locationIDNode.innerHTML = encodedLocationID ?? '';
    //locationIDNode.innerHTML = this.selectedLocation?.erpid ?? '';
    rootNode.appendChild(locationIDNode);

    var uofMIDNode = inputDoc.createElement('UofMID');
    var encodedUofMID = this.selectedUofM?.erpid?.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });
    uofMIDNode.innerHTML = encodedUofMID ?? '';
    //uofMIDNode.innerHTML = this.selectedUofM?.erpid ?? '';
    rootNode.appendChild(uofMIDNode);

    var quantityNode = inputDoc.createElement('Quantity');
    quantityNode.innerHTML = this.f.quantity?.value ?? 0;
    rootNode.appendChild(quantityNode);
    var currencyIDNode = inputDoc.createElement('CurrencyID');
    currencyIDNode.innerHTML = this.selectedCurrency?.erpid ?? '';
    rootNode.appendChild(currencyIDNode);
    var dateNode = inputDoc.createElement('Date');
    var useDate = this.f?.effectiveDate?.value ?? new Date()
    if (this.f?.useToday?.value == true) {
      useDate = new Date()
    }
    //const formattedEffectiveDate = this.datePipe.transform(this.f?.effectiveDate?.value ?? new Date(), 'MM/dd/yyyy');
    const formattedEffectiveDate = this.datePipe.transform(useDate, 'MM/dd/yyyy');
    dateNode.innerHTML = formattedEffectiveDate;
    rootNode.appendChild(dateNode);
    var originatingUnitPriceNode = inputDoc.createElement('OriginatingUnitPrice');
    originatingUnitPriceNode.innerHTML = this.f.valueFromERP?.value ?? '';
    rootNode.appendChild(originatingUnitPriceNode);

    if (this.idOfPricingScenario) {
      const response: any = await this.pricingScenarioDocumentValuesService.getList({ Filter_PricingScenarioID: this.idOfPricingScenario });
      this.pricingScenarioDocumentValues = response.body;
      if (this.pricingScenarioDocumentValues && this.pricingScenarioDocumentValues.length > 0) {
        var documentAttributesNode = inputDoc.createElement('DocumentAttributes');
        rootNode.appendChild(documentAttributesNode);
        for (let i = 0; i < this.pricingScenarioDocumentValues.length; i++) {
          const singleAttribute = this.pricingScenarioDocumentValues[i];
          var singleAttributeNode = inputDoc.createElement('DocumentAttribute');
          singleAttributeNode.setAttribute('name', singleAttribute.documentAttribute.name);
          singleAttributeNode.innerHTML = singleAttribute.value ?? '';
          documentAttributesNode.appendChild(singleAttributeNode);
        }
      }
    }

    // TODO: Determine why we have to add these single quotes.
    const inputXML: string =
      "'" +
      new XMLSerializer().serializeToString(inputDoc.documentElement) +
      "'";

    return await inputXML;
  }

  async buildPreviewInputXML() {
    var inputDoc: XMLDocument = new Document();
    var documentNode = inputDoc.createElement('Document');
    inputDoc.appendChild(documentNode);
    var rootNode = inputDoc.createElement('DocumentLine');
    documentNode.appendChild(rootNode);
    var docLineIDNode = inputDoc.createElement('DocumentLineID');
    docLineIDNode.innerHTML = this?.idOfPricingScenario?.toString() ?? 'Test';
    rootNode.appendChild(docLineIDNode);

    var customerNumberNode = inputDoc.createElement('CustomerNumber');
    var encodedCustomerNumber = this.selectedCustomer?.number?.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });
    customerNumberNode.innerHTML = encodedCustomerNumber ?? '';
    rootNode.appendChild(customerNumberNode);

    var itemNumberNode = inputDoc.createElement('ItemNumber');
    var encodedItemNumber = this.selectedItem?.number?.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });
    itemNumberNode.innerHTML = encodedItemNumber ?? '';
    rootNode.appendChild(itemNumberNode);

    var locationIDNode = inputDoc.createElement('LocationID');
    var encodedLocationID = this.selectedLocation?.erpid?.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });
    locationIDNode.innerHTML = encodedLocationID ?? '';
    rootNode.appendChild(locationIDNode);

    var uofMNameNode = inputDoc.createElement('UofMName');
    var encodedUofMName = this.selectedUofM?.name?.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });
    uofMNameNode.innerHTML = encodedUofMName ?? '';
    rootNode.appendChild(uofMNameNode);

    var quantityNode = inputDoc.createElement('Quantity');
    quantityNode.innerHTML = this.f.quantity?.value ?? 0;
    rootNode.appendChild(quantityNode);
    var currencyAlphaCodeNode = inputDoc.createElement('CurrencyAlphaCode');
    currencyAlphaCodeNode.innerHTML = this.selectedCurrency?.alphaCode ?? '';
    rootNode.appendChild(currencyAlphaCodeNode);
    var dateNode = inputDoc.createElement('Date');
    var useDate = this.f?.effectiveDate?.value ?? new Date()
    if (this.f?.useToday?.value == true) {
      useDate = new Date()
    }
    const formattedEffectiveDate = this.datePipe.transform(useDate, 'MM/dd/yyyy');
    dateNode.innerHTML = formattedEffectiveDate;
    rootNode.appendChild(dateNode);
    var originatingUnitPriceNode = inputDoc.createElement('OriginatingUnitPrice');
    originatingUnitPriceNode.innerHTML = this.f.valueFromERP?.value ?? '';
    rootNode.appendChild(originatingUnitPriceNode);

    if (this.idOfPricingScenario) {
      const response: any = await this.pricingScenarioDocumentValuesService.getList({ Filter_PricingScenarioID: this.idOfPricingScenario });
      this.pricingScenarioDocumentValues = response.body;
      if (this.pricingScenarioDocumentValues && this.pricingScenarioDocumentValues.length > 0) {
        var documentAttributesNode = inputDoc.createElement('DocumentAttributes');
        rootNode.appendChild(documentAttributesNode);
        for (let i = 0; i < this.pricingScenarioDocumentValues.length; i++) {
          const singleAttribute = this.pricingScenarioDocumentValues[i];
          var singleAttributeNode = inputDoc.createElement('DocumentAttribute');
          singleAttributeNode.setAttribute('name', singleAttribute.documentAttribute.name);
          singleAttributeNode.innerHTML = singleAttribute.value ?? '';
          documentAttributesNode.appendChild(singleAttributeNode);
        }
      }
    }

    // TODO: Determine why we have to add these single quotes.
    const previewInputXML: string =
      "'" +
      new XMLSerializer().serializeToString(inputDoc.documentElement) +
      "'";

    return await previewInputXML;
  }

  async execute() {
    this.priceAdjustments = [];
    this.gridView = {
      data: this.priceAdjustments,
      total: this.priceAdjustments.length,
    };
    const inputXML: string = await this.buildInputXML();
    this.executeGetPrice(inputXML);
  }

  async onClickPricePreview() {
    if (this.pricingDiagnosticRunID) {
      const inputXML: string = await this.buildPreviewInputXML();
      const outputDoc = await this.executeGetPricePreview(inputXML);
      const modalRef = this.modalService.open(PricePreviewComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        centered: true,
        backdrop: 'static',
      });
      //console.log(outputDoc);
      modalRef.componentInstance.previewSet = outputDoc;
    }
    else {
      this.snack.openSnackBar(
        'Please Save the Current Pricing Scenario first.',
        '',
        false,
        'Info',
        'alert-info',
      );
    }
  }

  async getPriceScheduleByERPId(erpid) {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERP = erpid;
    const Operand_ERP = 'eq';
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingPriceSchedules: PriceSchedule[] = await (await this.priceSchedulesService.getList(params)).body;
    if (existingPriceSchedules.length == 1) {
      return existingPriceSchedules[0];
    }
  }

  async getGLAccountByERPId(erpid) {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERP = erpid;
    const Operand_ERP = 'eq';
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingGLAccounts: GLAccount[] = await (await this.glAccountsService.getList(params)).body;
    if (existingGLAccounts.length == 1) {
      return existingGLAccounts[0];
    }
  }

  async getPricingScenarioById() {
    if (this.idOfPricingScenario) {
      return await this.pricingScenariosService.getById(this.idOfPricingScenario);
    } else {
      return emptyPricingScenario;
    }
  }

  async getPriceScheduleByID(id) {
    return await this.priceSchedulesService.getById(id);
  }

  async getItemById(id) {
    return await this.itemsService.getById(id);
  }

  async getUofMById(id) {
    return await this.uofMsService.getById(id);
  }

  async getCurrencyById(id) {
    return await this.currenciesService.getById(id);
  }

  async getCustomerById(id) {
    return await this.customersService.getById(id);
  }

  async getLocationById(id) {
    return await this.locationsService.getById(id);
  }

  async getDiagnosticById(id) {
    return await this.pricingDiagnosticsService.getById(id);
  }

  async showInputXML() {
    const inputXML1: string = await Promise.resolve(this.buildInputXML());
    const inputXML: string = inputXML1.slice(1, -1);
    const modalRef = this.modalService.open(PricingScenarioXMLSingleComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.xmlString = inputXML;
  }

  public showPricingDiagnostic() {
    if (this.pricingDiagnosticRunID) {
      const modalRef = this.modalService.open(PricingDiagnosticSingleComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.idOfPricingDiagnostic = this.pricingDiagnosticRunID;
    }
    else {
      this.snack.openSnackBar(
        'The Current Pricing Scenario Has Not Yet Been Run.',
        '',
        false,
        'Info',
        'alert-info',
      );
    }
  }

  async executeGetPrice(inputXML) {
    try {
      const response: any = await this.toolsService.getPrice(inputXML);
      const status: any = response.status;
      if (status === 200) {
        const parser = new DOMParser();
        const outputDoc = parser.parseFromString(response.body, 'application/xml');

        this.pricingScenario.outputString = outputDoc.documentElement.outerHTML;

        // Clear out existing output values.
        // this.f.lastReturnedUnitPrice.patchValue('');
        // this.f.returnedPriceScheduleName.patchValue('');
        // this.f.pricingRunID.patchValue('');
        // this.f.lastExecutionDateTime.patchValue('');
        // this.priceAdjustments = [];

        // Get Price Schedule Name
        var priceScheduleIDNode = outputDoc.querySelector('PriceScheduleID');
        if (priceScheduleIDNode.hasChildNodes) {
          var childNode = priceScheduleIDNode.childNodes[0];
          if (childNode != undefined) {
            var priceScheduleERPID = childNode.nodeValue;
            const priceSchedule: PriceSchedule = await this.getPriceScheduleByERPId(priceScheduleERPID);
            this.f.lastReturnedPriceScheduleName.patchValue(priceSchedule.name);
            this.pricingScenario.lastReturnedPriceSchedule = priceSchedule;
            this.pricingScenario.lastReturnedPriceScheduleID = priceSchedule.id;
          }
        }

        // Get Modified Unit Price
        var modifiedUnitPriceNode = outputDoc.querySelector('ModifiedUnitPrice');
        if (modifiedUnitPriceNode.hasChildNodes) {
          var childNode = modifiedUnitPriceNode.childNodes[0];
          if (childNode != undefined) {
            var modifiedUnitPrice = childNode.nodeValue;
            this.f.lastReturnedUnitPrice.patchValue(modifiedUnitPrice);
            this.pricingScenario.lastReturnedUnitPrice = +modifiedUnitPrice;
          }
        }

        // Get the PricingDiagnostics Details
        var pricingDiagnosticRunIDNode = outputDoc.querySelector('PricingDiagnosticRunID');
        if (pricingDiagnosticRunIDNode.hasChildNodes) {
          var childNode = pricingDiagnosticRunIDNode.childNodes[0];
          if (childNode != undefined) {
            this.pricingDiagnosticRunID = +childNode.nodeValue;
            this.pricingScenario.lastExecutionDiagnosticID = this.pricingDiagnosticRunID;
            const pricingDiagnostic: PricingDiagnostic = await this.getDiagnosticById(this.pricingDiagnosticRunID);
            this.f.pricingRunID.patchValue(pricingDiagnostic.id);
            const runDateTime = this.datePipe.transform(pricingDiagnostic.runDateTime, 'yyyy-MM-ddThh:mm:ss');
            this.f.lastExecutionDateTime.patchValue(runDateTime);
            this.pricingScenario.lastExecutionDateTime = new Date(runDateTime);
          }
        }

        // Get the Price Was Found
        var priceWasFoundNode = outputDoc.querySelector('PriceWasFound');
        if (priceWasFoundNode.hasChildNodes) {
          var childNode = priceWasFoundNode.childNodes[0];
          if (childNode != undefined) {
            this.processStatus = parseInt(childNode.nodeValue);
            this.f.processStatus.patchValue(this.processStatus);
            //this.pricingScenario.processStatus = +this.processStatus;
            if (this.processStatus == 1) {
              var modifiedUnitPriceNode = outputDoc.querySelector('ModifiedUnitPrice');
              if (modifiedUnitPriceNode.hasChildNodes) {
                var childNode = modifiedUnitPriceNode.childNodes[0];
                if (childNode != undefined) {
                  var modifiedUnitPrice = childNode.nodeValue;
                  var expectedUnitPrice = this.pricingScenarioForm.controls['expectedUnitPriceAmount'].value;
                  if (expectedUnitPrice != undefined) {
                    if (parseFloat(modifiedUnitPrice) != parseFloat(expectedUnitPrice)) {
                      this.snack.openSnackBar('A price was found but it does not match the Expected Unit Price. View the Results tab for more information.', '', false, 'Error', 'alert-danger');
                    }
                    else {
                      this.snack.openSnackBar('Price found successfully. View the Results tab for more information.', '', false, 'Success', 'alert-success');
                    }
                  }
                  else {
                    this.snack.openSnackBar('Price found successfully. View the Results tab for more information.', '', false, 'Success', 'alert-success');
                  }
                }
              }
            }
            else {
              this.snack.openSnackBar('Unable to find price. View the Results tab for more information.', '', false, 'Error', 'alert-danger');
            }
          }
        }

        // Fill in Price Adjustments.
        this.priceAdjustments = [];
        var priceAdjustmentNodes = outputDoc.getElementsByTagName('PriceAdjustments');
        if (priceAdjustmentNodes && priceAdjustmentNodes.length > 0) {
          let foo = priceAdjustmentNodes[0];
          if (foo.hasChildNodes) {
            for (let i = 0; i < foo.childElementCount; i++) {
              const singleAdjustment = foo.childNodes[i];
              this.populateAdjustmentsGrid(singleAdjustment)
            }
            this.gridView = {
              data: this.priceAdjustments,
              total: this.priceAdjustments.length,
            };
          }
        }
        if (this.f.name != null) {
          this.submit(null, false);
        }
      }
    } catch (e) {
      this.snack.openSnackBar(e.message, '', true, 'Error', 'alert-danger');
    }
  }

  async executeGetPricePreview(inputXML) {
    try {
      const response: any = await this.toolsService.getPricePreview(inputXML);
      const status: any = response.status;
      if (status === 200) {
        return response.body;
      }
    } catch (e) {
      this.snack.openSnackBar(e.message, '', true, 'Error', 'alert-danger');
    }
  }

  async populateAdjustmentsGrid(singleAdjustment) {
    let adjustmentName = singleAdjustment.childNodes[0]?.innerHTML ?? '';        //PriceAdjustmentName
    let priceAdjustmentAmount = singleAdjustment.childNodes[1]?.innerHTML ?? ''; //PriceAdjustmnetAmount
    let debitGLAccountERPID = singleAdjustment.childNodes[2]?.innerHTML ?? '';   //DebitGLAccount
    let creditGLAccountERPID = singleAdjustment.childNodes[3]?.innerHTML ?? '';  //CreditGLAccount
    let userCanEdit = singleAdjustment.childNodes[4]?.innerHTML ?? '';           //UserCanEdit               
    let affectsMargins = singleAdjustment.childNodes[5]?.innerHTML ?? '';        //AdjustmentAffectsMargins
    let modifiesUnitPrice = singleAdjustment.childNodes[6]?.innerHTML ?? '';     //AdjustmentModifiesUnitPrice

    var currentAdjustment: PriceAdjustment = this.GetEmptyPriceAdjustment();
    currentAdjustment.PriceAdjustmentName = adjustmentName;
    currentAdjustment.PriceAdjustmentAmount = priceAdjustmentAmount;

    const debitGLAccount: GLAccount = (await Promise.resolve(this.getGLAccountByERPId(debitGLAccountERPID)));
    currentAdjustment.DebitGLAccountName = debitGLAccount?.name;
    currentAdjustment.DebitGLAccountNumber = debitGLAccount?.number;

    const creditGLAccount: GLAccount = (await Promise.resolve(this.getGLAccountByERPId(creditGLAccountERPID)));
    currentAdjustment.CreditGLAccountName = creditGLAccount?.name;
    currentAdjustment.CreditGLAccountNumber = creditGLAccount?.number;

    if (userCanEdit == 1) {
      currentAdjustment.UserCanEdit = "Yes";
    }
    else {
      currentAdjustment.UserCanEdit = "No";
    }

    if (affectsMargins == 1) {
      currentAdjustment.AdjustmentAffectsMargins = "Yes";
    }
    else {
      currentAdjustment.AdjustmentAffectsMargins = "No";
    }

    if (modifiesUnitPrice == 1) {
      currentAdjustment.AdjustmentModifiesUnitPrice = "Yes";
    }
    else {
      currentAdjustment.AdjustmentModifiesUnitPrice = "No";
    }
    this.priceAdjustments.push(currentAdjustment);
  }

  GetEmptyPriceAdjustment() {
    return {
      PriceAdjustmentName: '',
      PriceAdjustmentAmount: null,
      DebitGLAccountName: '',
      DebitGLAccountNumber: '',
      CreditGLAccountName: '',
      CreditGLAccountNumber: '',
      UserCanEdit: '',
      AdjustmentAffectsMargins: '',
      AdjustmentModifiesUnitPrice: ''
    };
  };

  buildPriceAdjustmentsGrid(priceAdjustmentXML) {
    let paXML = (new DOMParser()).parseFromString(priceAdjustmentXML, "application/xml")
    const priceAdjustmentNodes = paXML.getElementsByTagName("PriceAdjustment");
    for (let i = 0; i < priceAdjustmentNodes.length; i++) {
      const element = priceAdjustmentNodes[i].firstChild.nodeValue;
    }
    this.gridView = {
      data: this.priceAdjustments,
      total: this.priceAdjustments.length,
    };
  }

  async loadData() {
    this.loading = true;
    try {
      if (this.idOfPricingScenario) {
        this.pricingScenario = await Promise.resolve(this.getPricingScenarioById());

        if (this.pricingScenario.priceScheduleID) {
          const currentPriceSchedule: PriceSchedule = await Promise.resolve(this.getPriceScheduleByID(this.pricingScenario.priceScheduleID));
          this.priceSchedules.push(currentPriceSchedule);
          this.selectedPriceSchedule = currentPriceSchedule;
        }

        if (this.pricingScenario.expectedPriceScheduleID) {
          const currentPriceSchedule: PriceSchedule = await Promise.resolve(this.getPriceScheduleByID(this.pricingScenario.expectedPriceScheduleID));
          this.expectedPriceSchedules.push(currentPriceSchedule);
          this.selectedExpectedPriceSchedule = currentPriceSchedule;
        }

        if (this.pricingScenario.lastReturnedPriceScheduleID) {
          const currentPriceSchedule: PriceSchedule = await Promise.resolve(this.getPriceScheduleByID(this.pricingScenario.lastReturnedPriceScheduleID));
          this.pricingScenarioForm.get('lastReturnedPriceScheduleName').setValue(currentPriceSchedule.name);
        }

        if (this.pricingScenario.itemID) {
          const currentItem: Item = await Promise.resolve(this.getItemById(this.pricingScenario.itemID));
          this.items.push(currentItem);
          this.selectedItem = currentItem;
        }

        if (this.pricingScenario.uofMID) {
          const currentUofM: UofM = await Promise.resolve(this.getUofMById(this.pricingScenario.uofMID));
          this.uofMs.push(currentUofM);
          this.selectedUofM = currentUofM;
        }

        if (this.pricingScenario.currencyID) {
          const currentCurrency: Currency = await Promise.resolve(this.getCurrencyById(this.pricingScenario.currencyID));
          this.currencies.push(currentCurrency);
          this.selectedCurrency = currentCurrency;
        }

        if (this.pricingScenario.customerID) {
          const currentCustomer: Customer = await Promise.resolve(this.getCustomerById(this.pricingScenario.customerID));
          this.customers.push(currentCustomer);
          this.selectedCustomer = currentCustomer;
        }

        if (this.pricingScenario.locationID) {
          const currentLocation: Location = await Promise.resolve(this.getLocationById(this.pricingScenario.locationID));
          this.locations.push(currentLocation);
          this.selectedLocation = currentLocation;
        }

        if (this.pricingScenario.effectiveDate) {
          const currentDate = parseDate(this.pricingScenario.effectiveDate);
          this.pricingScenario.effectiveDate = currentDate;
          this.effectiveDate = currentDate;
          this.f.effectiveDate.patchValue(currentDate);
        }

        if (this.pricingScenario.useToday) {
          this.pricingScenarioForm.get('effectiveDate').disable()
          this.pricingScenario.effectiveDate = null;
          this.pricingScenarioForm.controls['effectiveDate'].patchValue(null);
        } else {
          this.pricingScenarioForm.get('effectiveDate').enable()
        }

        this.processStatus = +this.pricingScenario.processStatus;

        if (this.pricingScenario?.priceAdjustmentsXML) {
          const parser = new DOMParser();
          const outputDoc = parser.parseFromString('<PriceAdjustments>' + this.pricingScenario.priceAdjustmentsXML + "</PriceAdjustments>", 'application/xml');

          var priceAdjustmentNodes = (outputDoc.getElementsByTagName('PriceAdjustment'));
          if (priceAdjustmentNodes && priceAdjustmentNodes.length > 0) {
            for (let i = 0; i < priceAdjustmentNodes.length; i++) {
              const singleAdjustment = priceAdjustmentNodes[i];
              this.populateAdjustmentsGrid(singleAdjustment)
            }
            this.gridView = {
              data: this.priceAdjustments,
              total: this.priceAdjustments.length,
            };
          }

          this.populateAdjustmentsGrid(this.pricingScenario.priceAdjustmentsXML);
        }

        if (this.pricingScenario.lastExecutionDiagnosticID) {
          this.pricingDiagnosticRunID = +this.pricingScenario.lastExecutionDiagnosticID;
        }

        this.updateForm();
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  async priceScheduleSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getPriceSchedules({ SearchTerm });
  }

  async currencySearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getCurrencies({ SearchTerm });
  }

  async locationSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getLocations({ SearchTerm });
  }

  async customerSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getCustomers({ SearchTerm });
  }

  async itemSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getItems({ SearchTerm });
  }

  async uofMSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getUofMs({ SearchTerm });
  }

  async getPriceSchedules(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.priceSchedulesService.getList({ ...params, PageSize });
      this.priceSchedules = resp.body;
      this.paginationObject.priceSchedules = getPaginationHeader(resp.headers);
    } catch (e) {
      this.priceSchedules = [];
    }
    return this.priceSchedules;
  }

  async getCurrencies(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.currenciesService.getList({ ...params, PageSize });
      this.currencies = resp.body;
      this.paginationObject.currencies = getPaginationHeader(resp.headers);
    } catch (e) {
      this.currencies = [];
    }
    return this.currencies;
  }

  async getLocations(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.locationsService.getList({ ...params, PageSize });
      this.locations = resp.body;
      this.paginationObject.locations = getPaginationHeader(resp.headers);
    } catch (e) {
      this.locations = [];
    }
    return this.locations;
  }

  async getCustomers(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.customersService.getList({ ...params, PageSize });
      this.customers = resp.body;
      this.paginationObject.customers = getPaginationHeader(resp.headers);
    } catch (e) {
      this.customers = [];
    }
    return this.customers;
  }

  async getItems(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.itemsService.getList({ ...params, PageSize });
      this.items = resp.body;
      this.paginationObject.items = getPaginationHeader(resp.headers);
    } catch (e) {
      this.items = [];
    }
    return this.items;
  }

  async getUofMs(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.uofMsService.getList({ ...params, PageSize });
      this.uofMs = resp.body;
      this.paginationObject.uofMs = getPaginationHeader(resp.headers);
    } catch (e) {
      this.uofMs = [];
    }
    return this.uofMs;
  }

  onSelectBoxChanged(id: number, key, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    switch (key) {
      case 'priceScheduleID':
        // this.pricingScenario[key] = item;
        this.selectedPriceSchedule = item;
        break;
      case 'expectedPriceScheduleID':
        this.selectedExpectedPriceSchedule = item;
        break;
      case 'currencyID':
        if (item) {
          this.selectedCurrency = item;
        }
        break;
      case 'locationID':
        this.selectedLocation = item;
        break;
      case 'customerID':
        this.selectedCustomer = item;
        break;
      case 'itemID':
        this.selectedItem = item;
        this.selectedUofM = null;
        this.uofMs = [];
        this.pricingScenarioForm.get('uofMID').setValue(null);
        this.setDefaultUofM();
        break;
      case 'uofMID':
        this.selectedUofM = item;
        break;
      default:
        break;
    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.PriceSchedule:
        this.priceSchedules = await (
          await this.priceSchedulesService.getList(params)).body;
        break;
      case Filters.ExpectedPriceSchedule:
        this.expectedPriceSchedules = await (
          await this.priceSchedulesService.getList(params)).body;
        break;
      case Filters.Currency:
        this.currencies = await (await this.currenciesService.getList(params)).body;
        break;
      case Filters.Location:
        this.locations = await (await this.locationsService.getList(params)).body;
        break;
      case Filters.Customer:
        this.customers = await (await this.customersService.getList(params)).body;
        break;
      case Filters.Item:
        this.items = await (await this.itemsService.getList(params)).body;
        break;
      case Filters.UnitOfMeasure:
        this.uofMs = await (await this.uofMsService.getList({
          Filter_UofMScheduleID: this.selectedItem.uofMScheduleID, ...params
        })).body;
        break;
      default:
        break;
    }
  }

  async onLookup_priceSchedule() {
    const modalRef = this.modalService.open(PriceScheduleLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PriceSchedule = receivedEntry;
      this.priceSchedules = [];
      this.priceSchedules.push(recordSelected);
      this.pricingScenarioForm.patchValue({ ['priceScheduleID']: receivedEntry.id });
      this.selectedPriceSchedule = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_expectedPriceSchedule() {
    const modalRef = this.modalService.open(PriceScheduleLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PriceSchedule = receivedEntry;
      this.expectedPriceSchedules = [];
      this.expectedPriceSchedules.push(recordSelected);
      this.pricingScenarioForm.patchValue({ ['expectedPriceScheduleID']: receivedEntry.id });
      this.selectedExpectedPriceSchedule = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_currency() {
    const modalRef = this.modalService.open(CurrencyLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Currency = receivedEntry;
      this.currencies = [];
      this.currencies.push(recordSelected);
      this.pricingScenarioForm.patchValue({ ['currencyID']: receivedEntry.id });
      this.selectedCurrency = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_location() {
    const modalRef = this.modalService.open(LocationLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Location = receivedEntry;
      this.locations = [];
      this.locations.push(recordSelected);
      this.pricingScenarioForm.patchValue({ ['locationID']: receivedEntry.id });
      this.selectedLocation = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_customer() {
    const modalRef = this.modalService.open(CustomerLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Customer = receivedEntry;
      this.customers = [];
      this.customers.push(recordSelected);
      this.pricingScenarioForm.patchValue({ ['customerID']: receivedEntry.id });
      this.selectedCustomer = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_item() {
    const modalRef = this.modalService.open(ItemLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Item = receivedEntry;
      this.items = [];
      this.items.push(recordSelected);
      this.pricingScenarioForm.patchValue({ ['itemID']: receivedEntry.id });
      this.selectedItem = receivedEntry;
      // Clear the UofM since it is dependent upon item.
      this.selectedUofM = null;
      this.f.uofMID.patchValue('');
      this.setDefaultUofM();
      modalRef.close('test');
    });
  }

  async setDefaultUofM() {
    const Filter_UofMScheduleID = this.selectedItem.uofMScheduleID;
    const params = {};
    await this.getUofMs({ Filter_UofMScheduleID });
    if (!this.pricingScenarioForm.get('uofMID').value) {
      const baseUofM = this.uofMs.find(uofM => uofM.isBase);
      if (baseUofM) {
        this.pricingScenarioForm.patchValue({ 'uofMID': baseUofM.id });
        this.pricingScenario.uofM = baseUofM;
        this.selectedUofM = baseUofM;
      }
    }
  }


  async onLookup_unitOfMeasure() {
    const modalRef = this.modalService.open(UofMLookupComponent, {});
    modalRef.componentInstance.uofMScheduleID = this.selectedItem.uofMScheduleID;
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: UofM = receivedEntry;
      this.uofMs = [];
      this.uofMs.push(recordSelected);
      this.pricingScenarioForm.patchValue({ ['uofMID']: receivedEntry.id });
      this.selectedUofM = receivedEntry;
      modalRef.close('test');
    });
  }

  async getUseSingleCurrencySettings() {
    const Filter_Name = 'UseSingleCurrency';
    const resp = await this.settingsService.getList({ Filter_Name });
    const setting: Setting[] = resp.body;
    this.singleCurrency = setting[0].value == 'true';
    if (this.singleCurrency && this.idOfPricingScenario) {
      this.getDefaultCurrencySettings();
    }
  }

  async getDefaultCurrencySettings() {
    const Filter_Name = 'DefaultCurrency';
    const resp = await this.settingsService.getList({ Filter_Name });
    const setting: Setting[] = resp.body;
    if (setting && setting[0] && setting[0].value != '') {
      this.defaultCurrencyID = +setting[0].value;
      const resp = await this.currenciesService.getById(this.defaultCurrencyID);
      let defaultCurrency = resp;
      this.currencies = [];
      this.currencies.push(defaultCurrency);
      this.pricingScenario.currency = defaultCurrency;
      this.pricingScenario.currencyID = defaultCurrency.id;
      this.pricingScenarioForm.patchValue({ ['currencyLabelName']: defaultCurrency.name });
      this.selectedCurrency = defaultCurrency;
      this.updateForm();
    }
  }

  async setExpectedToReturnedPrice(modal) {
    let returnedUnitPrice = this.pricingScenario?.lastReturnedUnitPrice;
    let returnedPriceSched = this.pricingScenario?.lastReturnedPriceSchedule;
    if (this.pricingScenario) {
      this.pricingScenario.expectedUnitPriceAmount = returnedUnitPrice;
      this.pricingScenarioForm.patchValue({ ['expectedUnitPriceAmount']: returnedUnitPrice });

      if (this.pricingScenario.lastReturnedPriceScheduleID) {
        this.expectedPriceSchedules = [];
        const currentPriceSchedule: PriceSchedule = await Promise.resolve(this.getPriceScheduleByID(this.pricingScenario.lastReturnedPriceScheduleID));
        //console.log('currentPriceSchedule.id: ' + currentPriceSchedule.id);
        this.expectedPriceSchedules.push(currentPriceSchedule);
        this.selectedExpectedPriceSchedule = currentPriceSchedule;
        this.pricingScenarioForm.patchValue({ ['expectedPriceScheduleID']: currentPriceSchedule.id })
      }


    }
    modal.close();
  }

}
