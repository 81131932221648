import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemJobsService } from '../../../services/system-jobs/system-jobs.service';
import { SystemJob, emptySystemJob } from '../../../services/system-jobs/system-jobs.model';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup, emptyLookup } from 'src/app/services/lookups/lookups.model';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { Location } from '@angular/common';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { CopyService } from '../../../services/copy/copy.service';
import { SystemJobLog, emptySystemJobLog } from '../../../services/system-job-logs/system-job-logs.model';
import { SystemJobLogsService } from '../../../services/system-job-logs/system-job-logs.service';
import { SystemJobLogListComponent } from '../../system-job-logs/system-job-log-list/system-job-log-list.component';
import { SystemJobParameter, emptySystemJobParameter } from '../../../services/system-job-parameters/system-job-parameters.model';
import { SystemJobParametersService } from '../../../services/system-job-parameters/system-job-parameters.service';
import { SystemJobParameterListComponent } from '../../system-job-parameters/system-job-parameter-list/system-job-parameter-list.component';
import { UtilitiesService } from '../../../services/utilities/utilities.service';

enum Filters {
  JobType = 1,
  TimeUnit = 2,
  Minutely = 3,
  Hourly = 4,
}

@Component({
  selector: 'app-system-job-single',
  templateUrl: './system-job-single.component.html',
  styleUrls: ['./system-job-single.component.scss'],
})
export class SystemJobSingleComponent implements OnInit {
  @ViewChild('subgrid') SystemJobLogsSubGrid: SystemJobLogListComponent;
  @ViewChild('subgrid') SystemJobParametersSubGrid: SystemJobParameterListComponent;

  filters = Filters;
  idOfSystemJob: number | null = null;
  systemJob: SystemJob = emptySystemJob;
  loading: boolean = true;
  systemJobForm: FormGroup;
  subgridmode = 'subgrid';
  systemJobLogs: SystemJobLog[] = [];
  systemJobParameters: SystemJobParameter[] = [];
  paginationObject_SystemJobLogs: any = { systemJobLogs: mockedData };
  paginationObject_SystemJobParameters: any = { systemJobParameters: mockedData };

  @Output() onFormChanged = new EventEmitter<Boolean>();
  @Output() onFormSaved = new EventEmitter<Boolean>();
  @Output() onFormCancelled = new EventEmitter<Boolean>();

  jobTypeID: number;
  jobTypes: Lookup[] = [];
  timeUnitID: number;
  timeUnits: Lookup[] = [];
  minutelyID: number;
  minutelyOptions: Lookup[] = [];
  hourlyID: number;
  hourlyOptions: Lookup[] = [];
  startOn: Date = new Date();
  isRunning: boolean;
  isActive: boolean;
  submitted = false;
  modalReference: any;
  pagination_systemJobLogs: any = { systemJobLogs: mockedData };
  pagination_systemJobParameters: any = { systemJobParameters: mockedData };
  isFormChanged = false;
  goBackForce = false;
  nextURL = '';
  selectedJobType: Lookup = emptyLookup;
  selectedTimeUnit: Lookup = emptyLookup;
  selectedMinutelyOption: Lookup = emptyLookup;
  selectedHourlyOption: Lookup = emptyLookup;

  constructor(
    private route: ActivatedRoute,
    private systemJobsService: SystemJobsService,
    private modalService: NgbModal,
    public fb: FormBuilder,
    private snack: MySnackBarService,
    public activeModal: NgbActiveModal,
    private sideNavService: SideNavService,
    private location: Location,
    private router: Router,
    private copyService: CopyService,
    private lookupService: LookupsService,
    private utilitiesService: UtilitiesService
  ) {
    this.route.params.subscribe((params) => {
      if (params.id && params.id === 'new') {
        this.systemJob = emptySystemJob;
        this.idOfSystemJob = null;
        this.submitted = false;
        return;
      }
      else {
        this.idOfSystemJob = params.id;
      }
    });

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.SystemJobLogsSubGrid.gridClassName = "SystemJobLogSubgridClass"
          this.SystemJobParametersSubGrid.gridClassName = "SystemJobParameterSubgridClass"
        }
        else {
          this.SystemJobLogsSubGrid.gridClassName = "SystemJobLogFullSubgridClass"
          this.SystemJobParametersSubGrid.gridClassName = "SystemJobParameterFullSubgridClass"
        }
      },
    );
  }

  ngOnInit(): void {
    this.buildForm();
    this.loadData();
  }

  buildForm() {
    this.systemJobForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      isActive: new FormControl(true),
      jobTypeID: new FormControl('', Validators.required),
      isRunning: new FormControl(''),
      timeUnitID: new FormControl('', Validators.required),
      startOn: new FormControl('', Validators.required),
      minutelyID: new FormControl(''),
      hourlyID: new FormControl(''),
      nextRun: new FormControl({ value: '', disabled: true }),
    });
    this.setstartOn();
    this.getJobTypes();
    this.getTimeUnits();
    this.getMinutelyOptions();
    this.getHourlyOptions();
    this.systemJobForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }


  get f() {
    return this.systemJobForm.controls;
  }

  onClickClose(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMSystemJob", this.systemJob.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  updateForm() {
    this.systemJobForm.patchValue({ ...this.systemJob });
    this.isFormChanged = false;
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.systemJob.id);
    await this.systemJobsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/system-jobs']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.systemJob && this.systemJob.id) {
      return false;
    }
    else {
      return true;
    }
  }

  async onClickAddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/system-job/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/system-job/new']);
      });
    }
  }

  onClickBack(): void {
    this.location.back();
  }

  onClickSave() {
    this.submit(null);
  }

  onClickSaveClose() {
    this.submit("Close");
  }

  onClickSaveNew() {
    this.submit("New");
  }

  async onClickRunNow() {
    if (this.isRunning === true) {
      this.snack.openSnackBar(
        'System Job is already running.', '', true, 'Error', 'alert-danger');
    } else {
      if (this.systemJob.jobTypeID == 123) { //Data Sync, add to queue directly
        try {
          const params = [
            {
              Name: "@SystemJobID",
              Value: this.systemJob.id
            }
          ];
          const paramList = JSON.stringify(params);
          const response: any = await this.utilitiesService.callStoredProc('usp_RPMUtilityCreateSyncQueueFromSystemJob', paramList);
          const status: any = response.status;
          if (status === 200) {

          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
          return;
        }
      }
      else { //not Data Sync, update Next Run
        var now = new Date();
        now.setDate(now.getDate());
        const utcDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()));
        this.systemJob.nextRun = utcDate;
        //this.systemJob.nextRun = now;  //this is the same either way
      }
      this.submit("Close");
    }
  }

  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.systemJobForm.controls['name'].value;
    const Operand_Name = 'eq';

    const params = { Filter_Name, Operand_Name, PageSize };
    const existingSystemJobs: SystemJob[] = await (
      await this.systemJobsService.getList(params)
    ).body;
    if (existingSystemJobs.length > 0) {
      if (this.systemJob.id) {
        for (const key in existingSystemJobs) {
          const currentSystemJob: SystemJob = existingSystemJobs[key];
          if (currentSystemJob.id !== this.systemJob.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async submit(action) {
    this.systemJobForm.markAllAsTouched();
    this.submitted = true;
    if (this.systemJobForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.systemJob,
      ...this.systemJobForm.value,
    };
    if ((await this.checkForExistingName()) === true) {
      this.snack.openSnackBar(
        'System Job Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.systemJob.id) {
        try {
          const response: any = await this.systemJobsService.update(
            this.systemJob.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.systemJob = { ...this.systemJob, ...response.body }; // Assuming response.body contains the full updated SystemJob object
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/system-jobs']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/system-job/new'],);
              });
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      } else {
        try {
          const response: any = await this.systemJobsService.create(
            this.systemJobForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.systemJob = response.body;
            this.idOfSystemJob = this.systemJob.id;
            this.snack.openSnackBar(
              'Record saved successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/system-jobs']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/system-job/new'],);
              });
            }
            else {
              this.systemJob = response.body;
              this.router.navigate(['/price/system-job/', this.systemJob.id]);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
      this.onFormSaved.emit(false);
      this.onFormChanged.emit(false);
    }
  }

  async loadData() {
    this.loading = true;
    try {
      //
      if (this.idOfSystemJob) {
        // If this is an existing record we populate the values.
        this.systemJob = await Promise.resolve(await this.systemJobsService.getById(this.idOfSystemJob));
        console.log(this.systemJob.startOn);
        if (this.systemJob.startOn) {
          //this.systemJob.startOn = new Date(this.systemJob.startOn);
          let utcDate = new Date(this.systemJob.startOn); // This will be in UTC
          this.systemJob.startOn = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
        }
        console.log(this.systemJob.startOn);
        if (!this.isRunning) {
          this.isRunning = false;
        }
        if (!this.isActive) {
          this.isActive = false;
        }
        this.updateForm();
      }
      this.isFormChanged = false;
    }
    catch (e) {
      console.log(e);
    }
    finally {
      this.loading = false;
    }
    if (this.systemJob.jobTypeID != null) {
      this.jobTypeChanged(this.systemJob.jobTypeID);
    }
    if (this.systemJob.timeUnitID != null) {
      this.timeUnitChanged(this.systemJob.timeUnitID);
    }
    if (this.systemJob.minutelyID != null) {
      this.minutelyIDChanged(this.systemJob.minutelyID);
    }
    if (this.systemJob.hourlyID != null) {
      this.hourlyIDChanged(this.systemJob.hourlyID);
    }
    this.isFormChanged = false;
  }

  onSelectBoxChanged(id: number, key: keyof SystemJob, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.systemJob[key] = item;
    if (key == 'jobTypeID') {
      this.jobTypeChanged(id);
    }
    if (key == 'timeUnitID') {
      this.timeUnitChanged(id);
    }
    if (key == 'minutelyID') {
      this.minutelyIDChanged(id);
    }
    if (key == 'hourlyID') {
      this.hourlyIDChanged(id);
    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.JobType:
        {
          const Filter_OptionSet = 'JobType';
          const params = { SearchTerm, PageSize, Filter_OptionSet };
          this.jobTypes = await this.lookupService.getList(params);
        }
        break;
      case Filters.TimeUnit:
        {
          const Filter_OptionSet = 'TimeUnit';
          const params = { SearchTerm, PageSize, Filter_OptionSet };
          this.timeUnits = await this.lookupService.getList(params);
        }
        break;
      case Filters.Minutely:
        {
          const Filter_OptionSet = 'Minutely';
          const params = { SearchTerm, PageSize, Filter_OptionSet };
          this.minutelyOptions = await this.lookupService.getList(params);
        }
        break;
      case Filters.Hourly:
        {
          const Filter_OptionSet = 'Hourly';
          const params = { SearchTerm, PageSize, Filter_OptionSet };
          this.hourlyOptions = await this.lookupService.getList(params);
        }
        break;
      default:
        break;
    }
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async canDeactivate(nextURL: string) {
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

  setstartOn() {
    // Default in Start Date - this is required to make the date picker connection.
    this.systemJobForm.patchValue({ ['startOn']: this.systemJob.startOn });
    this.startOn = this.systemJob.startOn;
  }

  async getJobTypes() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'JobType';
    const params = { Filter_OptionSet, PageSize };
    this.jobTypes = await this.lookupService.getList(params);
    return this.jobTypes;
  }

  async getTimeUnits() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'TimeUnit';
    const params = { Filter_OptionSet, PageSize };
    this.timeUnits = await this.lookupService.getList(params);
    return this.timeUnits;
  }

  async getMinutelyOptions() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'Minutely';
    const params = { Filter_OptionSet, PageSize };
    this.minutelyOptions = await this.lookupService.getList(params);
    return this.minutelyOptions;
  }

  async getHourlyOptions() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'Hourly';
    const params = { Filter_OptionSet, PageSize };
    this.hourlyOptions = await this.lookupService.getList(params);
    return this.hourlyOptions;
  }

  startOnChanged() {
    //this.setNextTime();
  }

  jobTypeChanged(jobTypeID) {
    this.jobTypeID = jobTypeID;
  }

  timeUnitChanged(timeUnitID) {
    this.timeUnitID = timeUnitID;

    this.systemJobForm.get('startOn').disable();
    this.systemJobForm.get('startOn').clearValidators();

    this.systemJobForm.get('minutelyID').disable();
    this.systemJobForm.get('minutelyID').clearValidators();

    this.systemJobForm.get('hourlyID').disable();
    this.systemJobForm.get('hourlyID').clearValidators();

    switch (timeUnitID) {
      case 125: //On Demand
        this.systemJobForm.get('startOn').setValue(null);
        this.systemJobForm.get('startOn').updateValueAndValidity();
        this.startOn = null;
        this.systemJob.startOn = null;
        break;
      case 111: //Minutely
        this.systemJobForm.get('startOn').setValidators(Validators.required);
        this.systemJobForm.get('startOn').updateValueAndValidity();
        this.systemJobForm.get('startOn').enable();
        this.systemJobForm.get('minutelyID').setValidators(Validators.required);
        this.systemJobForm.get('minutelyID').updateValueAndValidity();
        this.systemJobForm.get('minutelyID').enable();
        break;
      case 112: //Hourly
        this.systemJobForm.get('startOn').setValidators(Validators.required);
        this.systemJobForm.get('startOn').updateValueAndValidity();
        this.systemJobForm.get('startOn').enable();
        this.systemJobForm.get('hourlyID').setValidators(Validators.required);
        this.systemJobForm.get('hourlyID').updateValueAndValidity();
        this.systemJobForm.get('hourlyID').enable();
        break;
      default:  //Daily, Weekly, Monthly
        this.systemJobForm.get('startOn').setValidators(Validators.required);
        this.systemJobForm.get('startOn').updateValueAndValidity();
        this.systemJobForm.get('startOn').enable();
        break;
    }
  }

  minutelyIDChanged(minutelyID) {
    this.minutelyID = minutelyID;
  }

  hourlyIDChanged(hourlyID) {
    this.hourlyID = hourlyID;
  }
}
