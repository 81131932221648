<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/System_Jobs.png" alt="System Jobs" style="padding-right: 5px;">
  System Jobs
</div>
<kendo-grid #grid="kendoGrid"
            [data]="gridView"
            [pageSize]="query.PageSize"
            [skip]="skip"
            [reorderable]="true"
            [loading]="loading"
            [pageable]="true"
            (pageChange)="pageChange($event)"
            [filter]="filter"
            [filterable]="filterable"
            (filterChange)="gridFilterChange($event)"
            [resizable]="true"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortChange($event)"
            [selectable]="{ mode: 'multiple' }"
            kendoGridSelectBy="id"
            [selectedKeys]="mySelection"
            (cellClick)="onCellClick($event)"
            (dblclick)="onDblClick()"
            [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate>
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">Refresh</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Add.png" [routerLink]="['/price/system-job/new']" title="Add New">Add New</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableEditBtn" imageUrl="../../../../assets/images/Edit.png" (click)="onDblClick()" title="Edit Selected">Edit</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="onClick_Delete()" title="Delete Selected">Delete</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Excel_Export.png" (click)="onClick_Export(grid, exportToExcel)" title="Export to Excel">Export</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Excel_Import.png" (click)="hiddenfileinput.click()" title="Import from Excel">Import</button>
    </kendo-buttongroup>
    <input type="file" style="display: none" (change)="onFileChange($event)" multiple="false" #hiddenfileinput />
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy"></button>
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
  </ng-template>

  <kendo-grid-column field="name" title="Name"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view'" [routerLink]="['/price/system-job/', dataItem.id]">{{ dataItem.name }}</a>
      <span role="button" *ngIf="mode == 'lookup'">{{ dataItem.name }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="jobType.name" title="Job Type"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>

  <kendo-grid-column field="description" title="Description"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>

  <kendo-grid-column field="isActive" filter="boolean" title="Is Active"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.isActive ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="isRunning" filter="boolean" title="Is Running"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.isRunning ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="timeUnit.name" title="Frequency"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>

  <kendo-grid-column field="startOn" title="Start On"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.startOn | date: 'MM/dd/yyyy HH:mm:ss zz' }} </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-date-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="nextRun" title="Next Run"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.nextRun | date: 'MM/dd/yyyy HH:mm:ss zz' }} </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-date-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel #exportToExcel fileName="SystemJobs.xlsx">
    <kendo-excelexport-column field="id" title="ID" *ngIf="exportAll && optRowID"></kendo-excelexport-column>
    <kendo-excelexport-column field="name" title="Name" [headerCellOptions]="{bold:'true'}"></kendo-excelexport-column>
    <kendo-excelexport-column field="description" title="Description"></kendo-excelexport-column>
    <kendo-excelexport-column field="isActive" title="IsActive"></kendo-excelexport-column>
    <kendo-excelexport-column field="jobType.name" title="JobTypeName"></kendo-excelexport-column>
    <kendo-excelexport-column field="timeUnit.name" title="TimeUnitName"></kendo-excelexport-column>
    <kendo-excelexport-column field="startOn" title="StartOn"></kendo-excelexport-column>
    <kendo-excelexport-column field="minutely.name" title="MinutelyName"></kendo-excelexport-column>
    <kendo-excelexport-column field="hourly.name" title="HourlyName"></kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>
