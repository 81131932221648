import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PriceBookService } from '../../../services/price-book/price-book.service';
import { PriceBook, emptyPriceBook } from '../../../services/price-book/price-book.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceSheetListComponent } from '../../price-sheets/price-sheet-list/price-sheet-list.component';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { CopyService } from '../../../services/copy/copy.service';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { ItemAttribute } from '../../../services/item-attributes/item-attributes.model';
import { ItemAttributesService } from '../../../services/item-attributes/item-attributes.service';
import { ItemAttributeLookupComponent } from '../../item-attributes/item-attribute-lookup/item-attribute-lookup.component';
import { CustomerAttributesService } from '../../../services/customer-attributes/customer-attributes.service';
import { CustomerAttribute } from 'src/app/services/customer-attributes/customer-attributes.model';
import { CustomerAttributeLookupComponent } from '../../customer-attributes/customer-attribute-lookup/customer-attribute-lookup.component';
import { DocumentAttribute } from '../../../services/document-attributes/document-attributes.model';
import { DocumentAttributesService } from '../../../services/document-attributes/document-attributes.service';
import { DocumentAttributeLookupComponent } from '../../document-attributes/document-attribute-lookup/document-attribute-lookup.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { PriceSheetsService } from '../../../services/price-sheets/price-sheets.service';
import { PriceSheetDetailsService } from '../../../services/price-sheet-details/price-sheet-details.service';

enum Filters {
  Name = 1,
  EntityType,
  CustomerAttribute,
  ItemAttribute,
  DocumentAttribute,
}
@Component({
  selector: 'app-price-book-single',
  templateUrl: './price-book-single.component.html',
  styleUrls: ['./price-book-single.component.scss'],
})
export class PriceBookSingleComponent implements OnInit {
  @ViewChild('subgrid') priceSheetsSubGrid: PriceSheetListComponent;

  filters = Filters;
  idOfPriceBook: number | null = null;
  priceBook: PriceBook = emptyPriceBook;
  loading: boolean = true;
  priceBookForm: FormGroup;
  submitted = false;
  @Output() onFormChanged = new EventEmitter<Boolean>();
  paginationObject: any = { priceSheets: mockedData };
  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';
  subgridmode = 'subgrid';
  deleteLock: boolean = false;

  entityTypes: Lookup[] = [];
  customerAttributes: CustomerAttribute[] = [];
  itemAttributes: ItemAttribute[] = [];
  documentAttributes: DocumentAttribute[] = [];
  paginationObject_EntityTypes: any = { entityTypes: mockedData };
  paginationObject_CustomerAttributes: any = { customerAttributes: mockedData };
  paginationObject_ItemAttributes: any = { itemAttributes: mockedData };
  paginationObject_DocumentAttributes: any = { documentAttributes: mockedData };
  attributeColumnName: string = 'Attribute Value'

  constructor(
    private route: ActivatedRoute,
    private priceBooksService: PriceBookService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snack: MySnackBarService,
    private router: Router,
    private sideNavService: SideNavService,
    private copyService: CopyService,
    private lookupsService: LookupsService,
    private customerAttributesService: CustomerAttributesService,
    private itemAttributesService: ItemAttributesService,
    private documentAttributesService: DocumentAttributesService,
    private priceSheetsService: PriceSheetsService,
    private priceSheetDetailsService: PriceSheetDetailsService
  ) {
    this.route.params.subscribe(params => {
      if (params.id && params.id === 'new') {
        this.priceBook = emptyPriceBook;
        this.idOfPriceBook = null;
        this.submitted = false;
        this.updateForm();
        this.buildForm();
        this.priceSheetsSubGrid.priceBookID = null;
        this.priceBook.entityTypeID = 86; //None
        this.priceSheetsSubGrid.loadSubGridData();
        return;
      }
      this.idOfPriceBook = params.id;
      this.loadData();
    });
    this.buildForm();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.priceSheetsSubGrid.gridClassName = "PriceSheetSubgridClass"
        }
        else {
          this.priceSheetsSubGrid.gridClassName = "PriceSheetFullSubgridClass"
        }
      },
    );
  }

  ngOnInit(): void {
  }

  buildForm() {
    this.priceBookForm = new FormGroup({
      name: new FormControl('', Validators.required),
      deleteLock: new FormControl(false),
      description: new FormControl(''),
      entityTypeID: new FormControl(86, Validators.required), //None
      customerAttributeID: new FormControl(),
      itemAttributeID: new FormControl(),
      documentAttributeID: new FormControl(),
    });
    this.getEntityTypes();
    this.priceBookForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.priceBookForm.controls;
  }

  updateForm() {
    this.priceBookForm.patchValue({ ...this.priceBook });
    this.isFormChanged = false;
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMPriceBook", this.priceBook.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async onClick_Delete() {
    const { sheetCount, totalLinesCount } = await this.sumPriceSheetCounts();
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      if (this.priceBookForm.get('deleteLock').value) {
        this.snack.openSnackBar(
          `This record cannot be deleted because it has a Delete Lock, a safety check to ensure you want to delete this Price Book, its ${sheetCount} Sheets, and its ${totalLinesCount} Detail Lines. If you still want to delete this Price Book, clear the Delete Lock and try again.`,
          '',
          true,
          'Error',
          'alert-danger'
        );
        return;
      }
      else {
        this.deleteRecord();
      }
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async deleteRecord() {
    const ids = [];
    ids.push(this.priceBook.id);
    await this.priceBooksService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.isFormChanged = false;
        this.router.navigate(['/price/price-books']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });
  }

  onClickAddNew() {
    this.router.navigate(['/price/price-book/new'], { replaceUrl: true });
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.priceBook && this.priceBook.id) {
      return false;
    }
    else {
      return true;
    }
  }

  onClickSave() {
    this.submit(null);
  }

  onClickSaveClose() {
    this.submit("Close");
  }

  onClickSaveNew() {
    this.submit("New");
  }

  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.priceBookForm.controls['name'].value;
    const Operand_Name = 'eq';

    const params = { Filter_Name, Operand_Name, PageSize };
    const existingPriceBooks: PriceBook[] = await (
      await this.priceBooksService.getList(params)
    ).body;
    if (existingPriceBooks.length > 0) {
      if (this.priceBook.id) {
        for (const key in existingPriceBooks) {
          const currentPriceBook: PriceBook = existingPriceBooks[key];
          if (currentPriceBook.id !== this.priceBook.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async submit(action) {
    this.priceBookForm.markAllAsTouched();
    this.submitted = true;
    if (this.priceBookForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = { ...this.priceBook, ...this.priceBookForm.value };

    // Check to see if Price Book Name already exists.
    if ((await this.checkForExistingName()) === true) {
      this.snack.openSnackBar(
        'Price Book Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.priceBook.id) {
        try {
          const response: any = await this.priceBooksService.update(
            this.priceBook.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            if (action == "Close") {
              this.router.navigate(['/price/price-books']);
            }
            else if (action == "New") {
              this.priceBook = emptyPriceBook;
              this.idOfPriceBook = null;
              this.submitted = false;
              this.updateForm();
              this.buildForm();
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      } else {
        try {
          const response: any = await this.priceBooksService.create(
            this.priceBookForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.priceBook = response.body;
            this.idOfPriceBook = this.priceBook.id;
            this.snack.openSnackBar(
              'Record saved successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            if (action == "Close") {
              this.router.navigate(['/price/price-books']);
            }
            else if (action == "New") {
              this.priceBook = emptyPriceBook;
              this.idOfPriceBook = null;
              this.submitted = false;
              this.updateForm();
              this.buildForm();
            }
            else {
              this.priceBook = response.body;
              this.router.navigate(['/price/price-book/', this.priceBook.id]);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
    }
    this.isFormChanged = false;
  }

  async getPriceBookById() {
    if (this.idOfPriceBook) {
      return await this.priceBooksService.getById(this.idOfPriceBook);
    } else {
      return emptyPriceBook;
    }
  }

  async loadData() {
    this.loading = true;
    try {
      [
        this.priceBook,
      ] = await Promise.all([
        this.getPriceBookById(),
      ]);

      if (this.priceBook.customerAttributeID) {
        const currentCustomerAttribute: CustomerAttribute = await Promise.resolve(await this.customerAttributesService.getById(this.priceBook.customerAttributeID));
        this.customerAttributes.push(currentCustomerAttribute);
        this.priceBook.customerAttribute = currentCustomerAttribute;
      }
      if (this.priceBook.itemAttributeID) {
        const currentItemAttribute: ItemAttribute = await Promise.resolve(this.itemAttributesService.getById(this.priceBook.itemAttributeID));
        this.itemAttributes.push(currentItemAttribute);
        this.priceBook.itemAttribute = currentItemAttribute;
      }
      if (this.priceBook.documentAttributeID) {
        const currentDocumentAttribute: DocumentAttribute = await Promise.resolve(this.documentAttributesService.getById(this.priceBook.documentAttributeID));
        this.documentAttributes.push(currentDocumentAttribute);
        this.priceBook.documentAttribute = currentDocumentAttribute;
      }
      if (this.priceBook.entityTypeID) {
        const currentLookup: Lookup = await Promise.resolve(this.lookupsService.getById(this.priceBook.entityTypeID));
        this.priceBook.entityType = currentLookup;
      }
      else {
        this.priceBook.entityTypeID = 86; //None
      }
      this.updateForm();
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async canDeactivate(nextURL: string) {
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }
  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.CustomerAttribute:
        this.customerAttributes = await (await this.customerAttributesService.getList(params)).body;
        break;
      case Filters.ItemAttribute:
        this.itemAttributes = await (await this.itemAttributesService.getList(params)).body;
        break;
      case Filters.DocumentAttribute:
        this.documentAttributes = await (await this.documentAttributesService.getList(params)).body;
        break;
      default:
        break;
    }
  }

  async onLookup_customerAttribute() {
    const modalRef = this.modalService.open(CustomerAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: CustomerAttribute = receivedEntry;
      this.customerAttributes = [];
      this.customerAttributes.push(recordSelected);
      this.priceBookForm.patchValue({ ['customerAttributeID']: receivedEntry.id });
      this.priceBook.customerAttribute = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_itemAttribute() {
    const modalRef = this.modalService.open(ItemAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: ItemAttribute = receivedEntry;
      this.itemAttributes = [];
      this.itemAttributes.push(recordSelected);
      this.priceBookForm.patchValue({ ['itemAttributeID']: receivedEntry.id });
      this.priceBook.itemAttribute = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_documentAttribute() {
    const modalRef = this.modalService.open(DocumentAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: DocumentAttribute = receivedEntry;
      this.documentAttributes = [];
      this.documentAttributes.push(recordSelected);
      this.priceBookForm.patchValue({ ['documentAttributeID']: receivedEntry.id });
      this.priceBook.documentAttribute = receivedEntry;
      modalRef.close('test');
    });
  }

  async getEntityTypes() {
    this.entityTypes = []
    const Filter_OptionSet = 'EntityType';
    const params = { Filter_OptionSet };
    const allEntityTypes: Lookup[] = await this.lookupsService.getList(params);
    if (allEntityTypes.length > 0) {
      for (const key in allEntityTypes) {
        const currentLookup: Lookup = allEntityTypes[key];
        if (currentLookup.name == "Customer Attribute" || currentLookup.name == "Document Attribute"
          || currentLookup.name == "Item Attribute" || currentLookup.name == "None") {
          this.entityTypes.push(currentLookup);
        }
      }
    }
  }

  entityTypeChange(entityTypeID) {
    // Clear the Customer Attribute
    this.priceBookForm.patchValue({ ['customerAttributeID']: null });
    this.f.customerAttributeID.clearValidators();
    this.f.customerAttributeID.updateValueAndValidity();
    // Clear the Item Attribute
    this.priceBookForm.patchValue({ ['itemAttributeID']: null });
    this.f.itemAttributeID.clearValidators();
    this.f.itemAttributeID.updateValueAndValidity();
    // Clear the Document Attribute
    this.priceBookForm.patchValue({ ['documentAttributeID']: null });
    this.f.documentAttributeID.clearValidators();
    this.f.documentAttributeID.updateValueAndValidity();
    if (entityTypeID == 75) {
      // Require the Customer Attribute
      this.f.customerAttributeID.setValidators(Validators.required);
      this.f.customerAttributeID.updateValueAndValidity();
    }
    else if (entityTypeID == 76) {
      // Require the Item Attribute
      this.f.itemAttributeID.setValidators(Validators.required);
      this.f.itemAttributeID.updateValueAndValidity();
    }
    else if (entityTypeID == 70) {
      // Require the Document Attribute
      this.f.documentAttributeID.setValidators(Validators.required);
      this.f.documentAttributeID.updateValueAndValidity();
    }
  }

  onSelectBoxChanged(id: number, key: keyof PriceBook, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.priceBook[key] = item;
    if (key == "entityTypeID") {
      this.entityTypeChange(id);
    }
  }

  async sumPriceSheetCounts() {
    let totalLinesCount = 0;
    let sheetCount = 0;
    try {
      const sheetResponse = await this.priceSheetsService.getList({ Filter_PriceBookID: this.idOfPriceBook });
      const sheetList = sheetResponse.body;
      sheetCount = sheetList.length;
      for (const sheet of sheetList) {
        const lineResponse = await this.priceSheetDetailsService.getList({ Filter_PriceSheetID: sheet.id, PageSize: 1, PageNumber: 1 });
        const pagination = getPaginationHeader(lineResponse.headers);
        totalLinesCount += pagination.TotalCount;
      }
    } catch (error) {
      this.loading = false;
    }
    return { sheetCount, totalLinesCount };
  }

}
