export interface DataMap {
  id: number;
  name: string;
  dataMapTypeID: number;
  dataMapType: {
    name: string;
    id: number;
  }
  description: string;
  useCustomer: boolean;
  useItem: boolean;
  useUofM: boolean;
  useLocation: boolean;
  useCurrency: boolean;
  useDates: boolean;
  useAttribute1: boolean;
  useAttribute2: boolean;
  useAttribute3: boolean;
  entityType1ID: number;
  entityType1: {
    name: string;
    id: number;
  };
  customerAttribute1ID: number;
  customerAttribute1: {
    name: string;
    id: number;
  };
  itemAttribute1ID: number;
  itemAttribute1: {
    name: string;
    id: number;
  };
  documentAttribute1ID: number;
  documentAttribute1: {
    name: string;
    id: number;
  };
  entityType2ID: number;
  entityType2: {
    name: string;
    id: number;
  };
  customerAttribute2ID: number;
  customerAttribute2: {
    name: string;
    id: number;
  };
  itemAttribute2ID: number;
  itemAttribute2: {
    name: string;
    id: number;
  };
  documentAttribute2ID: number;
  documentAttribute2: {
    name: string;
    id: number;
  };
  entityType3ID: number;
  entityType3: {
    name: string;
    id: number;
  };
  customerAttribute3ID: number;
  customerAttribute3: {
    name: string;
    id: number;
  };
  itemAttribute3ID: number;
  itemAttribute3: {
    name: string;
    id: number;
  };
  documentAttribute3ID: number;
  documentAttribute3: {
    name: string;
    id: number;
  };
  defaultMapAmount: number;
  defaultNumberSourceID: number;
  defaultNumberSource: {
    name: string;
    id: number;
  };
  defaultPriceCalculationID: number;
  defaultPriceCalculation: {
    name: string;
    id: number;
  };
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyDataMap: DataMap = {
  id: null,
  name: '',
  dataMapTypeID: null,
  dataMapType: {
    name: '',
    id: null,
  },
  description: null,
  useCustomer: null,
  useItem: null,
  useUofM:null,
  useLocation: null,
  useCurrency: null,
  useDates: null,
  useAttribute1: null,
  useAttribute2: null,
  useAttribute3: null,
  entityType1ID: null,
  entityType1: {
    name: '',
    id: null
  },
  customerAttribute1ID: null,
  customerAttribute1: {
    name: '',
    id: null
  },
  itemAttribute1ID: null,
  itemAttribute1: {
    name: '',
    id: null
  },
  documentAttribute1ID: null,
  documentAttribute1: {
    name: '',
    id: null
  },
  entityType2ID: null,
  entityType2: {
    name: '',
    id: null
  },
  customerAttribute2ID: null,
  customerAttribute2: {
    name: '',
    id: null
  },
  itemAttribute2ID: null,
  itemAttribute2: {
    name: '',
    id: null
  },
  documentAttribute2ID: null,
  documentAttribute2: {
    name: '',
    id: null
  },
  entityType3ID: null,
  entityType3: {
    name: '',
    id: null
  },
  customerAttribute3ID: null,
  customerAttribute3: {
    name: '',
    id: null
  },
  itemAttribute3ID: null,
  itemAttribute3: {
    name: '',
    id: null
  },
  documentAttribute3ID: null,
  documentAttribute3: {
    name: '',
    id: null
  },
  defaultMapAmount: null,
  defaultNumberSourceID: null,
  defaultNumberSource: {
    name: '',
    id: null
  },
  defaultPriceCalculationID: null,
  defaultPriceCalculation: {
    name: '',
    id: null,
  },
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};


