import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, Renderer2, NgZone, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceCalculationLookupComponent } from '../../../price/price-calculations/price-calculation-lookup/price-calculation-lookup.component';
import { PriceScheduleCalculationsService } from '../../../services/price-schedule-calc/price-schedule-calc.service';
import { PriceCalculation } from '../../../services/price-calculations/price-calculations.model';
import { PriceScheduleCalculation, emptyPriceScheduleCalculation } from '../../../services/price-schedule-calc/price-schedule-calc.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GridDataResult, PageChangeEvent, GridComponent, ExcelComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service';
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Subscription } from 'rxjs';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';

const tableRow = node => node.tagName.toLowerCase() === 'tr';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};

@Component({
  selector: 'app-price-schedule-calculation-list',
  templateUrl: './price-schedule-calculation-list.component.html',
  styleUrls: ['./price-schedule-calculation-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
  .k-grid tr.dragging {
      background-color: #f45c42;
  };
`]
})
export class PriceScheduleCalculationListComponent
  implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;
  //new
  private currentSubscription: Subscription;
  constructor(
    //new
    private renderer: Renderer2, private zone: NgZone,
    private datePipe: DatePipe,
    private priceScheduleCalculationService: PriceScheduleCalculationsService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private lookupService: LookupsService,
    private notificationHelper: NotificationHelper
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input() priceScheduleID: number;
  @Input() loading: boolean = true;
  @Input() priceCalculations: PriceCalculation[] = [];  //a list of Price Calculations filtered to this Price Schedule
  @Input() query = {
    PageSize: 25,
    SortTerm: 'rank',
    PageNumber: 1,
    SearchTerm: '',
  };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  priceScheduleCalculations: PriceScheduleCalculation[] = [];

  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public clickedRowItem;
  public gridClassName: string = "PriceCalculationSubgridClass";
  public filterable: boolean;
  public priceScheduleCalculationsPriceModeOptions: Lookup[];
  public priceScheduleCalculationsEntity: Lookup[] = [];

  public sort: SortDescriptor[] = [
    {
      field: 'rank',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Rank',
      field: 'rank',
      width: 100,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Name',
      field: 'name',
      width: 250,
      orderIndex: 1,
      hidden: false,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.priceScheduleID && !changes.priceScheduleID.firstChange) {
      // We now have an Parent ID so we can change the tooltip.
      document.getElementById('btnAdd').setAttribute('title', 'Add New');
    }
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.priceScheduleCalculationService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = 0;  //no siblings
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      console.log(options.optTechnicalStyle, options.optDescColumns, options.optRowID, options.optSiblingRecords);
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) {
        excelComponent.fetchData = this.allData;
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }

  async onClick_ExportOLD(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();

    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.confirmReImport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = true;
      excelComponent.fetchData = this.allData;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.confirmExport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = false;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });

  }

  onResetGridClick() {
    StorageService.removeColumnSettings('PriceScheduleCalculationList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('PriceScheduleCalculationList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('PriceScheduleCalculationList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('PriceScheduleCalculationList_Config', this.columnsConfig);
  }

  ngAfterViewInit(): void {
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;
    this.loadListData();
    this.getPriceScheduleCalculationPriceModeOptions();
    this.getPriceScheduleCalculationEntity();
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  async loadListData() {
    this.priceScheduleCalculations = [];
    this.gridView = {
      data: this.priceScheduleCalculations,
      total: 0,
    };

    this.loading = true;
    this.query.PageSize = StorageService.PageSize();
    try {
      if (this.priceScheduleID) {
        document.getElementById('btnAdd').setAttribute('title', 'Add New');
        const PageSize = StorageService.PageSize() ?? 50;
        const response: any = await this.priceScheduleCalculationService.getList({
          Filter_PriceScheduleID: this.priceScheduleID,
          ...this.query, PageSize
        });
        this.priceScheduleCalculations = response.body;
        this.priceCalculations = this.priceScheduleCalculations.map((psc) => {
          return <PriceCalculation>{
            id: psc.priceCalculation.id,
            name: psc.priceCalculation.name,
            rank: psc.rank,
            priceScheduleCalculationID: psc.id,
            priceModeOptionID: psc.priceCalculation.priceModeOptionID,
            priceModeOption: psc.priceCalculation.priceModeOption,
            priceAmount: psc.priceCalculation.priceAmount,
            pricePercent: psc.priceCalculation.pricePercent,
            convertToUofM: psc.priceCalculation.convertToUofM,
            perUofMName: psc.priceCalculation.perUofMName,
            roundingRuleID: psc.priceCalculation.roundingRuleID,
            roundingRule: psc.priceCalculation.roundingRule,
            modifyUnitPrice: psc.priceCalculation.modifyUnitPrice,
            trackAdjustment: psc.priceCalculation.trackAdjustment,
            debitGLAccountID: psc.priceCalculation.debitGLAccountID,
            debitGLAccount: psc.priceCalculation.debitGLAccount,
            creditGLAccountID: psc.priceCalculation.creditGLAccountID,
            creditGLAccount: psc.priceCalculation.creditGLAccount,
            userCanEdit: psc.priceCalculation.userCanEdit,
            affectsMarginCalc: psc.priceCalculation.affectsMarginCalc,
            pricingFilterID: psc.priceCalculation.pricingFilterID,
            pricingFilter: psc.priceCalculation.pricingFilter,
            trackAdjustmentExtended: psc.priceCalculation.trackAdjustmentExtended,
            createdOn: psc.priceCalculation.createdOn,
            createdBy: psc.priceCalculation.createdBy,
            modifiedOn: psc.priceCalculation.modifiedOn,
            modifiedBy: psc.priceCalculation.modifiedBy, //sas added
            useValueFromAttribute: psc.priceCalculation.useValueFromAttribute,
            entity: psc.priceCalculation.entity,
            customerAttributeID: psc.priceCalculation.customerAttributeID,
            customerAttribute: psc.priceCalculation.customerAttribute,
            documentAttributeID: psc.priceCalculation.documentAttributeID,
            documentAttribute: psc.priceCalculation.documentAttribute,
            itemAttributeID: psc.priceCalculation.itemAttributeID,
            itemAttribute: psc.priceCalculation.itemAttribute
          };
        });
        this.pagination = getPaginationHeader(response.headers);
        this.gridView = {
          data: this.priceCalculations,
          total: this.pagination.TotalCount,
        };
      }
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  async getPriceScheduleCalculationPriceModeOptions() {
    const Filter_OptionSet = 'PriceMode';
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { Filter_OptionSet, PageSize };
    this.priceScheduleCalculationsPriceModeOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "PriceMode";
    defaultLookup.name = "(All)";
    this.priceScheduleCalculationsPriceModeOptions.reverse();
    this.priceScheduleCalculationsPriceModeOptions.push(defaultLookup);
    this.priceScheduleCalculationsPriceModeOptions.reverse();
    return this.priceScheduleCalculationsPriceModeOptions;
  }

  async getPriceScheduleCalculationEntity() {
    this.priceScheduleCalculationsEntity.length = 0;
    this.priceScheduleCalculationsEntity.push({ id: 999, name: "(All)", optionSet: "EntityType", sortOrder: 1 });
    this.priceScheduleCalculationsEntity.push({ id: 68, name: "Customer", optionSet: "EntityType", sortOrder: 2 });
    this.priceScheduleCalculationsEntity.push({ id: 70, name: "Document", optionSet: "EntityType", sortOrder: 3 });
    this.priceScheduleCalculationsEntity.push({ id: 71, name: "Item", optionSet: "EntityType", sortOrder: 4 });
    return this.priceScheduleCalculationsEntity;
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  async onFileChange(evt: any) {
    await this.importsService.onFileChange(evt, 'RPMPriceScheduleCalculation');
    this.fileUploader.nativeElement.value = null;
    this.loadListData();
  }

  onFileChangeOLD(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      //ws.forEach(function(y) {
      //var worksheet = wb.Sheets[y];
      var headers = {};
      var data = [];
      for (const z in ws) {
        if (z[0] === '!') continue;
        //parse out the column, row, and value
        var col = z.substring(0, 1);
        var row = parseInt(z.substring(1));
        var value = ws[z].v;

        //store header names
        if (row == 1) {
          headers[col] = String(value);
          continue;
        }

        if (!data[row]) data[row] = {};
        data[row][headers[col]] = String(value).trim();
      }
      //drop those first two rows which are empty
      data.shift();
      data.shift();
      console.log(data);
      //});
      var myJSONString = JSON.stringify(data);
      var myEscapedJSONString = myJSONString
        .replace(/[\\]/g, '\\\\')
        .replace(/[\']/g, "\\'")
        .replace(/[\"]/g, '\\"')
        .replace(/[\/]/g, '\\/')
        .replace(/[\b]/g, '\\b')
        .replace(/[\f]/g, '\\f')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t');

      const inputXML: string = "'" + myEscapedJSONString + "'";
      this.performImport(inputXML);
    };

    reader.readAsBinaryString(target.files[0]);

    this.fileUploader.nativeElement.value = null;
  }

  async performImport(inputXML) {
    try {
      const response: any = await this.importsService.performImport(
        inputXML,
        'RPMPriceScheduleCalculation',
      );
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Import successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
        this.loadListData();
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick(e) {
    let test = this.mySelection;
    if (this.clickedRowItem && e?.target?.localName != null && e.target.localName != "button") {
      this.router.navigate([`/price/price-calculation/${this.clickedRowItem.id}`]);
    }
  }

  public onRankIncrease() {
    if (this.clickedRowItem) {
      // @ts-ignore
      let rows: any[] = this.myGrid.data.data;
      const foundIndex = rows.findIndex((row) => row.id === this.clickedRowItem.id);
      const reorderedColumn = rows.splice(foundIndex, 1);
      rows.splice(foundIndex - 1, 0, ...reorderedColumn);
    }
  }

  public onRankDecrease() {
    if (this.clickedRowItem) {
      // @ts-ignore
      let rows: any[] = this.myGrid.data.data;
      const foundIndex = rows.findIndex((row) => row.id === this.clickedRowItem.id);
      const reorderedColumn = rows.splice(foundIndex, 1);
      rows.splice(foundIndex + 1, 0, ...reorderedColumn);
    }
  }

  async saveAllPriceSchedules() {  //need to remove after editing lines
    // @ts-ignore
    let allRows: any[] = this.myGrid.data.data;
    let rank: number = 1;
    for (let index = 0; index < allRows.length; index++) {
      const row = allRows[index];
      let currentPriceScheduldCalc: PriceScheduleCalculation = await Promise.resolve(await this.priceScheduleCalculationService.getById(row.priceScheduleCalculationID));

      currentPriceScheduldCalc.rank = rank;
      row.rank = rank;
      this.save(currentPriceScheduldCalc);
      rank++;
    }
  }

  async save(priceScheduleCalculation) {
    try {
      const response: any = await this.priceScheduleCalculationService.update(
        priceScheduleCalculation.id,
        priceScheduleCalculation,
      );
      const status: any = response.status;
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }

  }

  onRefresh() {
    this.loadListData();
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
        if (myFilter.field == 'priceAmount') {
          const Filter_PriceAmount =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_PriceAmount = myFilter.operator;
          const params = {
            ...this.query,
            Filter_PriceAmount,
            Operand_PriceAmount,
          };
          this.query = params;
        }
        if (myFilter.field == 'pricePercent') {
          const Filter_PricePercent =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_PricePercent = myFilter.operator;
          const params = {
            ...this.query,
            Filter_PricePercent,
            Operand_PricePercent,
          };
          this.query = params;
        }
        if (myFilter.field == 'convertToUofM') {
          const Filter_ConvertToUofM = myFilter.value;
          const Operand_ConvertToUofM = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ConvertToUofM,
            Operand_ConvertToUofM,
          };
          this.query = params;
        }
        if (myFilter.field == 'perUofMName') {
          const Filter_PerUofMName = myFilter.value == null ? '' : myFilter.value;
          const Operand_PerUofMName = myFilter.operator;
          const params = { ...this.query, Filter_PerUofMName, Operand_PerUofMName };
          this.query = params;
        }
        if (myFilter.field == 'modifyUnitPrice') {
          const Filter_ModifyUnitPrice = myFilter.value;
          const Operand_ModifyUnitPrice = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ModifyUnitPrice,
            Operand_ModifyUnitPrice,
          };
          this.query = params;
        }
        if (myFilter.field == 'trackAdjustment') {
          const Filter_TrackAdjustment = myFilter.value;
          const Operand_TrackAdjustment = myFilter.operator;
          const params = {
            ...this.query,
            Filter_TrackAdjustment,
            Operand_TrackAdjustment,
          };
          this.query = params;
        }
        if (myFilter.field == 'trackAdjustmentExtended') {
          const Filter_TrackAdjustmentExtended = myFilter.value;
          const Operand_TrackAdjustmentExtended = myFilter.operator;
          const params = {
            ...this.query,
            Filter_TrackAdjustmentExtended,
            Operand_TrackAdjustmentExtended,
          };
          this.query = params;
        }
        if (myFilter.field == 'userCanEdit') {
          const Filter_UserCanEdit = myFilter.value;
          const Operand_UserCanEdit = myFilter.operator;
          const params = {
            ...this.query,
            Filter_UserCanEdit,
            Operand_UserCanEdit,
          };
          this.query = params;
        }
        if (myFilter.field == 'affectsMarginCalc') {
          const Filter_AffectsMarginCalc = myFilter.value;
          const Operand_AffectsMarginCalc = myFilter.operator;
          const params = {
            ...this.query,
            Filter_AffectsMarginCalc,
            Operand_AffectsMarginCalc,
          };
          this.query = params;
        }
        if (myFilter.field == 'roundingRule.name') {
          const Filter_RoundingRule =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_RoundingRule = myFilter.operator;
          const params = {
            ...this.query,
            Filter_RoundingRule,
            Operand_RoundingRule,
          };
          this.query = params;
        }
        if (myFilter.field == 'debitGLAccount.name') {
          const Filter_DebitGLAccount =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_DebitGLAccount = myFilter.operator;
          const params = {
            ...this.query,
            Filter_DebitGLAccount,
            Operand_DebitGLAccount,
          };
          this.query = params;
        }
        if (myFilter.field == 'creditGLAccount.name') {
          const Filter_CreditGLAccount =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_CreditGLAccount = myFilter.operator;
          const params = {
            ...this.query,
            Filter_CreditGLAccount,
            Operand_CreditGLAccount,
          };
          this.query = params;
        }
        if (myFilter.field == 'priceModeOption.name') {
          if (myFilter.value != '(All)') {
            const Filter_PriceModeOption = myFilter.value;
            const Operand_PriceModeOption = myFilter.operator;
            const params = {
              ...this.query,
              Filter_PriceModeOption,
              Operand_PriceModeOption,
            };
            this.query = params;
          }
        }
        if (myFilter.field == 'pricingFilter.name') {
          const Filter_PricingFilter =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_PricingFilter = myFilter.operator;
          const params = {
            ...this.query,
            Filter_PricingFilter,
            Operand_PricingFilter,
          };
          this.query = params;
        }
        if (myFilter.field == 'useValueFromAttribute') {
          const Filter_UseValueFromAttribute = myFilter.value;
          const Operand_UseValueFromAttribute = myFilter.operator;
          const params = {
            ...this.query,
            Filter_UseValueFromAttribute,
            Operand_UseValueFromAttribute,
          };
          this.query = params;
        }
        if (myFilter.field == 'entity.name') {
          if (myFilter.value != '(All)') {
            const Filter_Entity = myFilter.value;
            const Operand_Entity = myFilter.operator;
            const params = {
              ...this.query,
              Filter_Entity,
              Operand_Entity,
            };
            this.query = params;
          }
        }
        if (myFilter.field == 'customerAttribute.name') {
          const Filter_CustomerAttribute =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_CustomerAttribute = myFilter.operator;
          const params = {
            ...this.query,
            Filter_CustomerAttribute,
            Operand_CustomerAttribute,
          };
          this.query = params;
        }
        if (myFilter.field == 'documentAttribute.name') {
          const Filter_DocumentAttribute =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_DocumentAttribute = myFilter.operator;
          const params = {
            ...this.query,
            Filter_DocumentAttribute,
            Operand_DocumentAttribute,
          };
          this.query = params;
        }
        if (myFilter.field == 'itemAttribute.name') {
          const Filter_ItemAttribute =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_ItemAttribute = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ItemAttribute,
            Operand_ItemAttribute,
          };
          this.query = params;
        }

      });
    }
    this.onFilterChange();
  }

  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.priceScheduleCalculationService.getExportList({
      Filter_PriceScheduleID: this.priceScheduleID,
      ...this.query,
    });
  };

  edit() {
    this.router.navigate([`/price/price-calculation/${this.clickedRowItem.id}`]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async onLookup_PriceCalculations() {
    const modalRef = this.modalService.open(PriceCalculationLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'PriceCalculationModalClass',
      centered: true,
      backdrop: 'static',
    });
    //modalRef.componentInstance.loadListData();
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "PriceCalculationDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe(async (receivedEntry) => {
      const newPSC: PriceScheduleCalculation = emptyPriceScheduleCalculation;
      newPSC.priceScheduleID = this.priceScheduleID;
      newPSC.priceCalculationID = receivedEntry.id;
      newPSC.rank = this.priceScheduleCalculations.length + 1;
      // Remove the ID since this is a create.
      delete newPSC.id;
      try {
        // Create Price Schedule Calculation record.       
        const response: any = await this.priceScheduleCalculationService.create(
          newPSC,
        );
        const status: any = response.status;
        if (status === 201) {
          // Refresh Grid
          this.loadListData();
        }
      } catch (e) {
        const err = e.error();
      }
      modalRef.close('test');
    });
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.priceScheduleCalculationService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });

    modal.close();
    this.loadListData();
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }


}


