import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PricingConditionsService } from '../../../services/pricing-conditions/pricing-conditions.service';
import { PricingCondition, emptyPricingCondition } from '../../../services/pricing-conditions/pricing-conditions.model';
import { UofMsService } from '../../../services/uofms/uofms.service';
import { UofM } from '../../../services/uofms/uofms.model';
import { RoundingRulesService } from '../../../services/rounding-rules/rounding-rules.service';
import { RoundingRule } from '../../../services/rounding-rules/rounding-rules.model';
import { PricingFiltersService } from '../../../services/pricing-filters/pricing-filters.service';
import { PricingFilter } from '../../../services/pricing-filters/pricing-filters.model';
import { GLAccountsService } from '../../../services/glaccounts/glaccounts.service';
import { GLAccount } from '../../../services/glaccounts/glaccounts.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { debounce } from 'lodash';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { prepareData } from './utils/prepareDataForFrom';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { PricingFilterLookupComponent } from '../../pricing-filters/pricing-filter-lookup/pricing-filter-lookup.component';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { StorageService } from 'src/app/utils/StorageHelper';
import { CustomerAttribute, emptyCustomerAttribute } from '../../../services/customer-attributes/customer-attributes.model';
import { emptyItemAttribute, ItemAttribute } from '../../../services/item-attributes/item-attributes.model';
import { PriceBookService } from '../../../services/price-book/price-book.service';
import { DocumentAttribute, emptyDocumentAttribute } from '../../../services/document-attributes/document-attributes.model';
import { PriceBook, emptyPriceBook } from '../../../services/price-book/price-book.model';
import { CopyService } from '../../../services/copy/copy.service';

enum Filters {
  PriceModeOption = 1,
  PerUofM = 2,
  DebitGLAccount = 3,
  CreditGLAccount = 4,
  RoundingRule = 5,
  ConditionalPricingFilter = 6,
  PricingFilter = 7,
  CustomerAttribute = 8,
  ItemAttribute = 9,
  DocumentAttribute = 10,
  PriceBook = 11,
}
@Component({
  selector: 'app-pricing-condition-single',
  templateUrl: './pricing-condition-single.component.html',
  styleUrls: ['./pricing-condition-single.component.scss'],
})
export class PricingConditionSingleComponent implements OnInit {
  filters = Filters;
  idOfPricingCondition: number | null = null;
  pricingCondition: PricingCondition = emptyPricingCondition();
  loading: boolean = true;
  pricingConditionForm: FormGroup;
  perUofMs: UofM[] = [];
  roundingRules: RoundingRule[] = [];
  conditionalPricingFilters: PricingFilter[] = [];
  debitGLAccounts: GLAccount[] = [];
  creditGLAccounts: GLAccount[] = [];
  priceModeOptions: Lookup[] = [];
  historyDateOptions: Lookup[] = [];
  conditionOptions: Lookup[] = [];
  conditionOperands: Lookup[] = [];
  conditionTypeOptions: Lookup[] = [];

  paginationObject: any = {
    uofMs: mockedData,
    glAccounts: mockedData,
    roundingrules: mockedData,
    priceFilters: mockedData,
    conditionalPricingFilters: mockedData
  };
  submitted = false;
  @Output()
  onFormChanged = new EventEmitter<boolean>();
  modalReference: any;
  showPricePercent: boolean = false;
  showPriceAmount: boolean = false;
  showConvertToUofMName: boolean = false;
  showConditionAmount: boolean = false;
  showConditionQuantity: boolean = false;
  showHistoryDateOption: boolean = false;
  showHistoryDateValue: boolean = false;
  showHistoryDate: boolean = false;
  trackAdjustmentValue: boolean = false;
  convertToUofMValue: boolean = false;
  abortIfValueNotFound: boolean = false;
  isFormChanged = false;
  goBackForce = false;
  nextURL = '';
  customerAttributes: CustomerAttribute[] = [];
  selectedCustomerAttribute: CustomerAttribute = emptyCustomerAttribute;
  documentAttributes: DocumentAttribute[] = [];
  selectedDocumentAttribute: DocumentAttribute = emptyDocumentAttribute;
  itemAttributes: ItemAttribute[] = [];
  selectedItemAttribute: ItemAttribute = emptyItemAttribute;
  priceBooks: PriceBook[] = [];
  selectedPriceBook: PriceBook = emptyPriceBook;

  historyDate: Date = new Date();
  min: Date = new Date(2000, 0);

  constructor(
    private route: ActivatedRoute,
    private pricingConditionsService: PricingConditionsService,
    private uofMsService: UofMsService,
    private roundingRulesService: RoundingRulesService,
    private pricingFiltersService: PricingFiltersService,
    private gLAccountsService: GLAccountsService,
    private lookupService: LookupsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private snack: MySnackBarService,
    private router: Router,
    private priceBooksService: PriceBookService,
    private location: Location,
    private copyService: CopyService
  ) {
    this.route.params.subscribe(params => {
      if (params.id && params.id === 'new') {
        this.pricingCondition = emptyPricingCondition();
        //this.pricingCondition.conditionOperandID = null; //mhr hack workaround for now; this value in the emptyPricingCondition is not null for some reason, it is an array!
        this.idOfPricingCondition = null;
        this.submitted = false;
        this.updateForm();
        this.buildForm();
        return;
      }
      this.idOfPricingCondition = params.id;
      this.loadData();
    });

    this.uofMSearch = debounce(
      this.uofMSearch,
      400,
      {},
    );
    this.debitGLAccountSearch = debounce(
      this.debitGLAccountSearch, 400,
      {}
    );
    this.creditGLAccountSearch = debounce(
      this.creditGLAccountSearch, 400,
      {}
    );
    this.roundingRuleSearch = debounce(
      this.roundingRuleSearch, 400,
      {}
    );
    this.conditionalPricingFilterSearch = debounce(
      this.conditionalPricingFilterSearch, 400,
      {}
    );
  }

  ngOnInit(): void {
    this.buildForm();
    this.updateForm();
  }

  buildForm() {
    this.pricingConditionForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)]),
      description: new FormControl(''),
      conditionalPricingFilterID: new FormControl(''),
      conditionTypeID: new FormControl('', [Validators.required]),
      conditionOperandID: new FormControl('', [Validators.required]),
      conditionQuantity: new FormControl(''),
      conditionAmount: new FormControl(''),
      convertToUofM: new FormControl(false),
      convertToUofMName: new FormControl(''),
      maximumAffectedQuantity: new FormControl('', [Validators.required]),
      historyDateOptionID: new FormControl(''),
      historyDateValue: new FormControl(''),
      historyDate: new FormControl('')
    });
    this.getPriceModeOptions();
    this.getHistoryDateOptions();
    this.getConditionOptions();
    this.getConditionOperands();
    this.getConditionTypeOptions();
    this.pricingConditionForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }

  //async getEntityOptions() {
  //  const Filter_OptionSet = 'EntityType';
  //  const params = { Filter_OptionSet };
  //  const allEntityTypes: Lookup[] = await this.lookupService.getList(params);

  //  if (allEntityTypes.length > 0) {
  //    for (const key in allEntityTypes) {
  //      const currentLookup: Lookup = allEntityTypes[key];
  //      if (currentLookup.name == "Customer Attribute" || currentLookup.name == "Document Attribute" || currentLookup.name == "Item Attribute" || currentLookup.name == "Price Book") {
  //        this.entityTypes.push(currentLookup);
  //      }        
  //    }
  //  }
  //  return this.entityTypes;
  //}

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMPricingCondition", this.pricingCondition.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }
  get f() {
    return this.pricingConditionForm.controls;
  }

  updateForm() {
    this.pricingConditionForm.patchValue({ ...this.pricingCondition });
    this.isFormChanged = false;
  }

  clearPrefill() {
    setTimeout(() => {
      this.perUofMs = [];
      this.debitGLAccounts = [];
      this.creditGLAccounts = [];
      this.roundingRules = [];
      this.conditionalPricingFilters = [];
      //this.pricingFilters = [];
    });
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.pricingCondition.id);
    await this.pricingConditionsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/pricing-conditions']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }


  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.pricingCondition && this.pricingCondition.id) {
      return false;
    }
    else {
      return true;
    }
  }

  onClickBack(): void {
    this.location.back();
  }

  onClickSave() {
    this.submit(null);
  }

  onClickSaveClose() {
    this.submit("Close");
  }

  onClickSaveNew() {
    this.submit("New");
  }

  async onClickAddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/pricing-condition/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/pricing-condition/new']);
      });
    }
  }

  async submit(action) {
    this.pricingConditionForm.markAllAsTouched();
    this.submitted = true;
    if (this.pricingConditionForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.pricingCondition,
      ...this.pricingConditionForm.getRawValue() // pricingConditionForm.value does not include disabled controls.  //mhr new try
    };

    // Check to see if Pricing Condition Name already exists. 
    if ((await this.checkForExistingName()) === true) {
      this.snack.openSnackBar(
        'Pricing Condition Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.pricingCondition.id) {
        try {
          const response: any = await this.pricingConditionsService.update(
            this.pricingCondition.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/pricing-conditions']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/pricing-condition/new']);
              });
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      } else {
        try {
          const response: any = await this.pricingConditionsService.create(
            this.pricingConditionForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.pricingCondition = response.body;
            this.snack.openSnackBar(
              'Record saved successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/pricing-conditions']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/pricing-condition/new']);
              });
            }
            else {
              this.pricingCondition = response.body;
              this.router.navigate(['/price/pricing-condition/', this.pricingCondition.id]);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
    }
    this.isFormChanged = false;
  }

  async getPriceModeOptions() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'PriceMode';
    const params = { Filter_OptionSet, PageSize };
    this.priceModeOptions = await this.lookupService.getList(params);
    return this.priceModeOptions;
  }

  async getHistoryDateOptions() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'HistoryDate';
    const params = { Filter_OptionSet, PageSize };
    this.historyDateOptions = await this.lookupService.getList(params);
    return this.historyDateOptions;
  }

  async getConditionOptions() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'Condition';
    const params = { Filter_OptionSet, PageSize };
    this.conditionOptions = await this.lookupService.getList(params);
    return this.conditionOptions;
  }

  async getConditionOperands() {
    this.conditionOperands.length = 0;
    this, this.pricingConditionForm.patchValue({ ['conditionOperandID']: null });
    this.conditionOperands.push({ id: 20, name: "Equals", optionSet: "Operand", sortOrder: 2 });
    this.conditionOperands.push({ id: 21, name: "Does Not Equal", optionSet: "Operand", sortOrder: 2 });
    this.conditionOperands.push({ id: 24, name: "Greater Than", optionSet: "Operand", sortOrder: 6 });
    this.conditionOperands.push({ id: 25, name: "Greater Than or Equal", optionSet: "Operand", sortOrder: 7 });
    this.conditionOperands.push({ id: 26, name: "Less Than", optionSet: "Operand", sortOrder: 8 });
    this.conditionOperands.push({ id: 27, name: "Less Than or Equal", optionSet: "Operand", sortOrder: 9 });
    return this.conditionOperands;
  }

  async getConditionTypeOptions() { //mhr need this list to sort in sort order
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'ConditionType';
    const params = { Filter_OptionSet, PageSize };
    this.conditionTypeOptions = await this.lookupService.getList(params);
    return this.conditionOptions;
    //this.conditionTypes.length = 0;
    //this, this.pricingConditionForm.patchValue({ ['conditionTypeID']: null });
    //this.conditionTypes.push({ id: 78, name: "Quantity on All Document Lines", optionSet: "ConditionType", sortOrder: 1 });
    //this.conditionTypes.push({ id: 79, name: "Quantity on Previous Document Lines", optionSet: "ConditionType", sortOrder: 2 });
    //this.conditionTypes.push({ id: 80, name: "Amount on Previous Document Lines", optionSet: "ConditionType", sortOrder: 3 });
    //this.conditionTypes.push({ id: 81, name: "Quantity of Historical Sales", optionSet: "ConditionType", sortOrder: 4 });
    //this.conditionTypes.push({ id: 82, name: "Amount of Historical Sales", optionSet: "ConditionType", sortOrder: 5 });
    //this.conditionTypes.push({ id: 83, name: "Number of Historical Sale Documents", optionSet: "ConditionType", sortOrder: 6 });
    //return this.conditionTypes;

  }

  //MHR example for above
  //async getItemAttributeDataTypeOptions() {
  //  const PageSize = StorageService.PageSize() ?? 50;
  //  const Filter_OptionSet = 'AttributeDataType';
  //  const params = { Filter_OptionSet, PageSize };
  //  this.itemAttributeDataTypeOptions = await this.lookupService.getList(params);
  //  const defaultLookup: Lookup = emptyLookup;
  //  defaultLookup.id = 999;
  //  defaultLookup.optionSet = "AttributeDataType";
  //  defaultLookup.name = "(All)";
  //  this.itemAttributeDataTypeOptions.reverse();
  //  this.itemAttributeDataTypeOptions.push(defaultLookup);
  //  this.itemAttributeDataTypeOptions.reverse();
  //  const index = this.itemAttributeDataTypeOptions.findIndex(x => x.name == "Lookup", 0);
  //  if (index > -1) {
  //    this.itemAttributeDataTypeOptions.splice(index, 1);
  //  }
  //  return this.itemAttributeDataTypeOptions;
  //}



  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.pricingConditionForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingPricingConditions: PricingCondition[] = await (
      await this.pricingConditionsService.getList(params)
    ).body;
    if (existingPricingConditions.length > 0) {
      if (this.pricingCondition.id) {
        for (const key in existingPricingConditions) {
          const currentPricingCondition: PricingCondition = existingPricingConditions[key];
          if (currentPricingCondition.id !== this.pricingCondition.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async getPricingConditionById() {
    if (this.idOfPricingCondition) {
      return await this.pricingConditionsService.getById(this.idOfPricingCondition);
    } else {
      return emptyPricingCondition();
    }
  }

  async getConditionalPricingFilterById(id) {
    return await this.pricingFiltersService.getById(id);
  }

  async loadData() {
    this.loading = true;
    try {
      if (this.idOfPricingCondition) {
        this.pricingCondition = await Promise.resolve(this.getPricingConditionById());

        if (this.pricingCondition.conditionalPricingFilterID) {
          const currentConditionalPricingFilter: PricingFilter = await Promise.resolve(this.getConditionalPricingFilterById(this.pricingCondition.conditionalPricingFilterID));
          this.conditionalPricingFilters.push(currentConditionalPricingFilter);
        }

        if (this.pricingCondition.historyDate) {
          this.pricingCondition.historyDate = new Date(this.pricingCondition.historyDate);
        }
      }

      this.getPriceModeOptions();
      this.updateForm();
    } catch (e) {
    } finally {
      this.loading = false;
    }
    if (this?.pricingCondition?.convertToUofM) {
      this.convertToUofMValue = this.pricingCondition.convertToUofM;
    }

    if (this?.pricingCondition?.historyDateOption != null) {
      this.historyDateOptionChange(this.pricingCondition.historyDateOption.id);
    }

    if (this?.pricingCondition?.conditionType != null) {
      this.conditionTypeOptionChange(this.pricingCondition.conditionType.id);
    }

    if (this?.pricingCondition?.convertToUofM != null) {
      this.convertToUofMChange();
    }

    this.isFormChanged = false;
  }

  prepareData() {
    [
      //@ts-ignore
      this.uofMs,
      //@ts-ignore
      this.debitGLAccounts,
      //@ts-ignore
      this.creditGLAccounts,
      //@ts-ignore
      this.roundingRules,
      //@ts-ignore
      this.conditionalPricingFilters,
      //@ts-ignore
      //this.pricingFilters,
      //@ts-ignore
      this.priceModeOptions
    ] = prepareData(
      this.pricingCondition,
      this.conditionalPricingFilters,
    );

    this.updateForm();
  }

  async uofMSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getUofMs({ SearchTerm });
  }

  async debitGLAccountSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getDebitGLAccounts({ SearchTerm });
  }

  async creditGLAccountSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getCreditGLAccounts({ SearchTerm });
  }

  async roundingRuleSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getRoundingRules({ SearchTerm });
  }

  //async pricingFilterSearch(event) {
  //  const SearchTerm = event.term; // or Filter_Name
  //  return await this.getPricingFilters({ SearchTerm });
  //}

  async conditionalPricingFilterSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getConditionalPricingFilters({ SearchTerm });
  }

  async getUofMs(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.uofMsService.getList({ ...params, PageSize });
      // @ts-ignore
      this.uofMs = resp.body;
      this.paginationObject.uofMs = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.perUofMs = [];
    }
    return this.perUofMs;
  }

  async getDebitGLAccounts(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.gLAccountsService.getList({ ...params, PageSize });
      // @ts-ignore
      this.debitGLAccounts = resp.body;
      this.paginationObject.debitGLAccounts = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.debitGLAccounts = [];
    }
    return this.debitGLAccounts;
  }

  async getCreditGLAccounts(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.gLAccountsService.getList({ ...params, PageSize });
      // @ts-ignore
      this.creditGLAccounts = resp.body;
      this.paginationObject.creditGLAccounts = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.creditGLAccounts = [];
    }
    return this.creditGLAccounts;
  }

  async getRoundingRules(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.roundingRulesService.getList({ ...params, PageSize });
      // @ts-ignore
      this.roundingRules = resp.body;
      this.paginationObject.roundingRules = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.roundingRules = [];
    }
    return this.roundingRules;
  }

  //async getPricingFilters(params) {
  //  try {
  //    const PageSize = StorageService.PageSize() ?? 50;
  //    const resp = await this.pricingFiltersService.getList({...params, PageSize});
  //    this.pricingFilters = resp.body;     
  //    this.paginationObject.pricingFilters = getPaginationHeader(
  //      resp.headers,
  //    );
  //  } catch (e) {
  //    this.pricingFilters = [];
  //  }
  //  return this.pricingFilters;
  //}

  async getConditionalPricingFilters(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.pricingFiltersService.getList({ ...params, PageSize });
      this.conditionalPricingFilters = resp.body;
      this.paginationObject.conditionalPricingFilters = getPaginationHeader(
        resp.headers,
      );
    } catch (e) {
      this.conditionalPricingFilters = [];
    }
    return this.conditionalPricingFilters;
  }

  onSelectBoxChanged(id: number, key: keyof PricingCondition, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.pricingCondition[key] = item;
    if (key == "historyDateOptionID") {
      this.historyDateOptionChange(id);
    }
    else if (key == "conditionTypeID") {
      this.conditionTypeOptionChange(id);
    }
  }

  conditionTypeOptionChange(conditionTypeID) {
    if (conditionTypeID == 81 || conditionTypeID == 82 || conditionTypeID == 83) { // Historical
      this.f.historyDateOptionID.setValidators(Validators.required);
      this.f.historyDateOptionID.updateValueAndValidity();
      this.showHistoryDateOption = true;
      //this.historyDateOptionChange; 
    }
    else { //non-historical
      this.pricingConditionForm.patchValue({ ['historyDateOptionID']: null });
      this.f.historyDateOptionID.clearValidators();
      this.f.historyDateOptionID.updateValueAndValidity();
      this.showHistoryDateOption = false;
      this.pricingConditionForm.patchValue({ ['historyDateValue']: null });
      this.f.historyDateValue.clearValidators();
      this.f.historyDateValue.updateValueAndValidity();
      this.showHistoryDateValue = false;
      this.pricingConditionForm.patchValue({ ['historyDate']: null });
      this.f.historyDate.clearValidators();
      this.f.historyDate.updateValueAndValidity();
      this.showHistoryDate = false;
    }
    if (conditionTypeID == 80 || conditionTypeID == 82) { // Amount
      this.f.conditionAmount.setValidators(Validators.required);
      this.f.conditionAmount.updateValueAndValidity();
      this.showConditionAmount = true;
      this.pricingConditionForm.patchValue({ ['conditionQuantity']: null });
      this.f.conditionQuantity.clearValidators();
      this.f.conditionQuantity.updateValueAndValidity();
      this.showConditionQuantity = false;
    }
    else { //Qty
      this.f.conditionQuantity.setValidators(Validators.required);
      this.f.conditionQuantity.updateValueAndValidity();
      this.showConditionQuantity = true;
      this.pricingConditionForm.patchValue({ ['conditionAmount']: null });
      this.f.conditionAmount.clearValidators();
      this.f.conditionAmount.updateValueAndValidity();
      this.showConditionAmount = false;
    }
  }

  historyDateOptionChange(dateOptionID) {
    if (dateOptionID == 49) { // Since Specific Date
      this.f.historyDate.setValidators(Validators.required);
      this.f.historyDate.updateValueAndValidity();
      this.showHistoryDate = true;
      this.pricingConditionForm.patchValue({ ['historyDateValue']: null });
      this.f.historyDateValue.clearValidators();
      this.f.historyDateValue.updateValueAndValidity();
      this.showHistoryDateValue = false;
    }
    else {
      this.f.historyDateValue.setValidators(Validators.required);
      this.f.historyDateValue.updateValueAndValidity();
      this.showHistoryDateValue = true;
      this.pricingConditionForm.patchValue({ ['historyDate']: null });
      this.f.historyDate.clearValidators();
      this.f.historyDate.updateValueAndValidity();
      this.showHistoryDate = false;
    }
  }

  convertToUofMChange() {
    if (this?.f?.convertToUofM) {
      if (this.f.convertToUofM.value == true) {
        this.f.convertToUofMName.setValidators(Validators.required);
        this.f.convertToUofMName.updateValueAndValidity();
        this.showConvertToUofMName = true;
      }
      else {
        this.pricingConditionForm.patchValue({ ['convertToUofMName']: null });
        this.f.convertToUofMName.clearValidators();
        this.f.convertToUofMName.updateValueAndValidity();
        this.showConvertToUofMName = false;
      }
      this.convertToUofMValue = this.f.convertToUofM.value
    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.ConditionalPricingFilter:
        this.conditionalPricingFilters = await (
          await this.pricingFiltersService.getList(params)
        ).body;
        break;
      //case Filters.PricingFilter:
      //  this.pricingFilters = await (
      //    await this.pricingFiltersService.getList(params)
      //  ).body;
      //  break;
      case Filters.PriceBook:
        this.priceBooks = await (
          await this.priceBooksService.getList(params)
        ).body;
        break;
      default:
        break;
    }
  }

  async onLookup_conditionalPricingFilter() {
    const modalRef = this.modalService.open(PricingFilterLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'PricingFilterModalClass',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "PricingFilterDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PricingFilter = receivedEntry;
      this.conditionalPricingFilters = [];
      this.conditionalPricingFilters.push(recordSelected);
      this.pricingConditionForm.patchValue({ ['conditionalPricingFilterID']: receivedEntry.id });
      this.pricingCondition.conditionalPricingFilter = receivedEntry;
      modalRef.close('test');
    });
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async canDeactivate(nextURL: string) {
    console.log("canDeactivate - isFormChanged: " + this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }
}
