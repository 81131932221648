<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete that entity ?</p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-outline-primary"
            (click)="remove(modal)">
      Yes
    </button>
    <button type="button"
            class="btn btn-outline-secondary"
            (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/View_Diagnostics.png" alt="Pricing Diagnostics" style="padding-right: 5px;">
  Pricing Diagnostics
</div>
<kendo-grid #grid="kendoGrid"
            [data]="gridView"
            [pageSize]="query.PageSize"
            [skip]="skip"
            [sort]="query.SortTerm"
            [reorderable]="true"
            [loading]="loading"
            [pageable]="true"
            (pageChange)="pageChange($event)"
            [filter]="filter"
            [filterable]="filterable"
            (filterChange)="gridFilterChange($event)"
            [resizable]="true"
            [sortable]="true"
            (sortChange)="sortChange($event)"
            [selectable]="{ mode: 'multiple' }"
            kendoGridSelectBy="id"
            [selectedKeys]="mySelection"
            (cellClick)="onCellClick($event)"
            (dblclick)="onDblClick()"
            [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">Refresh</button>
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>

    <button kendoButton *ngIf="isAdmin" type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            imageUrl="../../../../assets/images/Action.png" title="Purge Log"
            (click)="openPurgeDialog()">
      Purge Log
    </button>
    <kendo-dialog *ngIf="purgeDialogOpened" (close)="closePurgeDialog()">
      <div style="text-align: center; margin: 30px;">
        <h4>Enter number of days of Diagnostic Log to keep.  Any logs older than this many days will be permanently deleted.  This action cannot be undone.</h4>
        <p>
          <input kendoTextBox [(ngModel)]="purgeDaysToKeep" type="number" pattern="^[0-9]+$" min="0" placeholder="Days to Keep" [style.width.px]="150" />
        </p>
        <button kendoButton themeColor="primary" [style.width.px]="100" (click)="processPurge()">Purge</button>
        <button kendoButton fillMode="flat" (click)="closePurgeDialog()">Cancel</button>
      </div>
    </kendo-dialog>

  </ng-template>
  <kendo-grid-column field="id" title="ID" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="runDateTime" title="Run Date" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-date-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.runDateTime | date: 'MM/dd/yyyy' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="resultSummary" title="Result Summary" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="outputPrice" title="Output Price" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="calculationTime" title="Calculation Time" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>
