<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Price_Calculation.png" alt="Price Calculation" style="padding-right: 5px;">
  Create / Update Price Calculation
</div>
<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Back.png" (click)="onClickBack()"
          routerLinkActive="router-link-active" title="Go Back">
    BACK
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save.png" (click)="onClickSave()" title="Save">
    SAVE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save_Close.png" (click)="onClickSaveClose()" title="Save & Close">
    SAVE &
    CLOSE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save_New.png" (click)="onClickSaveNew()" title="Save & New">
    SAVE & NEW
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Add.png" (click)="onClickAddNew()" title="Add New">
    ADD NEW
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">
    COPY
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="showRemovePopUp(areYouSure)"
          title="Delete Record">
    DELETE
  </button>
</kendo-buttongroup>
<form [formGroup]="priceCalculationForm">
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Price Calculation Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Price Calculation Name"
                       maxlength="100">
        </kendo-textbox>
        <kendo-formerror>Name is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="priceModeOption" class="font-weight-bold" text="* Price Mode Option"></kendo-label>
        <ng-select id="priceModeOption" [multiple]="false" [selectOnTab]="true" formControlName="priceModeOptionID"
                   [notFoundText]="'Please start typing to search'" [ngClass]="{
            'is-invalid': submitted && f.priceModeOptionID?.errors,
            'w-100': true
            }" placeholder="Select a Price Mode Option" [searchable]="true"
                   (change)="onSelectBoxChanged($event, 'priceModeOption', priceModeOptions)">
          <ng-option *ngFor="let item of priceModeOptions" [value]="item.id">
            {{ item.name }}
          </ng-option>
        </ng-select>
        <kendo-formerror>Price Mode Option is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>

  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="description" text="Description"></kendo-label>
        <kendo-textbox formControlName="description" class="form-control" id="description">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>

  <div class="row" *ngIf="f.priceModeOptionID?.value === 187">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label class="font-weight-bold" for="dataMapID" text="* Data Map"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="dataMapID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     [notFoundText]="'Please start typing to search'" formControlName="dataMapID"
                     placeholder="Select an Data Map" [ngClass]="{'is-invalid': submitted && f.dataMapID.errors,'w-100': true}" [searchable]="true"
                     (search)="search($event, filters.DataMap)"
                     (change)="onSelectBoxChanged($event,'dataMapID',dataMaps)">
            <ng-option *ngFor="let item of dataMaps" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_DataMap()" icon="search"></button>
        </span>
        <kendo-formerror>Data Map is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>

  <div *ngIf="f.priceModeOptionID?.value !== 187">
    <div class="row">
      <div class="col">
        <div>
          <input type="checkbox" id="modifyUnitPrice" formControlName="modifyUnitPrice" #modifyUnitPrice kendoCheckBox />
          <kendo-label class="k-checkbox-label" [for]="modifyUnitPrice" text="Modify Unit Price"></kendo-label>

          <input type="checkbox" id="fromTheBaseStartingPrice" formControlName="fromTheBaseStartingPrice"
                 #fromTheBaseStartingPrice kendoCheckBox />
          <kendo-label class="k-checkbox-label" [for]="fromTheBaseStartingPrice" text="From the Base Starting Price">
          </kendo-label>
        </div>
        <!--<div *ngIf="showDeprecated">-->
        <div>
          <input type="checkbox" id="useValueFromAttribute" formControlName="useValueFromAttribute" (change)="useValueFromAttributeChanged()"
                 #useValueFromAttribute kendoCheckBox />
          <kendo-label class="k-checkbox-label" [for]="useValueFromAttribute" text="Use Value from Entity">
          </kendo-label>
          <input type="checkbox" id="useDefaultCurrency" formControlName="useDefaultCurrency"
                 #useDefaultCurrency kendoCheckBox />
          <kendo-label class="k-checkbox-label" [for]="useDefaultCurrency" text="Use Default Currency">
          </kendo-label>
        </div>
        <!--<div>
          <input type="checkbox" id="useNumberSource" formControlName="useNumberSource" (change)="useNumberSourceChanged()"
                 #useNumberSource kendoCheckBox />
          <kendo-label class="k-checkbox-label" [for]="useNumberSource" text="Use Number Source">
          </kendo-label>
        </div>-->
      </div>
      <div class="col flex-nowrap">
        <div *ngIf="showPricePercent">
          <kendo-formfield>
            <kendo-label for="pricePercent" class="font-weight-bold" text="* Percent"></kendo-label>
            <kendo-numerictextbox class="form-control" formControlName="pricePercent" [autoCorrect]="true" [decimals]="5" [spinners]="false"
                                  [format]="'n5'" [ngClass]="{ 'is-invalid': submitted && f.pricePercent.errors }"
                                  [min]="-99999999999999" [max]="99999999999999"
                                  placeholder="Enter a Percent Amount">
            </kendo-numerictextbox>
            <kendo-formerror>Percent is required</kendo-formerror>
          </kendo-formfield>
        </div>
        <div *ngIf="showPriceAmount">
          <kendo-formfield>
            <kendo-label for="priceAmount" class="font-weight-bold" text="* Amount"></kendo-label>
            <kendo-numerictextbox class="form-control" formControlName="priceAmount" [autoCorrect]="true" [decimals]="5" [maxlength]="15"
                                  [format]="'#########.#####'" [ngClass]="{ 'is-invalid': submitted && f.priceAmount.errors }"
                                  [min]="-99999999999999" [max]="99999999999999"
                                  placeholder="Enter a Price Amount">
            </kendo-numerictextbox>
            <kendo-formerror>Amount is required</kendo-formerror>
          </kendo-formfield>
        </div>
        <!--<div *ngIf="showEntity && showDeprecated">-->
        <div *ngIf="showEntity">
          <kendo-formfield>
            <kendo-label for="entityID" class="font-weight-bold" text="* Entity"></kendo-label>
            <ng-select id="entityID" [multiple]="false" [selectOnTab]="true" formControlName="entityID"
                       [notFoundText]="'Please start typing to search'" [ngClass]="{
                  'is-invalid': submitted && f.entityID.errors,
                  'w-100': true
                }" placeholder="Select an Entity" [searchable]="true"
                       (change)="onSelectBoxChanged($event, 'entityID', entityTypes)">
              <ng-option *ngFor="let item of entityTypes" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <div *ngIf="submitted && f.entityID.errors" class="invalid-feedback">
              <div *ngIf="f.entityID?.errors?.required">Entity is required</div>
            </div>
          </kendo-formfield>
        </div>

        <kendo-formfield *ngIf="showNumberSource">
          <kendo-label class="font-weight-bold" for="numberSourceID" text="* Number Source"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="numberSourceID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       [notFoundText]="'Please start typing to search'" formControlName="numberSourceID"
                       placeholder="Select an Number Source"
                       [ngClass]="{'is-invalid': submitted && f.numberSourceID.errors,'w-100': true}"
                       [searchable]="true" (search)="search($event, filters.NumberSource)"
                       (change)="onSelectBoxChanged($event,'numberSourceID',numberSources)">
              <ng-option *ngFor="let item of numberSources" [value]="item.id">{{ item.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton type="button" look="clear" (click)="onLookup_NumberSource()" icon="search"></button>
          </span>
          <kendo-formerror>Number Source is required</kendo-formerror>
        </kendo-formfield>


      </div>
    </div>

    <!--<div class="row" style="padding-top:15px;" *ngIf="showDeprecated">-->
    <div class="row" style="padding-top:15px;">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="pricingFilterID" text="Pricing Filter"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="pricingFilterID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="pricingFilterID"
                       [ngClass]="{'is-invalid': submitted && f.pricingFilterID.errors,'w-100': true}"
                       [searchable]="true" [notFoundText]="'Please start typing to search'"
                       (search)="search($event, filters.PricingFilter)"
                       (change)="onSelectBoxChanged($event,'pricingFilterID',pricingFilters)"
                       placeholder="Select a Pricing Filter">
              <ng-option *ngFor="let item of pricingFilters" [value]="item.id">{{ item.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton type="button" look="clear" (click)="onLookup_pricingFilter()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield *ngIf="showCustomerAttribute">
          <kendo-label class="font-weight-bold" for="customerAttributeID" text="* Customer Attribute"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="customerAttributeID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       [notFoundText]="'Please start typing to search'" formControlName="customerAttributeID"
                       placeholder="Select an Attribute" [ngClass]="{
                  'is-invalid': submitted && f.customerAttributeID.errors,
                  'w-100': true
                }" [searchable]="true"
                       (search)="search($event, filters.CustomerAttribute)" (change)="
                  onSelectBoxChanged(
                    $event,
                    'customerAttributeID',
                    customerAttributes)">
              <ng-option *ngFor="let item of customerAttributes" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton type="button" look="clear" (click)="onLookup_CustomerAttribute()"
                    icon="search"></button>
          </span>
          <kendo-formerror>Customer Attribute is required</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield *ngIf="showDocumentAttribute">
          <kendo-label class="font-weight-bold" for="documentAttributeID" text="* Document Attribute"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="documentAttributeID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       [notFoundText]="'Please start typing to search'" formControlName="documentAttributeID"
                       placeholder="Select an Attribute" [ngClass]="{
                  'is-invalid': submitted && f.documentAttributeID.errors,
                  'w-100': true
                }" [searchable]="true"
                       (search)="search($event, filters.DocumentAttribute)" (change)="
                  onSelectBoxChanged(
                    $event,
                    'documentAttributeID',
                    documentAttributes)">
              <ng-option *ngFor="let item of documentAttributes" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton type="button" look="clear" (click)="onLookup_DocumentAttribute()"
                    icon="search"></button>
          </span>
          <kendo-formerror>Document Attribute is required</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield *ngIf="showItemAttribute">
          <kendo-label class="font-weight-bold" for="itemAttributeID" text="* Item Attribute"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="itemAttributeID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       [notFoundText]="'Please start typing to search'" formControlName="itemAttributeID"
                       placeholder="Select an Attribute" [ngClass]="{
                  'is-invalid': submitted && f.itemAttributeID.errors,
                  'w-100': true
                }" [searchable]="true"
                       (search)="search($event, filters.ItemAttribute)" (change)="
                  onSelectBoxChanged(
                    $event,
                    'itemAttributeID',
                    itemAttributes)">
              <ng-option *ngFor="let item of itemAttributes" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton type="button" look="clear" (click)="onLookup_ItemAttribute()" icon="search"></button>
          </span>
          <kendo-formerror>Item Attribute is required</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield *ngIf="showPriceBook">
          <kendo-label class="font-weight-bold" for="priceBookID" text="* Price Book"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="priceBookID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       [notFoundText]="'Please start typing to search'" formControlName="priceBookID"
                       placeholder="Select a Price Book" [ngClass]="{
                  'is-invalid': submitted && f.priceBookID.errors,
                  'w-100': true
                }" [searchable]="true"
                       (search)="search($event, filters.PriceBook)" (change)="
                  onSelectBoxChanged(
                    $event,
                    'priceBookID',
                    priceBooks)">
              <ng-option *ngFor="let item of priceBooks" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton type="button" look="clear" (click)="onLookup_PriceBook()" icon="search"></button>
          </span>
          <kendo-formerror>Price Book is required</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield *ngIf="showAttributeMap">
          <kendo-label class="font-weight-bold" for="attributeMapID" text="* Attribute Map"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="attributeMapID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       [notFoundText]="'Please start typing to search'" formControlName="attributeMapID"
                       placeholder="Select an Attribute Map"
                       [ngClass]="{'is-invalid': submitted && f.attributeMapID.errors,'w-100': true}"
                       [searchable]="true" (search)="search($event, filters.AttributeMap)"
                       (change)="onSelectBoxChanged($event,'attributeMapID',attributeMaps)">
              <ng-option *ngFor="let item of attributeMaps" [value]="item.id">{{ item.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton type="button" look="clear" (click)="onLookup_AttributeMap()" icon="search"></button>
          </span>
          <kendo-formerror>Attribute Map is required</kendo-formerror>
        </kendo-formfield>

      </div>
    </div>

    <div class="row" *ngIf="showPriceBook || showAttributeMap || showCustomerAttribute || showItemAttribute || showDocumentAttribute">
      <div class="col form-group">
      </div>
      <div class="col form-group">
        <input type="checkbox" id="abortIfValueNotFound" formControlName="abortIfValueNotFound"
               #abortIfValueNotFound kendoCheckBox />
        <kendo-label class="k-checkbox-label" [for]="abortIfValueNotFound" text="Abort Schedule if Value Not Found">
        </kendo-label>
      </div>
    </div>

    <div class="row">
      <!--<div class="col form-group" *ngIf="showDeprecated">-->
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="pricingCondition" text="Pricing Condition"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="pricingConditionID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="pricingConditionID"
                       placeholder="Select a Pricing Condition"
                       [ngClass]="{'is-invalid': submitted && f.pricingConditionID.errors,'w-100': true}"
                       [searchable]="true" [notFoundText]="'Please start typing to search'"
                       (search)="search($event, filters.PricingCondition)"
                       (change)="onSelectBoxChanged($event,'pricingConditionID',pricingConditions)">
              <ng-option *ngFor="let item of pricingConditions" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton type="button" look="clear" (click)="onLookup_pricingCondition()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="roundingRule" text="Rounding Rule"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="roundingRuleID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="roundingRuleID"
                       placeholder="Select a Rounding Rule"
                       [ngClass]="{
            'is-invalid': submitted && f.roundingRuleID.errors,
            'w-100': true
            }" [searchable]="true" [notFoundText]="'Please start typing to search'"
                       (search)="search($event, filters.RoundingRule)" (change)="
            onSelectBoxChanged(
              $event,
              'roundingRuleID',
              roundingRules)">
              <ng-option *ngFor="let item of roundingRules" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton type="button" look="clear" (click)="onLookup_roundingRule()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
    </div>
    <div class="row" *ngIf="showConvertToUofM">
      <div class="col form-group d-flex align-items-center">
        <div class="wrap" style="padding-right: 10px;">
          <input type="checkbox" id="convertToUofM" (change)="convertToUofMChanged()" formControlName="convertToUofM"
                 #convertToUofM kendoCheckBox />
          <kendo-label class="k-checkbox-label" [for]="convertToUofM" text="Convert to Unit of Measure"></kendo-label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="showConvertToUofM && convertToUofMValue">
      <div class="col form-group">
        <kendo-formfield *ngIf="convertToUofMValue">
          <kendo-label for="perUofMName" class="font-weight-bold" text="* Per Unit of Measure Name"></kendo-label>
          <kendo-textbox class="form-control" formControlName="perUofMName" placeholder="Enter Unit of Measure by Name"
                         maxlength="100">
          </kendo-textbox>
          <kendo-formerror>Per Unit of Measure Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <kendo-tabstrip [tabPosition]="'top'">
      <kendo-tabstrip-tab [title]="'Tracking'" [selected]="true">
        <ng-template kendoTabContent>
          <div class="card p-3" style="background-color:rgb(234, 234, 234)">
            <div class="row">
              <div class="col form-group d-flex align-items-center">
                <div class="wrap" style="padding-right: 10px;">
                  <input type="checkbox" id="trackAdjustmentExtended" formControlName="trackAdjustmentExtended"
                         #trackAdjustmentExtended kendoCheckBox />
                  <kendo-label class="k-checkbox-label" [for]="trackAdjustmentExtended"
                               text="Track As Extended Price Adjustments"
                               title="Multiplies the Calculated Adjustment by the Quantity">
                  </kendo-label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col form-group d-flex align-items-center">
                <div class="wrap">
                  <input type="checkbox" id="trackAdjustment" (change)="trackAsAdjustmentChanged()"
                         formControlName="trackAdjustment" #trackAdjustment kendoCheckBox />
                  <kendo-label class="k-checkbox-label" [for]="trackAdjustment" text="Track Adjustments"></kendo-label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col form-group" style="padding-left:50px; padding-right: 25px;">
                <kendo-formfield *ngIf="trackAdjustmentValue">
                  <kendo-label for="debitGLAccount" [text]="areGLAccountsRequired ? '* Debit GL Account' : 'Debit GL Account'"
                               [class.font-weight-bold]="areGLAccountsRequired">
                  </kendo-label>
                  <span class="k-textbox" style="width: 100%;">
                    <ng-select id="debitGLAccountID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                               formControlName="debitGLAccountID"
                               [ngClass]="{'is-invalid': submitted && f.debitGLAccountID?.errors,'w-100': true}" [searchable]="true"
                               placeholder="Select a Debit GL Account"
                               [notFoundText]="'Please start typing to search'"
                               (search)="search($event, filters.DebitGLAccount)"
                               (change)="onSelectBoxChanged($event,'debitGLAccountID',debitGLAccounts)">
                      <ng-option *ngFor="let item of debitGLAccounts" [value]="item.id"> {{ item.name }} </ng-option>
                    </ng-select>
                    <kendo-textbox-separator></kendo-textbox-separator>
                    <button kendoButton type="button" look="clear" (click)="onLookup_debitGLAccount()" icon="search">
                    </button>
                  </span>
                  <kendo-formerror>Debit GL Account is required if Credit GL Account is specified</kendo-formerror>
                </kendo-formfield>
              </div>
            </div>

            <div class="row">
              <div class="col form-group" style="padding-left:50px; padding-right: 25px;">
                <kendo-formfield *ngIf="trackAdjustmentValue">
                  <kendo-label for="creditGLAccount" [text]="areGLAccountsRequired ? '* Credit GL Account' : 'Credit GL Account'"
                               [class.font-weight-bold]="areGLAccountsRequired">
                  </kendo-label>
                  <span class="k-textbox" style="width: 100%;">
                    <ng-select id="creditGLAccountID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                               formControlName="creditGLAccountID"
                               [ngClass]="{'is-invalid': submitted && f.creditGLAccountID?.errors,'w-100': true}" [searchable]="true"
                               placeholder="Select a Credit GL Account"
                               [notFoundText]="'Please start typing to search'"
                               (search)="search($event, filters.CreditGLAccount)"
                               (change)="onSelectBoxChanged($event,'creditGLAccountID',creditGLAccounts)">
                      <ng-option *ngFor="let item of creditGLAccounts" [value]="item.id"> {{ item.name }} </ng-option>
                    </ng-select>
                    <kendo-textbox-separator></kendo-textbox-separator>
                    <button kendoButton type="button" look="clear" (click)="onLookup_creditGLAccount()" icon="search">
                    </button>
                  </span>
                  <kendo-formerror>Credit GL Account is required if Debit GL Account is specified</kendo-formerror>
                </kendo-formfield>
              </div>
            </div>

            <div class="row">
              <div class="col form-group d-flex align-items-center">
                <div class="wrap" *ngIf="trackAdjustmentValue" style="padding-left: 35px;">
                  <input type="checkbox" [disabled]="trackAdjustmentValue" id="userCanEdit" formControlName="userCanEdit"
                         #userCanEdit kendoCheckBox />
                  <kendo-label class="k-checkbox-label" [for]="userCanEdit" text="User Can Edit"></kendo-label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col form-group d-flex align-items-center">
                <div class="wrap" *ngIf="trackAdjustmentValue" style="padding-left: 35px;">
                  <input type="checkbox" id="affectsMarginCalc" formControlName="affectsMarginCalc" #affectsMarginCalc
                         kendoCheckBox />
                  <kendo-label class="k-checkbox-label" [for]="affectsMarginCalc" text="Affects Margin Calculation">
                  </kendo-label>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab *ngIf="priceCalculationForm.get('priceModeOptionID')?.value === 59" [title]="'SQL Script Text'">
        <ng-template kendoTabContent>
          <div class="card p-3" style="background-color:rgb(234, 234, 234)">
            <div class="row">
              <div class="col-12 form-group">
                <kendo-formfield style="width: 100%;">
                  <kendo-label for="sqlScriptText" text="SQL Script"> </kendo-label>
                  <kendo-textarea class="form-control" formControlName="sqlScriptText" rows="15" style="overflow-y:auto; width:100%;"
                                  [readonly]="!isRocktonAdmin" title="Scripts can only be edited by Rockton Support">
                  </kendo-textarea>
                </kendo-formfield>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</form>
