import { Component } from '@angular/core';
import { GroupDescriptor } from '@progress/kendo-data-query';
import { StorageService } from '../../../app/utils/StorageHelper';
import { PriceSchedule } from 'src/app/services/price-schedules/price-schedules.model';
import { PriceSchedulesService } from 'src/app/services/price-schedules/price-schedules.service';

@Component({
  selector: 'app-pricing-tree',
  templateUrl: './pricing-tree.component.html',
  styleUrls: ['./pricing-tree.component.scss'],
})
export class PricingTreeComponent {
  constructor(
    private priceSchedulesService: PriceSchedulesService,
  ) {
  }

  priceSchedules: PriceSchedule[] = [];
  public showDeprecated = (StorageService.ShowDeprecated() == 'true');
  loading: boolean = true;
  query = { PageSize: 100, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };

  public mySelection: string[] = [];
  public gridView: PriceSchedule[];

  defaultGroups: GroupDescriptor[] = [
    {
      field: 'useInDynamicPricing',
      dir: 'desc'
    },
    {
      field: 'dynamicPricingRank',
      dir: 'asc'
    }
  ];

  ngOnInit() {
    this.loading = true;
    this.loadListData();
    this.loading = false;
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.priceSchedulesService.getList(this.query);
      this.priceSchedules = response.body;
      //if (!this.showDeprecated) {
      //  this.priceSchedules = this.priceSchedules.filter(item => item.useInDynamicPricing == true);
      //}
      this.priceSchedules.sort((a, b) => {
        if (a.dynamicPricingRank < b.dynamicPricingRank) return -1;
        if (a.dynamicPricingRank > b.dynamicPricingRank) return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
      this.gridView = this.priceSchedules;
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  onRefresh() {
    this.priceSchedules = [];
    this.gridView = [];
    this.loadListData();
  }
}

