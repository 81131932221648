<kendo-dialog title="Validate Import?"
              (close)="close('cancel')"
              [minWidth]="250"
              [width]="450">
  <p style="margin: 30px; text-align: center;">
    Would you like to validate the import file before importing? This will check for errors and log them to a System Job, allowing you to address errors before importing.
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="close('cancel')" class="btn btn-secondary"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="close('validate')" primary="true" class="btn btn-primary"><span class="k-icon k-i-check-outline"></span>Validate</button>
    <button kendoButton (click)="close('import')" primary="true" class="btn btn-primary"><span class="k-icon k-i-check-outline"></span>Import</button>
  </kendo-dialog-actions>
</kendo-dialog>

