import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NumberSourcesService } from '../../../services/number-sources/number-sources.service';
import { NumberSource, emptyNumberSource } from '../../../services/number-sources/number-sources.model';
import { PriceSchedulesService } from '../../../services/price-schedules/price-schedules.service';
import { PriceSchedule } from '../../../services/price-schedules/price-schedules.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { debounce } from 'lodash';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { PriceScheduleLookupComponent } from '../../price-schedules/price-schedule-lookup/price-schedule-lookup.component';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { StorageService } from 'src/app/utils/StorageHelper';
import { CustomerAttribute } from '../../../services/customer-attributes/customer-attributes.model';
import { CustomerAttributeLookupComponent } from '../../customer-attributes/customer-attribute-lookup/customer-attribute-lookup.component';
import { CustomerAttributesService } from '../../../services/customer-attributes/customer-attributes.service';
import { ItemAttribute } from '../../../services/item-attributes/item-attributes.model';
import { ItemAttributeLookupComponent } from '../../item-attributes/item-attribute-lookup/item-attribute-lookup.component';
import { PriceBookLookupComponent } from '../../price-book/price-book-lookup/price-book-lookup.component';
import { PriceBookService } from '../../../services/price-book/price-book.service';
import { PriceBook } from '../../../services/price-book/price-book.model';
import { DataMapLookupComponent } from '../../data-maps/data-map-lookup/data-map-lookup.component';
import { DataMapsService } from '../../../services/data-maps/data-maps.service';
import { DataMap } from '../../../services/data-maps/data-maps.model';
import { ItemAttributesService } from '../../../services/item-attributes/item-attributes.service';
import { DocumentAttribute } from '../../../services/document-attributes/document-attributes.model';
import { DocumentAttributeLookupComponent } from '../../document-attributes/document-attribute-lookup/document-attribute-lookup.component';
import { DocumentAttributesService } from '../../../services/document-attributes/document-attributes.service';
import { CopyService } from '../../../services/copy/copy.service';
import { PricingFilter } from '../../../services/pricing-filters/pricing-filters.model';
import { PricingFiltersService } from '../../../services/pricing-filters/pricing-filters.service';
import { PricingFilterLookupComponent } from '../../pricing-filters/pricing-filter-lookup/pricing-filter-lookup.component';
import { emptyNumberSourceChild, NumberSourceChild } from '../../../services/number-source-children/number-source-children.model';
import { NumberSourceChildrenService } from '../../../services/number-source-children/number-source-children.service';

enum Filters {
  NumberSourceType,
  PriceSchedule,
  CustomerAttribute,
  ItemAttribute,
  DocumentAttribute,
  PriceBook,
  DataMap,
  SumColumn,
  PricingFilter,
  HistoryDateOption,
}
@Component({
  selector: 'app-number-source-single',
  templateUrl: './number-source-single.component.html',
  styleUrls: ['./number-source-single.component.scss'],
})
export class NumberSourceSingleComponent implements OnInit {
  filters = Filters;
  idOfNumberSource: number | null = null;
  numberSource: NumberSource = emptyNumberSource;
  loading: boolean = true;
  numberSourceForm: FormGroup;
  numberSourceTypes: Lookup[] = [];
  priceBooks: PriceBook[] = [];
  priceSchedules: PriceSchedule[] = [];
  customerAttributes: CustomerAttribute[] = [];
  itemAttributes: ItemAttribute[] = [];
  documentAttributes: DocumentAttribute[] = [];
  dataMaps: DataMap[] = [];
  sumColumns: Lookup[] = [];
  pricingFilters: PricingFilter[] = [];
  historyDateOptions: Lookup[] = [];
  childNumberSources: NumberSource[] = [];
  numberSourceChildren: NumberSourceChild[] = [];

  paginationObject: any = {
    roundingrules: mockedData,
    priceSchedules: mockedData,
  };
  submitted = false;
  @Output() onFormChanged = new EventEmitter<boolean>();
  modalReference: any;
  isFormChanged = false;
  goBackForce = false;
  nextURL = '';
  public isRocktonAdmin: boolean;

  entityTypes: Lookup[] = [];
  min: Date = new Date(2000, 0);
  historyDate: Date = new Date();

  constructor(
    private route: ActivatedRoute,
    private numberSourcesService: NumberSourcesService,
    private pricingFiltersService: PricingFiltersService,
    private priceSchedulesService: PriceSchedulesService,
    private lookupService: LookupsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private snack: MySnackBarService,
    private router: Router,
    private customerAttributesService: CustomerAttributesService,
    private documentAttributesService: DocumentAttributesService,
    private itemAttributesService: ItemAttributesService,
    private priceBooksService: PriceBookService,
    private dataMapsService: DataMapsService,
    private location: Location,
    private copyService: CopyService,
    private numberSourceChildService: NumberSourceChildrenService,
  ) {
    this.route.params.subscribe(params => {
      if (params.id && params.id === 'new') {
        this.numberSource = emptyNumberSource;
        this.idOfNumberSource = null;
        this.submitted = false;
        this.updateForm();
        this.buildForm();
        return;
      }
      this.idOfNumberSource = params.id;
      this.loadData();
    });

    this.priceScheduleSearch = debounce(
      this.priceScheduleSearch, 400,
      {}
    );
  }

  ngOnInit(): void {
    this.isRocktonAdmin = StorageService.IsRocktonAdmin();
    this.buildForm();
    this.updateForm();
  }

  buildForm() {
    this.numberSourceForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      description: new FormControl(''),
      numberSourceTypeID: new FormControl('', [Validators.required]),
      amount: new FormControl(''),
      priceBookID: new FormControl(''),
      useDefaultCurrency: new FormControl(''),
      priceScheduleID: new FormControl(''),
      customerAttributeID: new FormControl(''),
      documentAttributeID: new FormControl(''),
      itemAttributeID: new FormControl(''),
      dataMapID: new FormControl(''),

      sumColumnID: new FormControl(''),
      pricingFilterID: new FormControl(''),
      historyDateOptionID: new FormControl(''),
      historyDateValue: new FormControl(''),
      historyDate: new FormControl(''),
      convertToUofM: new FormControl(''),
      convertToUofMName: new FormControl(''),
      useExtendedValue: new FormControl(''),
      useHighest: new FormControl(''),

    });
    this.getnumberSourceTypes();
    this.getSumColumns();
    this.getHistoryDateOptions();
    this.numberSourceForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMNumberSource", this.numberSource.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async onLookup_CustomerAttribute() {
    const modalRef = this.modalService.open(CustomerAttributeLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "CustomerAttributeDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: CustomerAttribute = receivedEntry;
      this.customerAttributes = [];
      this.customerAttributes.push(recordSelected);
      this.numberSourceForm.patchValue({ ['customerAttributeID']: receivedEntry.id });
      this.numberSource.customerAttribute = receivedEntry;
      //this.selectedCustomerAttribute = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_DocumentAttribute() {
    const modalRef = this.modalService.open(DocumentAttributeLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "DocumentAttributeDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: DocumentAttribute = receivedEntry;
      this.documentAttributes = [];
      this.documentAttributes.push(recordSelected);
      this.numberSourceForm.patchValue({ ['documentAttributeID']: receivedEntry.id });
      this.numberSource.documentAttribute = receivedEntry;
      //this.selectedDocumentAttribute = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_ItemAttribute() {
    const modalRef = this.modalService.open(ItemAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: ItemAttribute = receivedEntry;
      this.itemAttributes = [];
      this.itemAttributes.push(recordSelected);
      this.numberSourceForm.patchValue({ ['itemAttributeID']: receivedEntry.id });
      this.numberSource.itemAttribute = receivedEntry;
      //this.selectedItemAttribute = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_PriceBook() {
    const modalRef = this.modalService.open(PriceBookLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PriceBook = receivedEntry;
      this.priceBooks = [];
      this.priceBooks.push(recordSelected);
      this.numberSourceForm.patchValue({ ['priceBookID']: receivedEntry.id });
      this.numberSource.priceBook = receivedEntry;
      //this.selectedPriceBook = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_PricingFilter() {
    const modalRef = this.modalService.open(PricingFilterLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PricingFilter = receivedEntry;
      this.pricingFilters = [];
      this.pricingFilters.push(recordSelected);
      this.numberSourceForm.patchValue({ ['pricingFilterID']: receivedEntry.id });
      this.numberSource.pricingFilter = receivedEntry;
      //this.selectedPricingFilter = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_PriceSchedule() {
    const modalRef = this.modalService.open(PriceScheduleLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PriceSchedule = receivedEntry;
      this.priceSchedules = [];
      this.priceSchedules.push(recordSelected);
      this.numberSourceForm.patchValue({ ['priceScheduleID']: receivedEntry.id });
      this.numberSource.priceSchedule = receivedEntry;
      //this.selectedPriceSchedule = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_DataMap() {
    const modalRef = this.modalService.open(DataMapLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: DataMap = receivedEntry;
      this.dataMaps = [];
      this.dataMaps.push(recordSelected);
      this.numberSourceForm.patchValue({ ['dataMapID']: receivedEntry.id });
      this.numberSource.dataMap = receivedEntry;
      //this.selectedDataMap = receivedEntry;
      modalRef.close('test');
    });
  }

  get f() {
    return this.numberSourceForm.controls;
  }

  updateForm() {
    this.numberSourceForm.patchValue({ ...this.numberSource });
    this.isFormChanged = false;
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.numberSource.id);
    await this.numberSourcesService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/number-sources']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }


  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.numberSource && this.numberSource.id) {
      return false;
    }
    else {
      return true;
    }
    //return !this.currency.id;
  }

  onClickBack(): void {
    this.location.back();
  }

  onClickSave() {
    this.submit(null);
  }

  onClickSaveClose() {
    this.submit("Close");
  }

  onClickSaveNew() {
    this.submit("New");
  }

  async onClickAddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/number-source/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/number-source/new']);
      });
    }
    //this.router.navigate(['/price/number-source/new'], { replaceUrl: true });
  }

  async submit(action) {
    this.numberSourceForm.markAllAsTouched();
    this.submitted = true;
    if (this.numberSourceForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.numberSource,
      ...this.numberSourceForm.value
    };

    // Check to see if Number Source Name already exists.
    if ((await this.checkForExistingName()) === true) {
      this.snack.openSnackBar(
        'Number Source Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.numberSource.id) {
        try {
          const response: any = await this.numberSourcesService.update(
            this.numberSource.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/number-sources']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/number-source/new']);
              });
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      } else {
        try {
          const response: any = await this.numberSourcesService.create(
            this.numberSourceForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.numberSource = response.body;
            this.snack.openSnackBar(
              'Record saved successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/number-sources']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/number-source/new']);
              });
            }
            else {
              this.numberSource = response.body;
              this.router.navigate(['/price/number-source/', this.numberSource.id]);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
    }
    this.isFormChanged = false;
  }

  async getnumberSourceTypes() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'NumberSourceType';
    const params = { Filter_OptionSet, PageSize };
    this.numberSourceTypes = await this.lookupService.getList(params);
    return this.numberSourceTypes;
  }

  async getSumColumns() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'SumColumn';
    const params = { Filter_OptionSet, PageSize };
    this.sumColumns = await this.lookupService.getList(params);
    return this.sumColumns;
  }

  async getHistoryDateOptions() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'HistoryDate';
    const params = { Filter_OptionSet, PageSize };
    this.historyDateOptions = await this.lookupService.getList(params);
    return this.historyDateOptions;
  }

  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.numberSourceForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingNumberSources: NumberSource[] = await (
      await this.numberSourcesService.getList(params)
    ).body;
    if (existingNumberSources.length > 0) {
      if (this.numberSource.id) {
        for (const key in existingNumberSources) {
          const currentNumberSource: NumberSource = existingNumberSources[key];
          if (currentNumberSource.id !== this.numberSource.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async getNumberSourceById() {
    if (this.idOfNumberSource) {
      return await this.numberSourcesService.getById(this.idOfNumberSource);
    } else {
      return emptyNumberSource;
    }
  }

  async getPriceFilterById(id) {
    return await this.pricingFiltersService.getById(id);
  }

  async getPriceScheduleById(id) {
    return await this.priceSchedulesService.getById(id);
  }

  async getCustomerAttributeById(id) {
    return await this.customerAttributesService.getById(id);
  }

  async getDocumentAttributeById(id) {
    return await this.documentAttributesService.getById(id);
  }

  async getItemAttributeById(id) {
    return await this.itemAttributesService.getById(id);
  }

  async getPriceBookById(id) {
    return await this.priceBooksService.getById(id);
  }

  async getPricingFilterById(id) {
    return await this.pricingFiltersService.getById(id);
  }

  async getDataMapById(id) {
    return await this.dataMapsService.getById(id);
  }

  async loadData() {
    this.loading = true;
    try {
      if (this.idOfNumberSource) {
        this.numberSource = await Promise.resolve(this.getNumberSourceById());

        if (this.numberSource.customerAttributeID) {
          const currentCustomerAttribute: CustomerAttribute = await Promise.resolve(this.getCustomerAttributeById(this.numberSource.customerAttributeID));
          this.customerAttributes.push(currentCustomerAttribute);
        }

        if (this.numberSource.documentAttributeID) {
          const currentDocumentAttribute: DocumentAttribute = await Promise.resolve(this.getDocumentAttributeById(this.numberSource.documentAttributeID));
          this.documentAttributes.push(currentDocumentAttribute);
        }

        if (this.numberSource.itemAttributeID) {
          const currentItemAttribute: ItemAttribute = await Promise.resolve(this.getItemAttributeById(this.numberSource.itemAttributeID));
          this.itemAttributes.push(currentItemAttribute);
        }

        if (this.numberSource.priceBookID) {
          const currentPriceBook: PriceBook = await Promise.resolve(this.getPriceBookById(this.numberSource.priceBookID));
          this.priceBooks.push(currentPriceBook);
        }

        if (this.numberSource.pricingFilterID) {
          const currentPricingFilter: PricingFilter = await Promise.resolve(this.getPricingFilterById(this.numberSource.pricingFilterID));
          this.pricingFilters.push(currentPricingFilter);
        }

        if (this.numberSource.priceScheduleID) {
          const currentPriceSchedule: PriceSchedule = await Promise.resolve(this.getPriceScheduleById(this.numberSource.priceScheduleID));
          this.priceSchedules.push(currentPriceSchedule);
        }

        //Because of a circular reference, Data Map must be handled/displayed specially, as the name/id entity does not exist
        if (this.numberSource.dataMapID) {
          this.getDataMapById(this.numberSource.dataMapID).then(currentDataMap => {
            this.dataMaps.push(currentDataMap);
            this.numberSourceForm.patchValue({ dataMapID: currentDataMap.id });
            this.numberSource.dataMap = currentDataMap;
            this.numberSourceForm.get('dataMapID').updateValueAndValidity();
            this.updateForm();
          });
        }

        if (this.numberSource.historyDate) {
          this.numberSource.historyDate = new Date(this.numberSource.historyDate);
        }
        //if (this?.numberSource?.convertToUofM) {
        //  this.convertToUofMValue = this.numberSource.convertToUofM;
        //}
      }
      this.getnumberSourceTypes();
      this.getSumColumns();
      this.getHistoryDateOptions();
      this.updateForm();
    } catch (e) {
    } finally {
      this.loading = false;
    }
    if (this?.numberSource?.numberSourceType != null) {
      this.numberSourceTypeChange(this.numberSource.numberSourceType.id);
    }

    this.isFormChanged = false;
  }

  async priceScheduleSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getPriceSchedules({ SearchTerm });
  }

  async getPriceSchedules(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.priceSchedulesService.getList({ ...params, PageSize });
      this.priceSchedules = resp.body;
      this.paginationObject.priceSchedules = getPaginationHeader(
        resp.headers,
      );
    } catch (e) {
      this.priceSchedules = [];
    }
    return this.priceSchedules;
  }

  onSelectBoxChanged(id: number, key: keyof NumberSource, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.numberSource[key] = item;
    if (key == "numberSourceType") {
      this.numberSourceTypeChange(id);
    }
    //else if (key == "entityID") {
    //  this.entityTypeChange(id);
    //}
    //if (key === 'priceBookID') {
    //  this.idOfPriceBook = id;
    //}
  }

  convertToUofMChange() {
    if (this?.f?.convertToUofM) {
      if (this.f.convertToUofM.value == true) {
        this.f.convertToUofMName.setValidators(Validators.required);
        this.f.convertToUofMName.updateValueAndValidity();
      }
      else {
        this.numberSourceForm.patchValue({ ['convertToUofMName']: null });
        this.f.convertToUofMName.clearValidators();
        this.f.convertToUofMName.updateValueAndValidity();
      }
    }
  }

  numberSourceTypeChange(numberSourceTypeID) {
    this.numberSourceForm.patchValue({ ['priceBookID']: null });
    this.f.priceBookID.clearValidators();
    this.f.priceBookID.updateValueAndValidity();
    //this.numberSourceForm.patchValue({ ['pricingFilterID']: null });
    //this.f.pricingFilterID.clearValidators();
    //this.f.pricingFilterID.updateValueAndValidity();
    this.numberSourceForm.patchValue({ ['priceScheduleID']: null });
    this.f.priceScheduleID.clearValidators();
    this.f.priceScheduleID.updateValueAndValidity();
    this.numberSourceForm.patchValue({ ['customerAttributeID']: null });
    this.f.customerAttributeID.clearValidators();
    this.f.customerAttributeID.updateValueAndValidity();
    this.numberSourceForm.patchValue({ ['documentAttributeID']: null });
    this.f.documentAttributeID.clearValidators();
    this.f.documentAttributeID.updateValueAndValidity();
    this.numberSourceForm.patchValue({ ['itemAttributeID']: null });
    this.f.itemAttributeID.clearValidators();
    this.f.itemAttributeID.updateValueAndValidity();
    this.numberSourceForm.patchValue({ ['dataMapID']: null });
    this.f.dataMapID.clearValidators();
    this.f.dataMapID.updateValueAndValidity();

    switch (numberSourceTypeID) {
      case 165://NumberSourceType	Fixed Amount
        break;
      case 166://NumberSourceType	Item List Price
        break;
      case 167://NumberSourceType	Item Cost
        break;
      case 168://NumberSourceType	Value from ERP
        break;
      case 169://NumberSourceType	Price Book
        this.f.priceBookID.setValidators(Validators.required);
        this.f.priceBookID.updateValueAndValidity();
        break;
      case 170://NumberSourceType	Price Schedule
        this.f.priceScheduleID.setValidators(Validators.required);
        this.f.priceScheduleID.updateValueAndValidity();
        break;
      case 171://NumberSourceType	Customer Attribute
        this.f.customerAttributeID.setValidators(Validators.required);
        this.f.customerAttributeID.updateValueAndValidity();
        break;
      case 172://NumberSourceType	Item Attribute
        this.f.itemAttributeID.setValidators(Validators.required);
        this.f.itemAttributeID.updateValueAndValidity();
        break;
      case 173://NumberSourceType	Document Attribute
        this.f.documentAttributeID.setValidators(Validators.required);
        this.f.documentAttributeID.updateValueAndValidity();
        break;
      case 174://NumberSourceType	Data Map
        this.f.dataMapID.setValidators(Validators.required);
        this.f.dataMapID.updateValueAndValidity();
        break;
      case 175://NumberSourceType	Historical Sales
        break;
      case 176://NumberSourceType	Previous Document Lines
        break;
      case 177://NumberSourceType	All Document Lines
        break;

    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.NumberSourceType:
        {
          const Filter_OptionSet = 'NumberSourceType';
          const params = { SearchTerm, PageSize, Filter_OptionSet };
          this.numberSourceTypes = await this.lookupService.getList(params);
        }
        break;
      case Filters.SumColumn:
        {
          const Filter_OptionSet = 'SumColumn';
          const params = { SearchTerm, PageSize, Filter_OptionSet };
          this.numberSourceTypes = await this.lookupService.getList(params);
        }
        break;
      case Filters.HistoryDateOption:
        {
          const Filter_OptionSet = 'HistoryDateOption';
          const params = { SearchTerm, PageSize, Filter_OptionSet };
          this.numberSourceTypes = await this.lookupService.getList(params);
        }
        break;
      case Filters.CustomerAttribute:
        this.customerAttributes = await (await this.customerAttributesService.getList(params)).body;
        break;
      case Filters.DocumentAttribute:
        this.documentAttributes = await (await this.documentAttributesService.getList(params)).body;
        break;
      case Filters.ItemAttribute:
        this.itemAttributes = await (await this.itemAttributesService.getList(params)).body;
        break;
      case Filters.PriceSchedule:
        this.priceSchedules = await (await this.priceSchedulesService.getList(params)).body;
        break;
      case Filters.PriceBook:
        this.priceBooks = await (await this.priceBooksService.getList(params)).body;
        break;
      case Filters.PricingFilter:
        this.pricingFilters = await (await this.pricingFiltersService.getList(params)).body;
        break;
      case Filters.DataMap:
        this.dataMaps = await (await this.dataMapsService.getList(params)).body;
        break;
      case Filters.PricingFilter:
        this.pricingFilters = await (await this.pricingFiltersService.getList(params)).body;
        break;
      default:
        break;
    }
  }

  async onLookup_priceSchedule() {
    const modalRef = this.modalService.open(PriceScheduleLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'PriceScheduleModalClass',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "PriceScheduleDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PriceSchedule = receivedEntry;
      this.priceSchedules = [];
      this.priceSchedules.push(recordSelected);
      this.numberSourceForm.patchValue({ ['priceScheduleID']: receivedEntry.id });
      this.numberSource.priceSchedule = receivedEntry;
      modalRef.close('test');
    });
  }

  async onSelectChildNumberSource(event, arr) {
    const newPSC: NumberSourceChild = emptyNumberSourceChild;
    newPSC.numberSourceID = this.idOfNumberSource;
    newPSC.childNumberSourceID = event.id;
    newPSC.rank = this.numberSourceChildren.length + 1;
    // Remove the ID since this is a create.
    delete newPSC.id;
    try {
      // Create Price Schedule Child record.
      const response: any = await this.numberSourceChildService.create(newPSC);
      const status: any = response.status;
      if (status === 201) {
        // Refresh Grid
        this.getChildNumberSources(null);
      }
    } catch (e) {
      const err = e.error();
    }
  }

  async getChildNumberSources(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.numberSourceChildService.getList({ Filter_NumberSourceID: this.idOfNumberSource, ...params, PageSize });
      this.numberSourceChildren = resp.body;
      this.childNumberSources = this.numberSourceChildren.map((psc) => {
        return <NumberSource>{
          id: psc.numberSource.id,
          name: psc.numberSource.name,
          numberSourceChildID: psc.id
        };
      });
      //this.pagination_childNumberSources = getPaginationHeader(
      //  // @ts-ignore
      //  resp.headers,
      //);
    } catch (e) {
      this.childNumberSources = [];
    }
    return this.childNumberSources;
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

}
