import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceBookService } from '../../../services/price-book/price-book.service';
import { PriceBook } from '../../../services/price-book/price-book.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { GridDataResult, PageChangeEvent, GridComponent, ExcelComponent } from '@progress/kendo-angular-grid';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service'
import { StorageService } from '../../../../app/utils/StorageHelper';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { CopyService } from '../../../services/copy/copy.service';

@Component({
  selector: 'app-price-book-list',
  templateUrl: './price-book-list.component.html',
  styleUrls: ['./price-book-list.component.scss'],
})
export class PriceBookListComponent implements OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private priceBooksService: PriceBookService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private sideNavService: SideNavService,
    private notificationHelper: NotificationHelper,
    private copyService: CopyService
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input() loading: boolean = true;
  @Input() priceBooks: PriceBook[] = [];
  @Input() query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: PriceBook[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Name',
      field: 'name',
      width: 150,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Description',
      field: 'description',
      width: 200,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Optional Filtering Attribute',
      field: 'entityType.name',
      width: 100,
      orderIndex: 2,
      hidden: false,
    },
    {
      title: 'Customer Attribute',
      field: 'customerAttribute.name',
      width: 200,
      orderIndex: 3,
      hidden: true,
    },
    {
      title: 'Item Attribute',
      field: 'itemAttribute.name',
      width: 200,
      orderIndex: 4,
      hidden: true,
    },
    {
      title: 'Document Attribute',
      field: 'documentAttribute.name',
      width: 200,
      orderIndex: 5,
      hidden: true,
    },
    {
      title: 'Delete Lock',
      field: 'deleteLock',
      width: 75,
      orderIndex: 6,
      hidden: false,
    },
  ];
  columnsConfig: ColumnSettings[];

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    const response: any = await this.priceBooksService.getList({
      Filter_DeleteLock: true, ...this.query
    });
    const lockedPriceBooks = response.body;
    const lockedIds = lockedPriceBooks.map(pb => pb.id);
    const overlap = new Set(ids.filter(id => lockedIds.includes(id)));
    if (overlap.size > 0) {
      this.snack.openSnackBar(
        `Records with a Delete Lock cannot be deleted.`,
        '',
        true,
        'Error',
        'alert-danger'
      );
      return;
    }

    await this.priceBooksService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  async onClickCopy() {
    try {
      const ids = [];
      this.mySelection.forEach(async (value) => {
        const response: any = await this.copyService.performCopy(null, "RPMPriceBook", Number(value));
        const status: any = response.status;
        if (status === 200) {
          this.snack.openSnackBar(
            'Completed Copy successfully!' + response.body,
            '',
            false,
            'Success',
            'alert-success',
          );
          this.loadListData();
        }
      });
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = 0;  //no siblings
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) { 
        excelComponent.fetchData = this.allData; //mhr unclear what this does
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }

  async onClick_ExportOLD(grid: GridComponent, excelComponent: ExcelComponent) {
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total; 
    modalRef.componentInstance.confirmReImport.subscribe(() => { //technical style
      modalRef.close();
      this.loading = true;
      this.exportAll = true;
      excelComponent.fetchData = this.allData;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.confirmExport.subscribe(() => { //report style
      modalRef.close();
      this.loading = true;
      this.exportAll = false;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });

  }

  onResetGridClick() {
    StorageService.removeColumnSettings('PriceBookList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('PriceBookList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('PriceBookList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('PriceBookList_Config', this.columnsConfig);
  }

  ngOnInit(): void {
    this.loadListData();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {          
          this.gridClassName = "DefaultGrid"
        }
        else {
          this.gridClassName = "FullScreenGrid"
        }
      },
    );

  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  async onFileChange(evt: any) {
    await this.importsService.onFileChange(evt, 'RPMPriceBook');
    this.fileUploader.nativeElement.value = null;
    this.loadListData();
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      if (this.mode === 'lookup') {
        this.onSelectSingle.emit(this.clickedRowItem);
      }
      else {
        this.router.navigate(['/price/price-book/' + this.clickedRowItem.id]);
      }
    }
  }

  async performImport(inputXML) {
    try {
      const response: any = await this.importsService.performImport(inputXML, 'RPMPriceBook');
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Import successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
        this.loadListData();
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = { PageNumber: 1, PageSize: this.query.PageSize, SortTerm: 'name', SearchTerm: '' };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }
  edit(id: number) {
    this.router.navigate([`/price/price-book/${id}`]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.priceBooksService.getExportList(this.query);
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.priceBooksService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(
          err.error,
          '',
          true,
          'Error',
          'alert-danger',
        );
      });

    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }
  async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.priceBooksService.getList(this.query);
      this.priceBooks = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.priceBooks,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }
}
