import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceScheduleLookupComponent } from '../../../price/price-schedules/price-schedule-lookup/price-schedule-lookup.component';
import { PriceScheduleChildrenService } from '../../../services/price-schedule-children/price-schedule-children.service';
import { PriceScheduleChild, emptyPriceScheduleChild } from '../../../services/price-schedule-children/price-schedule-children.model';
import { PriceSchedule } from '../../../services/price-schedules/price-schedules.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader, } from 'src/app/utils/getPaginationHeader';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GridDataResult, PageChangeEvent, GridComponent, ExcelComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, CompositeFilterDescriptor, FilterDescriptor, } from '@progress/kendo-data-query';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';

const tableRow = node => node.tagName.toLowerCase() === 'tr';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};

@Component({
  selector: 'app-price-schedule-child-list',
  templateUrl: './price-schedule-child-list.component.html',
  styleUrls: ['./price-schedule-child-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
  .k-grid tr.dragging {
      background-color: #f45c42;
  };
`]
})
export class PriceScheduleChildListComponent
  implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private priceScheduleChildService: PriceScheduleChildrenService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private notificationHelper: NotificationHelper
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input() priceScheduleID: number;
  @Input() loading: boolean = true;
  @Input() childPriceSchedules: PriceSchedule[] = [];  //a list of Price Schedules Children filtered to this Price Schedule
  @Input() query = {
    PageSize: 25,
    SortTerm: 'rank',
    PageNumber: 1,
    SearchTerm: '',
  };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  priceScheduleChildren: PriceScheduleChild[] = [];

  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public clickedRowItem;
  public gridClassName: string = "PriceScheduleChildSubgridClass";
  public filterable: boolean;

  public sort: SortDescriptor[] = [
    {
      field: 'rank',
      dir: 'asc',
    },
    {
      field: 'priceScheduleChildName',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Rank',
      field: 'rank',
      width: 100,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Child Price Schedule Name',
      field: 'priceScheduleChildName',
      width: 250,
      orderIndex: 1,
      hidden: false,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.priceScheduleID && !changes.priceScheduleID.firstChange) {
      // We now have an Parent ID so we can change the tooltip.
      document.getElementById('btnAdd').setAttribute('title', 'Add New');
    }
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.priceScheduleChildService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = 0;  //no siblings
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      console.log(options.optTechnicalStyle, options.optDescColumns, options.optRowID, options.optSiblingRecords);
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) {
        excelComponent.fetchData = this.allData;
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }

  async onClick_ExportOLD(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();

    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.confirmReImport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = true;
      excelComponent.fetchData = this.allData;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.confirmExport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = false;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });

  }

  onResetGridClick() {
    StorageService.removeColumnSettings('PriceScheduleChildList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('PriceScheduleChildList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('PriceScheduleChildList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('PriceScheduleChildList_Config', this.columnsConfig);
  }

  ngAfterViewInit(): void {
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;
    this.loadListData();
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  async loadListData() {
    this.priceScheduleChildren = [];
    this.gridView = {
      data: this.priceScheduleChildren,
      total: 0,
    };

    this.loading = true;
    this.query.PageSize = StorageService.PageSize();
    try {
      if (this.priceScheduleID) {
        document.getElementById('btnAdd').setAttribute('title', 'Add New');
        const PageSize = StorageService.PageSize() ?? 50;
        const response: any = await this.priceScheduleChildService.getList({
          Filter_PriceScheduleID: this.priceScheduleID,
          ...this.query, PageSize
        });
        this.priceScheduleChildren = response.body;
        this.childPriceSchedules = this.priceScheduleChildren.map((psc) => {
          return <PriceSchedule>{
            id: psc.priceSchedule.id,
            name: psc.priceSchedule.name,
            rank: psc.rank,
            priceScheduleChildID: psc.id,
            priceScheduleChildName: psc.childPriceSchedule.name,
            childPriceScheduleID: psc.childPriceSchedule.id,
            createdOn: psc.priceSchedule.createdOn,
            createdBy: psc.priceSchedule.createdBy,
            modifiedOn: psc.priceSchedule.modifiedOn,
            modifiedBy: psc.priceSchedule.modifiedBy
          };
        });
        this.pagination = getPaginationHeader(response.headers);
        this.gridView = {
          data: this.childPriceSchedules,
          total: this.pagination.TotalCount,
        };
      }
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick(e) {
    let test = this.mySelection;
    if (this.clickedRowItem && e?.target?.localName != null && e.target.localName != "button") {
      this.router.navigate([`/price/price-schedule/${this.clickedRowItem.childPriceSchedule.id}`]); //mhr
    }
  }

  async saveAllPriceSchedules() {  //if you delete this proc, it throws an error. Not sure what's calling it.  /mhr
    // @ts-ignore
    let allRows: any[] = this.myGrid.data.data;
    let rank: number = 1;
    //for (let index = 0; index < allRows.length; index++) {
    //  const row = allRows[index];
    //  let currentPriceScheduldChild: PriceScheduleChild = await Promise.resolve(await this.priceScheduleChildService.getById(row.priceScheduleChildID));

    //  currentPriceScheduldChild.rank = rank;
    //  row.rank = rank;
    //  this.save(currentPriceScheduldChild);
    //  rank++;
    //}
  }

  async save(priceScheduleChild) {
    try {
      const response: any = await this.priceScheduleChildService.update(
        priceScheduleChild.id,
        priceScheduleChild,
      );
      const status: any = response.status;
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }

  }

  onRefresh() {
    this.loadListData();
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }

  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.priceScheduleChildService.getExportList({
      Filter_PriceScheduleID: this.priceScheduleID,
      ...this.query,
    });
  };

  edit() {
    console.log(this.clickedRowItem.priceScheduleChildID);
    const newurl = `/price/price-schedule/${this.clickedRowItem.childPriceScheduleID}`;
    //this.router.navigate([`/price/price-schedule/${this.clickedRowItem.priceScheduleChildID}`]);  //mhr
    console.log(newurl);
    this.router.navigate([newurl]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async onLookup_PriceScheduleChildren() {
    const modalRef = this.modalService.open(PriceScheduleLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'PriceChildModalClass',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "PriceChildDialogGrid";
    modalRef.componentInstance.parentPriceScheduleID = this.priceScheduleID; //filters search list
    modalRef.componentInstance.onSelectSingle.subscribe(async (receivedEntry) => {
      const newPSC: PriceScheduleChild = emptyPriceScheduleChild;
      newPSC.priceScheduleID = this.priceScheduleID;
      newPSC.childPriceScheduleID = receivedEntry.id;
      newPSC.rank = this.priceScheduleChildren.length + 1;
      // Remove the ID since this is a create.
      delete newPSC.id;
      try {
        // Create Price Schedule Child record.       
        const response: any = await this.priceScheduleChildService.create(newPSC);
        const status: any = response.status;
        if (status === 201) {
          // Refresh Grid
          this.loadListData();
        }
      } catch (e) {
        modalRef.close();
        //console.log(e);
        const errMsg = 'Price Schedule cannot be the same as the Parent Price Schedule, nor can it already exist as a Child.'
        this.snack.openSnackBar(errMsg, '', true, 'Error', 'alert-danger');
      }
      modalRef.close('test');
    });
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.priceScheduleChildService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });

    modal.close();
    this.loadListData();
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  get disableRankBtn() {
    return Object.keys(this.mySelection).length == 0;
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  async ChangeRanks(offset: number) {
    const ids = this.mySelection.map(id => Number(id));
    try {
      for (let id of ids) {
        console.log(id)
        const record = await this.priceScheduleChildService.getById(id);
        const newRank = Math.max(1, record.rank + offset);
        const updatedRecord = { ...record, rank: newRank };
        await this.priceScheduleChildService.update(id, updatedRecord);
      }
      //this.mySelection = [];
    } catch (err) {
      this.notificationHelper.showStatus(err.error ? err.error : 'An error occurred while updating records.', "error");
    }
    await this.loadListData();
  }


}


