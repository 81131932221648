import { Component, OnInit, Input, ViewChildren, ViewEncapsulation, QueryList } from '@angular/core';
import { StorageService } from '../../../app/utils/StorageHelper';
import { PriceScheduleCalculationsService } from '../../services/price-schedule-calc/price-schedule-calc.service';
import { PriceScheduleCalculation } from '../../services/price-schedule-calc/price-schedule-calc.model';
import { PriceScheduleChildrenService } from '../../services/price-schedule-children/price-schedule-children.service';
import { PriceScheduleChild } from '../../services/price-schedule-children/price-schedule-children.model';
import { GridComponent } from "@progress/kendo-angular-grid";
import { NumberSourceChild } from '../../services/number-source-children/number-source-children.model';
import { DataMapValue } from '../../services/data-map-values/data-map-values.model';
import { NumberSourceChildrenService } from '../../services/number-source-children/number-source-children.service';
import { DataMapValuesService } from '../../services/data-map-values/data-map-values.service';

@Component({
  selector: 'app-pricing-tree-branch',
  templateUrl: './pricing-tree-branch.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PricingTreeBranchComponent implements OnInit {
  @ViewChildren(GridComponent) grids: QueryList<GridComponent> = new QueryList<GridComponent>();
  @Input() priceScheduleID: number = null;
  @Input() numberSourceID: number = null;
  @Input() dataMapID: number = null;
  public gridViewSchedCalc: PriceScheduleCalculation[];
  public gridViewSchedChild: PriceScheduleChild[]; //deprecated
  public gridViewNumSrcChild: NumberSourceChild[];
  public griveViewDMV: DataMapValue[];
  constructor(
    private priceScheduleCalculationsService: PriceScheduleCalculationsService,
    private priceScheduleChildrenService: PriceScheduleChildrenService,
    private numberSourceChildrenService: NumberSourceChildrenService,
    private dataMapValuesService: DataMapValuesService,
  ) {
  }
  priceScheduleCalculations: PriceScheduleCalculation[] = [];
  priceScheduleChildren: PriceScheduleChild[] = [];
  numberSourceChildren: NumberSourceChild[] = [];
  dataMapValues: DataMapValue[] = [];
  public showDeprecated = (StorageService.ShowDeprecated() == 'true');
  loading: boolean = true;
  query = { PageSize: 50, SortTerm: '', PageNumber: 1, SearchTerm: '' };

  ngOnInit() {
    this.loading = true;
    if (this.priceScheduleID) this.loadListDataSchedChild();
    if (this.priceScheduleID) this.loadListDataSchedCalc();
    if (this.numberSourceID) this.loadListDataNumSrcChild();
    if (this.dataMapID) this.loadListDataDMV();
    this.loading = false;
  }

  onRefresh() {
    this.ngOnInit();
  }

  public dragData = ({ dragTarget }) => {
    return {
      fromGrid: +dragTarget
        .closest(".k-grid")
        .getAttribute("data-kendo-grid-index"),
      fromIndex: +dragTarget.getAttribute("data-kendo-grid-item-index"),
    };
  };

  public async loadListDataSchedChild() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.priceScheduleChildrenService.getList(this.query);
      this.priceScheduleChildren = response.body;
      const filteredPSC = this.priceScheduleChildren.filter(calc => calc.priceScheduleID == this.priceScheduleID);
      filteredPSC.sort((a, b) => {
        if (a.rank < b.rank) return -1;
        if (a.rank > b.rank) return 1;
      });
      this.gridViewSchedChild = filteredPSC;
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  public async loadListDataSchedCalc() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.priceScheduleCalculationsService.getList(this.query);
      this.priceScheduleCalculations = response.body;
      const filteredPriceScheduleCalcs = this.priceScheduleCalculations.filter(calc => calc.priceScheduleID == this.priceScheduleID);
      filteredPriceScheduleCalcs.sort((a, b) => {
        if (a.rank < b.rank) return -1;
        if (a.rank > b.rank) return 1;
      });
      this.gridViewSchedCalc = filteredPriceScheduleCalcs;
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  public async loadListDataNumSrcChild() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.numberSourceChildrenService.getList(this.query);
      this.numberSourceChildren = response.body;
      const filteredNSC = this.numberSourceChildren.filter(nsc => nsc.numberSourceID == this.numberSourceID);
      filteredNSC.sort((a, b) => {
        if (a.rank < b.rank) return -1;
        if (a.rank > b.rank) return 1;
      });
      this.gridViewNumSrcChild = filteredNSC;
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  public async loadListDataDMV() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.dataMapValuesService.getList(this.query);
      this.dataMapValues = response.body;
      const filteredDMV = this.dataMapValues.filter(dmv => dmv.dataMapID == this.dataMapID);
      this.griveViewDMV = filteredDMV;
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

}
