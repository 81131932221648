<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Price_Calculation.png" alt="Number Source" style="padding-right: 5px;">
  Create / Update Number Source
</div>
<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Back.png" (click)="onClickBack()"
          routerLinkActive="router-link-active" title="Go Back">
    BACK
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save.png" (click)="onClickSave()" title="Save">
    SAVE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save_Close.png" (click)="onClickSaveClose()" title="Save & Close">
    SAVE &
    CLOSE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save_New.png" (click)="onClickSaveNew()" title="Save & New">
    SAVE & NEW
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Add.png" (click)="onClickAddNew()" title="Add New">
    ADD NEW
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">
    COPY
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="showRemovePopUp(areYouSure)"
          title="Delete Record">
    DELETE
  </button>
</kendo-buttongroup>
<form [formGroup]="numberSourceForm">

  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Number Source Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Number Source Name"
                       maxlength="100">
        </kendo-textbox>
        <kendo-formerror>Name is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="numberSourceTypeID" class="font-weight-bold" text="* Number Source Type"></kendo-label>
        <ng-select id="numberSourceTypeID" [multiple]="false" [selectOnTab]="true" formControlName="numberSourceTypeID"
                   [notFoundText]="'Please start typing to search'"
                   [ngClass]="{'is-invalid': submitted && f.numberSourceTypeID?.errors,'w-100': true}"
                   placeholder="Select a Number Source Type" [searchable]="true"
                   (change)="onSelectBoxChanged($event, 'numberSourceType', numberSourceTypes)">
          <ng-option *ngFor="let item of numberSourceTypes" [value]="item.id">
            {{ item.name }}
          </ng-option>
        </ng-select>
        <kendo-formerror>Number Source Type is required</kendo-formerror>
      </kendo-formfield>
    </div>

  </div>

  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="description" text="Description"></kendo-label>
        <kendo-textbox formControlName="description" class="form-control" id="description">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>

  <div class="row">
    <div class="col form-group" *ngIf="numberSourceForm?.get('numberSourceTypeID')?.value === 165">
      <kendo-formfield>
        <kendo-label for="amount" class="font-weight-bold" text="* Amount"></kendo-label>
        <kendo-numerictextbox class="form-control" formControlName="amount" [autoCorrect]="true"
                              [decimals]="5" [format]="'n5'" [ngClass]="{ 'is-invalid': submitted && f.amount.errors }"
                              placeholder="Enter an Amount" [min]="-99999999999999" [max]="99999999999999">
        </kendo-numerictextbox>
        <kendo-formerror>Amount is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <!--
    165	NumberSourceType	Fixed Amount
    166	NumberSourceType	Item List Price
    167	NumberSourceType	Item Cost
    169	NumberSourceType	Price Book
    170	NumberSourceType	Price Schedule
    171	NumberSourceType	Customer Attribute
    172	NumberSourceType	Item Attribute
    173	NumberSourceType	Document Attribute
    174	NumberSourceType	Data Map
    175	NumberSourceType	Historical Sales
    176	NumberSourceType	Previous Document Lines
    177	NumberSourceType	All Document Lines
    178	NumberSourceType	Dynamic Search
    179	NumberSourceType	Current Document Line-->

    <div class="col form-group" *ngIf="numberSourceForm?.get('numberSourceTypeID')?.value === 171">
      <kendo-formfield>
        <kendo-label class="font-weight-bold" for="customerAttributeID" text="* Customer Attribute"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="customerAttributeID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     [notFoundText]="'Please start typing to search'" formControlName="customerAttributeID"
                     placeholder="Select an Attribute" [ngClass]="{'is-invalid': submitted && f.customerAttributeID.errors,'w-100': true}" [searchable]="true"
                     (search)="search($event, filters.CustomerAttribute)" (change)="onSelectBoxChanged($event,'customerAttributeID',customerAttributes)">
            <ng-option *ngFor="let item of customerAttributes" [value]="item.id">{{ item.name }}</ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_CustomerAttribute()" icon="search"></button>
        </span>
        <kendo-formerror>Customer Attribute is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col form-group" *ngIf="numberSourceForm?.get('numberSourceTypeID')?.value === 169">
      <kendo-formfield>
        <kendo-label class="font-weight-bold" for="priceBookID" text="* Price Book"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="priceBookID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     [notFoundText]="'Please start typing to search'" formControlName="priceBookID"
                     placeholder="Select a Price Book" [ngClass]="{'is-invalid': submitted && f.priceBookID.errors,'w-100': true}" [searchable]="true"
                     (search)="search($event, filters.PriceBook)" (change)="onSelectBoxChanged($event,'priceBookID',priceBooks)">
            <ng-option *ngFor="let item of priceBooks" [value]="item.id">{{ item.name }}</ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_PriceBook()" icon="search"></button>
        </span>
        <kendo-formerror>Price Book is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div *ngIf="numberSourceForm?.get('numberSourceTypeID')?.value === 169" class="col-3 form-group d-flex align-items-center" style="padding-top:25px;">
      <div class="wrap" style="padding-right: 10px;">
        <input type="checkbox" id="useDefaultCurrency" formControlName="useDefaultCurrency"
               #useDefaultCurrency kendoCheckBox />
        <kendo-label class="k-checkbox-label" [for]="useDefaultCurrency" text="Use Default Currency"></kendo-label>
      </div>
    </div>

    <div class="col form-group" *ngIf="numberSourceForm?.get('numberSourceTypeID')?.value === 170">
      <kendo-formfield>
        <kendo-label class="font-weight-bold" for="priceScheduleID" text="* Price Schedule"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="priceScheduleID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     [notFoundText]="'Please start typing to search'" formControlName="priceScheduleID"
                     placeholder="Select a Price Schedule" [ngClass]="{'is-invalid': submitted && f.priceScheduleID.errors,'w-100': true}" [searchable]="true"
                     (search)="search($event, filters.PriceSchedule)" (change)="onSelectBoxChanged($event,'priceScheduleID',priceSchedules)">
            <ng-option *ngFor="let item of priceSchedules" [value]="item.id">{{ item.name }}</ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_PriceSchedule()" icon="search"></button>
        </span>
        <kendo-formerror>Price Schedule is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col form-group" *ngIf="numberSourceForm?.get('numberSourceTypeID')?.value === 174">
      <kendo-formfield>
        <kendo-label class="font-weight-bold" for="dataMapID" text="* Data Map"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="dataMapID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     [notFoundText]="'Please start typing to search'" formControlName="dataMapID"
                     placeholder="Select a Data Map" [ngClass]="{'is-invalid': submitted && f.dataMapID.errors,'w-100': true}" [searchable]="true"
                     (search)="search($event, filters.DataMap)" (change)="onSelectBoxChanged($event,'dataMapID',dataMaps)">
            <ng-option *ngFor="let item of dataMaps" [value]="item.id">{{ item.name }}</ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_DataMap()" icon="search"></button>
        </span>
        <kendo-formerror>Data Map is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>

  <!--180	SumColumn	Quantity
  181	SumColumn	Amount
  182	SumColumn	Item Attribute
  183	SumColumn	Document Attribute
  184	SumColumn	Number of Documents-->
  <div class="row" *ngIf="[175, 176, 177, 179].includes(numberSourceForm?.get('numberSourceTypeID')?.value)">
    <div class="col">
      <kendo-formfield>
        <kendo-label for="sumColumnID" class="font-weight-bold" text="* Sum Column"></kendo-label>
        <ng-select id="sumColumnID" [multiple]="false" [selectOnTab]="true" [clearable]="false" formControlName="sumColumnID"
                   [notFoundText]="'Please start typing to search'"
                   [ngClass]="{'is-invalid': submitted && f.sumColumnID?.errors, 'w-100': true }"
                   placeholder="Select a Sum Column" [searchable]="true" appendTo="body"
                   (change)="onSelectBoxChanged($event, 'sumColumnID', sumColumns)">
          <ng-option *ngFor="let item of sumColumns" [value]="item.id">{{ item.name }}</ng-option>
        </ng-select>
        <kendo-formerror>Sum Column is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col form-group d-flex align-items-center" style="padding-top:25px;">
      <div class="wrap" style="padding-right: 10px;">
        <input type="checkbox" id="useExtendedValue" formControlName="useExtendedValue"
               #useExtendedValue kendoCheckBox />
        <kendo-label class="k-checkbox-label" [for]="useExtendedValue" text="Use Extended Value (multiply by Quantity)"></kendo-label>
      </div>
    </div>

  </div>

  <div class="row" style="padding-top:15px;">
    <div class="col form-group" *ngIf="numberSourceForm?.get('numberSourceTypeID')?.value === 172 || numberSourceForm?.get('sumColumnID')?.value === 182">
      <kendo-formfield>
        <kendo-label class="font-weight-bold" for="itemAttributeID" text="* Item Attribute"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="itemAttributeID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     [notFoundText]="'Please start typing to search'" formControlName="itemAttributeID"
                     placeholder="Select an Attribute" [ngClass]="{'is-invalid': submitted && f.itemAttributeID.errors,'w-100': true}" [searchable]="true"
                     (search)="search($event, filters.ItemAttribute)" (change)="onSelectBoxChanged($event,'itemAttributeID',itemAttributes)">
            <ng-option *ngFor="let item of itemAttributes" [value]="item.id">{{ item.name }}</ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_ItemAttribute()" icon="search"></button>
        </span>
        <kendo-formerror>Item Attribute is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col form-group" *ngIf="numberSourceForm?.get('numberSourceTypeID')?.value === 173 || numberSourceForm?.get('sumColumnID')?.value === 183">
      <kendo-formfield>
        <kendo-label class="font-weight-bold" for="documentAttributeID" text="* Document Attribute"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="documentAttributeID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     [notFoundText]="'Please start typing to search'" formControlName="documentAttributeID"
                     placeholder="Select an Attribute" [ngClass]="{'is-invalid': submitted && f.documentAttributeID.errors,'w-100': true}" [searchable]="true"
                     (search)="search($event, filters.DocumentAttribute)" (change)="onSelectBoxChanged($event,'documentAttributeID',documentAttributes)">
            <ng-option *ngFor="let item of documentAttributes" [value]="item.id">{{ item.name }}</ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_DocumentAttribute()" icon="search"></button>
        </span>
        <kendo-formerror>Document Attribute is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>


  <div class="row" *ngIf="[175, 176, 177, 179].includes(numberSourceForm?.get('numberSourceTypeID')?.value)">

    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="pricingFilterID" text="Optional Record Filter"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="pricingFilterID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     [notFoundText]="'Please start typing to search'" formControlName="pricingFilterID"
                     placeholder="Select a Price Schedule" [ngClass]="{'is-invalid': submitted && f.pricingFilterID.errors,'w-100': true}" [searchable]="true"
                     (search)="search($event, filters.PricingFilter)" (change)="onSelectBoxChanged($event,'pricingFilterID',pricingFilters)">
            <ng-option *ngFor="let item of pricingFilters" [value]="item.id">{{ item.name }}</ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_PricingFilter()" icon="search"></button>
        </span>
      </kendo-formfield>
    </div>
  </div>

  <div class="row" *ngIf="[175, 176, 177, 179].includes(numberSourceForm?.get('numberSourceTypeID')?.value)">

    <div class="col-3 form-group d-flex align-items-center" style="padding-top:15px;">
      <div class="wrap" style="padding-right: 10px;">
        <input type="checkbox" id="convertToUofM" (change)="convertToUofMChange()" formControlName="convertToUofM"
               #convertToUofM kendoCheckBox />
        <kendo-label class="k-checkbox-label" [for]="convertToUofM" text="Convert to Unit of Measure"></kendo-label>
      </div>
    </div>

    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="convertToUofMName"
                     [class.font-weight-bold]="numberSourceForm?.get('convertToUofM')?.value"
                     [class.font-weight-normal]="!numberSourceForm?.get('convertToUofM')?.value"
                     text="* Unit of Measure Name">
        </kendo-label>
        <kendo-textbox class="form-control" formControlName="convertToUofMName" placeholder="Enter UofM Name"
                       maxlength="100" [disabled]="numberSourceForm?.get('convertToUofM')?.value === false">
        </kendo-textbox>
        <kendo-formerror>UofM Name is required</kendo-formerror>
      </kendo-formfield>
    </div>

  </div>

  <div class="row" *ngIf="numberSourceForm?.get('numberSourceTypeID')?.value === 175">
    <div class="col">
      <kendo-formfield>
        <kendo-label for="historyDateOption" class="font-weight-bold" text="* History Date Option"></kendo-label>
        <ng-select id="historyDateOptionOption" [multiple]="false" [selectOnTab]="true" [clearable]="false" formControlName="historyDateOptionID"
                   [notFoundText]="'Please start typing to search'"
                   [ngClass]="{'is-invalid': submitted && f.historyDateOptionID?.errors,'w-100': true}"
                   placeholder="Select a History Date Option" [searchable]="true" appendTo="body"
                   (change)="onSelectBoxChanged($event, 'historyDateOptionID', historyDateOptions)">
          <ng-option *ngFor="let item of historyDateOptions" [value]="item.id">
            {{ item.name }}
          </ng-option>
        </ng-select>
        <kendo-formerror>History Date Option is required</kendo-formerror>
      </kendo-formfield>
    </div>

    <!--49	HistoryDate	Since Specific Date
    50	HistoryDate	Last X Days
    51	HistoryDate	Last X Weeks
    52	HistoryDate	Last X Months
    53	HistoryDate	Last X Quarters
    54	HistoryDate	Last X Years-->
    <div class="col" *ngIf="numberSourceForm?.get('historyDateOptionID')?.value != 49">
      <kendo-formfield>
        <kendo-label for="historyDateValue" class="font-weight-bold" text="* History Date Value"></kendo-label>
        <kendo-numerictextbox class="form-control" formControlName="historyDateValue" [maxlength]="4" [min]="1" [decimals]="0" [max]="9999"
                              [format]="'####'" [ngClass]="{ 'is-invalid': submitted && f.historyDateValue.errors }"
                              placeholder="Enter a Value for History Date">
        </kendo-numerictextbox>
        <kendo-formerror>Value is required.</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="col" *ngIf="numberSourceForm?.get('historyDateOptionID')?.value === 49">
      <kendo-formfield>
        <kendo-label for="historyDate" class="font-weight-bold" text="* History Date">
        </kendo-label>
        <kendo-datepicker id="historyDate" class="form-control" formControlName="historyDate" [(value)]="historyDate" [min]="min">
        </kendo-datepicker>
        <kendo-formerror *ngIf="f.historyDate.errors && f.historyDate.errors.required">History Date is required</kendo-formerror>
        <kendo-formerror *ngIf="f.historyDate.errors && f.historyDate.errors.minError">History Date Must be Greater than Jan 1st, 2000</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>

  <div *ngIf="numberSourceForm?.get('numberSourceTypeID')?.value === 178" class="card p-3" style="background-color:rgb(234, 234, 234)">
    <app-number-source-child-list #subgrid [numberSourceID]='idOfNumberSource' mode="view"
                                  class="NumberSourceChildSubgridClass" (onSelectSingle)="onSelectChildNumberSource($event, 'numberSources')">
    </app-number-source-child-list>
    <br />
    <br />
    <br />
  </div>

  <div *ngIf="numberSourceForm?.get('numberSourceTypeID')?.value === 178" class="col form-group d-flex align-items-center" style="padding-top:25px;">
    <div class="wrap" style="padding-right: 10px;">
      <input type="checkbox" id="useHighest" formControlName="useHighest"
             #useHighest kendoCheckBox />
      <kendo-label class="k-checkbox-label" [for]="useHighest" text="For Number Sources with the same Rank, use Highest value instead of Lowest"></kendo-label>
    </div>
  </div>

</form>
