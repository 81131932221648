import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataMapValuesService } from '../../../services/data-map-values/data-map-values.service';
import { DataMapValue, emptyDataMapValue } from '../../../services/data-map-values/data-map-values.model';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { DataMap } from '../../../services/data-maps/data-maps.model';
import { DataMapsService } from '../../../services/data-maps/data-maps.service';
import { StorageService } from '../../../utils/StorageHelper';
import { Customer } from '../../../services/customers/customers.model';
import { CustomersService } from '../../../services/customers/customers.service';
import { CustomerLookupComponent } from '../../customers/customer-lookup/customer-lookup.component';
import { Item } from '../../../services/items/items.model';
import { ItemsService } from '../../../services/items/items.service';
import { ItemLookupComponent } from '../../items/item-lookup/item-lookup.component';
import { Location } from '../../../services/locations/locations.model';
import { LocationsService } from '../../../services/locations/locations.service';
import { LocationLookupComponent } from '../../locations/location-lookup/location-lookup.component';
import { Currency } from '../../../services/currencies/currencies.model';
import { CurrenciesService } from '../../../services/currencies/currencies.service';
import { CurrencyLookupComponent } from '../../currencies/currency-lookup/currency-lookup.component';
import { NumberSource } from '../../../services/number-sources/number-sources.model';
import { NumberSourcesService } from '../../../services/number-sources/number-sources.service';
import { NumberSourceLookupComponent } from '../../number-sources/number-source-lookup/number-source-lookup.component';
import { UofM } from '../../../services/uofms/uofms.model';
import { UofMsService } from '../../../services/uofms/uofms.service';
import { UofMLookupComponent } from '../../uofms/uofm-lookup/uofm-lookup.component';
import { PriceCalculation } from '../../../services/price-calculations/price-calculations.model';
import { PriceCalculationsService } from '../../../services/price-calculations/price-calculations.service';
import { PriceCalculationLookupComponent } from '../../price-calculations/price-calculation-lookup/price-calculation-lookup.component';
enum Filters {
  Customer = 1,
  Item,
  UofM,
  Location,
  Currency,
  NumberSource,
  PriceCalculation
}
@Component({
  selector: 'app-data-map-value-single',
  templateUrl: './data-map-value-single.component.html',
  styleUrls: ['./data-map-value-single.component.scss'],
})
export class DataMapValueSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfDataMapValue: number | null = null;
  @Input() idOfDataMap: number | null = null;
  @Input() attributeCount: number = 1;
  @Output() onFormChanged = new EventEmitter<Boolean>();
  @Output() onFormSaved = new EventEmitter<Boolean>();
  @Output() onFormCancelled = new EventEmitter<Boolean>();
  filters = Filters;
  dataMapValue: DataMapValue = emptyDataMapValue;
  loading: boolean = true;
  dataMapValueForm: FormGroup;
  submitted = false;
  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';
  parentDataMap: DataMap | null = null;
  customers: Customer[] = [];
  items: Item[] = [];
  uofMs: UofM[] = [];
  locations: Location[] = [];
  currencies: Currency[] = [];
  numberSources: NumberSource[] = [];
  priceCalculations: PriceCalculation[] = [];
  dateFrom: Date; // = new Date();
  dateTo: Date; // = new Date();

  constructor(
    private route: ActivatedRoute,
    private dataMapValuesService: DataMapValuesService,
    private dataMapsService: DataMapsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,
    private notificationHelper: NotificationHelper,
    private customersService: CustomersService,
    private itemsService: ItemsService,
    private uofMsService: UofMsService,
    private locationsService: LocationsService,
    private currenciesService: CurrenciesService,
    private numberSourcesService: NumberSourcesService,
    private priceCalculationsService: PriceCalculationsService,
  ) {
  }

  ngOnInit(): void {
    this.getParentDataMap();
    if (this.idOfDataMapValue) {
      this.loadData();
    }
    else {
      this.dataMapValue = emptyDataMapValue;
      this.dataMapValue.dataMapID = this.idOfDataMap;
      this.idOfDataMapValue = null;
      this.submitted = false;
    }
    if (this.idOfDataMap) {
      this.dataMapValue.dataMapID = this.idOfDataMap;
    }
    this.buildForm();
    this.updateForm();

  }

  async buildForm() {
    this.dataMapValueForm = new FormGroup({
      dataMapID: new FormControl(),
      customerID: new FormControl(),
      itemID: new FormControl(),
      uofMID: new FormControl(),
      locationID: new FormControl(),
      currencyID: new FormControl(),
      dateFrom: new FormControl(),
      dateTo: new FormControl(),
      attribute1Value: new FormControl(''),
      attribute2Value: new FormControl(''),
      attribute3Value: new FormControl(''),
      amount: new FormControl(),
      numberSourceID: new FormControl(),
      priceCalculationID: new FormControl(),
    });
    this.dataMapValueForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.dataMapValueForm.controls;
  }
  updateForm() {
    //this.dataMapValueForm.patchValue({ ...this.dataMapValue });
    //this.isFormChanged = false;
    this.dataMapValueForm.patchValue({
      ...this.dataMapValue,
      dateFrom: this.dataMapValue.dateFrom ? new Date(this.dataMapValue.dateFrom) : null,
      dateTo: this.dataMapValue.dateTo ? new Date(this.dataMapValue.dateTo) : null
    });
    this.dateFrom = this.dataMapValue.dateFrom;
    this.dateTo = this.dataMapValue.dateTo;
    this.isFormChanged = false;
  }

  async submit(action) {
    this.dataMapValueForm.markAllAsTouched();
    this.submitted = true;
    if (this.dataMapValueForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.dataMapValue,
      ...this.dataMapValueForm.value,
    };

    if (this.dataMapValue.id) {
      try {
        const response: any = await this.dataMapValuesService.update(
          this.dataMapValue.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatusOnDialog('Record updated successfully!', "success", this.container);
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.dataMapValuesService.create(
          this.dataMapValueForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.dataMapValue = response.body;
          this.idOfDataMapValue = this.dataMapValue.id;
          this.notificationHelper.showStatusOnDialog('Record saved successfully!', "success", this.container);
          this.onClick_Close('test');
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);
    if (action = "Close") {
      this.onClick_Close("");
    }
  }

  onClick_Save() {
    this.submit("Close");
  }

  onClick_Close(message) {
    this.activeModal.close(message);
  }

  async getDataMapValueById() {
    if (this.idOfDataMapValue) {
      return await this.dataMapValuesService.getById(
        this.idOfDataMapValue,
      );
    } else {
      return emptyDataMapValue;
    }
  }

  async getParentDataMap(): Promise<void> {
    if (this.idOfDataMap) {
      try {
        this.parentDataMap = await this.dataMapsService.getById(this.idOfDataMap);
      } catch (error) {
        this.parentDataMap = null;
      }
    } else {
      this.parentDataMap = null;
    }
  }

  async loadData() {
    this.loading = true;
    try {
      await Promise.all([
        this.getDataMapValueById().then(value => {
          this.dataMapValue = value;
        }),
      ]);
      if (this.dataMapValue.customerID) {
        const currentCustomer: Customer = await Promise.resolve(this.getCustomerById(this.dataMapValue.customerID));
        this.customers.push(currentCustomer);
      }
      if (this.dataMapValue.itemID) {
        const currentItem: Item = await Promise.resolve(this.getItemById(this.dataMapValue.itemID));
        this.items.push(currentItem);
      }
      if (this.dataMapValue.uofMID) {
        const currentUofM: UofM = await Promise.resolve(this.getUofMById(this.dataMapValue.uofMID));
        this.uofMs.push(currentUofM);
      }
      if (this.dataMapValue.locationID) {
        const currentLocation: Location = await Promise.resolve(this.getLocationById(this.dataMapValue.locationID));
        this.locations.push(currentLocation);
      }
      if (this.dataMapValue.currencyID) {
        const currentCurrency: Currency = await Promise.resolve(this.getCurrencyById(this.dataMapValue.currencyID));
        this.currencies.push(currentCurrency);
      }
      if (this.dataMapValue.numberSourceID) {
        const currentNumberSource: NumberSource = await Promise.resolve(this.getNumberSourceById(this.dataMapValue.numberSourceID));
        this.numberSources.push(currentNumberSource);
      }
      if (this.dataMapValue.priceCalculationID) {
        const currentPriceCalculation: PriceCalculation = await Promise.resolve(this.getPriceCalculationById(this.dataMapValue.priceCalculationID));
        this.priceCalculations.push(currentPriceCalculation);
}
      if (this.dataMapValue.dateFrom) {
        this.dataMapValue.dateFrom = new Date(this.dataMapValue.dateFrom);
        this.dateFrom = this.dataMapValue.dateFrom;
        this.dataMapValueForm.get('dateFrom').setValue(this.dataMapValue.dateFrom);
      }
      if (this.dataMapValue.dateTo) {
        this.dataMapValue.dateTo = new Date(this.dataMapValue.dateTo);
        this.dateTo = this.dataMapValue.dateTo;
        this.dataMapValueForm.get('dateTo').setValue(this.dataMapValue.dateTo);
      }
      this.updateForm();

    } catch (e) {
    } finally {
      this.loading = false;
    }
    this.onFormChanged.emit(false);
  }

  onSelectBoxChanged(id: number, key: keyof DataMapValue, arr) {
    let item = null;
    console.log('key: ' + key);
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.priceSheetDetail[key] = item;
    if (key == 'item') {
    }
    if (key == 'customer') {
    }
  }

  async onLookup_Customer() {
    const modalRef = this.modalService.open(CustomerLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Customer = receivedEntry;
      this.customers = [];
      this.customers.push(recordSelected);
      this.dataMapValueForm.patchValue({ ['customerID']: receivedEntry.id });
      this.dataMapValue.customer = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_Item() {
    const modalRef = this.modalService.open(ItemLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Item = receivedEntry;
      this.items = [];
      this.items.push(recordSelected);
      this.dataMapValueForm.patchValue({ ['itemID']: receivedEntry.id });
      this.dataMapValueForm.patchValue({ ['uofMID']: null });
      this.dataMapValue.item = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_UofM() {
    const modalRef = this.modalService.open(UofMLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: UofM = receivedEntry;
      this.uofMs = [];
      this.uofMs.push(recordSelected);
      this.dataMapValueForm.patchValue({ ['uofMID']: receivedEntry.id });
      this.dataMapValue.uofM = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_Location() {
    const modalRef = this.modalService.open(LocationLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Location = receivedEntry;
      this.locations = [];
      this.locations.push(recordSelected);
      this.dataMapValueForm.patchValue({ ['locationID']: receivedEntry.id });
      this.dataMapValue.location = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_Currency() {
    const modalRef = this.modalService.open(CurrencyLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Currency = receivedEntry;
      this.currencies = [];
      this.currencies.push(recordSelected);
      this.dataMapValueForm.patchValue({ ['currencyID']: receivedEntry.id });
      this.dataMapValue.currency = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_NumberSource() {
    const modalRef = this.modalService.open(NumberSourceLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: NumberSource = receivedEntry;
      this.numberSources = [];
      this.numberSources.push(recordSelected);
      this.dataMapValueForm.patchValue({ ['numberSourceID']: receivedEntry.id });
      this.dataMapValue.numberSource = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_PriceCalculation() {
    const modalRef = this.modalService.open(PriceCalculationLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PriceCalculation = receivedEntry;
      this.priceCalculations = [];
      this.priceCalculations.push(recordSelected);
      this.dataMapValueForm.patchValue({ ['priceCalculationID']: receivedEntry.id });
      this.dataMapValue.priceCalculation = receivedEntry;
      modalRef.close('test');
    });
  }

  async getCustomerById(id) {
    return await this.customersService.getById(id);
  }

  async getItemById(id) {
    return await this.itemsService.getById(id);
  }

  async getUofMById(id) {
    return await this.uofMsService.getById(id);
  }

  async getLocationById(id) {
    return await this.locationsService.getById(id);
  }

  async getCurrencyById(id) {
    return await this.currenciesService.getById(id);
  }

  async getNumberSourceById(id) {
    return await this.numberSourcesService.getById(id);
  }

  async getPriceCalculationById(id) {
    return await this.priceCalculationsService.getById(id);
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.Customer:
        // @ts-ignore
        this.customers = this.getCustomers(params);
        break;
      case Filters.Item:
        // @ts-ignore
        this.items = this.getItems(params);
        break;
      case Filters.Location:
        // @ts-ignore
        this.locations = this.getLocations(params);
        break;
      case Filters.Currency:
        // @ts-ignore
        this.currencies = this.getCurrencies(params);
        break;
      case Filters.NumberSource:
        // @ts-ignore
        this.numberSources = this.getNumberSources(params);
        break;
      case Filters.PriceCalculation:
        // @ts-ignore
        this.priceCalculations = this.getPriceCalculations(params);
        break;
      default:
        break;
    }
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async canDeactivate(nextURL: string) {
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }
}
