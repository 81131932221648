export interface PriceCalculation {
  id: number;
  name: string;
  description: string;
  rank: number;
  priceScheduleCalculationID: number;
  priceModeOptionID: number;
  priceModeOption: {
    name: string,
    id: number
};
  priceAmount: number;
  pricePercent: number;
  convertToUofM: boolean;
  perUofMName: string;
  roundingRuleID: number;
  roundingRule: {
    name: string;
    id: number;
  };
  modifyUnitPrice: boolean;
  trackAdjustment: boolean;
  debitGLAccountID: number;
  debitGLAccount: {
    name: string;
    id: number;
    erpid: string;
  };
  creditGLAccountID: number;
  creditGLAccount: {
    name: string;
    id: number;
    erpid: string;
  };
  userCanEdit: boolean;
  affectsMarginCalc: boolean;  
  pricingFilterID: number;
  pricingFilter: {
    name: string;
    id: number;
  };
  pricingConditionID: number;
  pricingCondition: {
    name: string;
    id: number;
  };
  trackAdjustmentExtended: boolean;
  useValueFromAttribute: boolean;
  useNumberSource: boolean;
  numberSourceID: number;
  numberSource: {
    name: string;
    id: number;
  };
  abortIfValueNotFound: boolean;
  entityID: number;
  entity: {
    name: string,
    id: number
  };
  customerAttributeID: number;
  customerAttribute: {
    name: string;
    id: number;
    attributeDataTypeID: number;
  };
  documentAttributeID: number;
  documentAttribute: {
    name: string;
    id: number;
    attributeDataTypeID: number;
  };
  itemAttributeID: number;
  itemAttribute: {
    name: string;
    id: number;
    attributeDataTypeID: number;
  };
  priceBookID: number;
  priceBook: {
    name: string;
    id: number;
  };
  attributeMapID: number;
  attributeMap: {
    name: string;
    id: number;
  };
  dataMapID: number;
  dataMap: {
    name: string;
    id: number;
  };
  sqlScriptText: string;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyPriceCalculation: PriceCalculation = {
  id: null,
  name: '',
  description: null,
  rank: null,
  priceScheduleCalculationID: null,
  priceModeOptionID: null,
  priceModeOption: {
    name: '',
    id: null
  },
  priceAmount: null,
  pricePercent: null,
  convertToUofM: false,
  perUofMName: '',
  roundingRuleID: null,
  roundingRule: {
    name: '',
    id: null
  },
  modifyUnitPrice: false,
  trackAdjustment: false,
  debitGLAccountID: null,
  debitGLAccount: {
    name: '',
    id: null,
    erpid: ''
  },
  creditGLAccountID: null,
  creditGLAccount: {
    name: '',
    id: null,
    erpid: ''
  },
  userCanEdit: false,
  affectsMarginCalc: false, 
  pricingFilterID: null,
  pricingFilter: {
    name: '',
    id: null
  },
  pricingConditionID: null,
  pricingCondition: {
    name: '',
    id: null
  },
  trackAdjustmentExtended: false,
  useValueFromAttribute: false,
  useNumberSource: false,
  numberSourceID: null,
  numberSource: {
    name: '',
    id:null,
  },
  abortIfValueNotFound: false,
  entityID: null,
  entity: {
    name: '',
    id: null
  },
  customerAttributeID: null,
  customerAttribute: {
    name: '',
    id: null,
    attributeDataTypeID: null
  },
  documentAttributeID: null,
  documentAttribute: {
    name: '',
    id: null,
    attributeDataTypeID: null
  },
  itemAttributeID: null,
  itemAttribute: {
    name: '',
    id: null,
    attributeDataTypeID: null
  },
  priceBookID: null,
  priceBook: {
    name: '',
    id: null
  },
  attributeMapID: null,
  attributeMap: {
    name: '',
    id: null,
  },
  dataMapID: null,
  dataMap: {
    name: '',
    id: null,
  },
  sqlScriptText: '',
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
