<kendo-dialog [minWidth]="450" (close)="onClick_Close('Cancel')" [width]="900" [height]="650">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/View_Diagnostics.png" alt="Pricing Diagnostic" style="padding-right: 5px;">
      Diagnostic Details
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>
  <form [formGroup]="pricingDiagnosticForm">
    <div class="row">
      <div class="col-4 form-group">
        <kendo-formfield>
          <kendo-label for="name" text="Run Date"></kendo-label>
          <kendo-textbox id="runDateTime" class="form-control" formControlName="runDateTime" readonly="true"></kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="id" text="Run ID"></kendo-label>
          <kendo-textbox id="id" class="form-control" formControlName="id" readonly="true"></kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="calculationTime" text="Calculation Time"></kendo-label>
          <kendo-textbox id="calculationTime" class="form-control" formControlName="calculationTime" readonly="true"></kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="outputPrice" text="Output Price"></kendo-label>
          <kendo-textbox id="outputPrice" class="form-control" formControlName="outputPrice" readonly="true"></kendo-textbox>
        </kendo-formfield>
      </div>
    </div>

    <kendo-tabstrip [tabPosition]="'top'">
      <kendo-tabstrip-tab title="Result Data" [selected]="true">
        <ng-template kendoTabContent>
          <div>
            <kendo-formfield>
              <textarea id="resultData" wrap="off" style="height: 400px; overflow-y: scroll; overflow-x: scroll;" class="form-control"
                        formControlName="resultData" readonly="true"
                        placeholder="(This log entry has been deleted. Recalculate to create a new log.)"></textarea>
            </kendo-formfield>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab title="Input XML Document">
        <ng-template kendoTabContent>
          <kendo-formfield>
            <textarea id="inputStringDoc" style="height: 400px; overflow-y: scroll; overflow-x: scroll;" class="form-control"
                      formControlName="inputStringDoc" readonly="true"></textarea>
          </kendo-formfield>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab title="Output XML Document">
        <ng-template kendoTabContent>
          <kendo-formfield>
            <textarea id="outputStringDoc" style="height: 400px; overflow-y: scroll; overflow-x: scroll;" class="form-control"
                      formControlName="outputStringDoc" readonly="true"></textarea>
          </kendo-formfield>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab title="Input XML Line">
        <ng-template kendoTabContent>
          <kendo-formfield>
            <textarea id="inputStringLine" style="height: 400px; overflow-y: scroll; overflow-x: scroll;" class="form-control"
                      formControlName="inputStringLine" readonly="true"></textarea>
          </kendo-formfield>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab title="Output XML Line">
        <ng-template kendoTabContent>
          <kendo-formfield>
            <textarea id="outputStringLine" style="height: 400px; overflow-y: scroll; overflow-x: scroll;" class="form-control"
                      formControlName="outputStringLine" readonly="true"></textarea>
          </kendo-formfield>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')" primary="true"><span class="k-icon k-i-cancel"></span>Close</button>
  </kendo-dialog-actions>
</kendo-dialog>
